import React from 'react';
import './Qsite.css';

export default function Video({ video }) {
	return (
		<div className='video-container mb-3'>
			<iframe
				src={`https://www.youtube.com/embed/${video}?rel=0`}
				title={video}
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
		</div>
	);
}
