import React from 'react';
import './Forms.css';
import Input from './Input';

export default function TriviaElement({ question, setQstValue, answer, setAnsValue }) {
	return (
		<>
			<Input label='Question' type='text' id='question' value={question} setValue={setQstValue} />
			<Input label='Answer' type='text' id='answer' value={answer} setValue={setAnsValue} />
		</>
	);
}
