import React, { useEffect, useState } from 'react';
import './Qsite.css';
import axios from 'axios';

export default function PlayerLineup({ prmSpns, allSpns, players }) {
	const [arrPlayers] = useState([]);

	useEffect(() => {
		if (arrPlayers.length === 0 && players.length !== 0) {
			players.forEach(async (plyr) => {
				const prfId = plyr.profileId;
				const { data } = await axios.get(`/getProfile4Qsite/${prfId}`);
				arrPlayers.push(data);
			});
		}
	}, [arrPlayers, players]);

	return (
		<div className='row mb-2'>
			<div className='col-6 h-100 d-flex flex-column justify-content-end'>
				{prmSpns.map((prmSpn) => (
					<div className='spnDiv shadow' key={prmSpn._id}>
						<a href={prmSpn.url} target='_blank' rel='noreferrer'>
							{allSpns.map((spn) => (
								<div key={spn._id}>{spn._id === prmSpn.sponsorId && <img className='spnImg' src={spn.logo} alt={spn.name} />}</div>
							))}
						</a>
					</div>
				))}
			</div>
			<div className='col-6 h-100'>
				{arrPlayers.map((plyr) => (
					<div className='row mb-2' key={plyr._id}>
						<div className='col-8'>
							<a className='plyrLink' href={plyr.stats} target='_blank' rel='noreferrer'>
								{plyr.fname} {plyr.lname}
							</a>
						</div>
						<div className='col-2 d-flex justify-content-end'>
							<div className='plyrText'>{plyr.posabbr}</div>
						</div>
						<div className='col-2 d-flex justify-content-end'>
							<div className='plyrText'>{plyr.number}</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
