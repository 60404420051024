import React, { useCallback, useContext, useEffect, useState } from 'react';
import './Promos.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../firebase';
import { AuthContext } from '../../../context/auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import ShadowBoxMd from '../../../components/shadowboxes/ShadowBoxMd';
import Input from '../../../components/forms/Input';
import ImageEdit from '../../../components/forms/ImageEdit';
import Checkbox from '../../../components/forms/Checkbox';
import HdrImgEdit from '../../../components/forms/HdrImgEdit';
import Textarea from '../../../components/forms/Textarea';
import SponsorElement from '../../../components/forms/SponsorElement';
import CustomBtnElement from '../../../components/forms/CustomBtnElement';
import CaptureForm from '../../../components/forms/CaptureForm';
import CaptureFormElement from '../../../components/forms/CaptureFormElement';
import CaptureFormCustom from '../../../components/forms/CaptureFormCustom';
import TriviaElement from '../../../components/forms/TriviaElement';
import PlayersForm from '../../../components/forms/PlayersForm';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import defHdrImg from '../../../assets/images/defHdrImage.jpg';
import defQponImg from '../../../assets/images/defNoImage.png';

const onlyNums = (value) => {
	let newVal = value.replace(/[^\d]/g, '');
	return newVal;
};

const formatPhone = (value) => {
	if (!value) return value;
	let phoneNumber = value.toString();
	phoneNumber = phoneNumber.replace(/[^\d]/g, '');
	if (phoneNumber.charAt(0) === '1' || phoneNumber.charAt(0) === 1) {
		phoneNumber = '' + phoneNumber.slice(1);
	}
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength >= 10) {
		phoneNumber = phoneNumber.substring(0, 10);
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export default function EditPromo() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const { id } = useParams();
	const [promo, setPromo] = useState({});
	//General Values
	const [temps, setTemps] = useState([]);
	const [tempId, setTempId] = useState('');
	const [tempName, setTempName] = useState('');
	const scheme = 'light';
	const [venues, setVenues] = useState([]);
	const [venId, setVenId] = useState('');
	const [levels, setLevels] = useState([]);
	const [lvlId, setLvlId] = useState('');
	//Settings
	const [name, setName] = useState('');
	const [seq, setSeq] = useState('');
	const [qrUrl, setQrUrl] = useState('');
	const [newQRImgUrl, setNewQRImgUrl] = useState('');
	const [stDate, setStDate] = useState('');
	const [exDate, setExDate] = useState('');
	const [recurring, setRecurring] = useState(false);
	const [interval, setInterval] = useState('');
	const [shwSoc, setShwSoc] = useState(false);
	const [socStop, setSocStop] = useState(false);
	const [socId, setSocId] = useState('');
	const [svdSoc, setSvdSoc] = useState({});
	const [fb, setFb] = useState('');
	const [tw, setTw] = useState('');
	const [ig, setIg] = useState('');
	const [tt, setTt] = useState('');
	const [donation, setDonation] = useState('');
	const [security, setSecurity] = useState('');
	const [dispSecurity, setDispSecurity] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [phone, setPhone] = useState('');
	const [dispPhone, setDispPhone] = useState('');
	//Layout
	const [curHdrUrl, setCurHdrUrl] = useState('');
	const [newHdrUrl, setNewHdrUrl] = useState('');
	const [headline, setHeadline] = useState('');
	const [bdyText, setBdyText] = useState('');
	const [video, setVideo] = useState('');
	//Sponsors
	const [sponsors, setSponsors] = useState([]);
	const [spnPrmStop, setPrmSpnStop] = useState(false);
	const [spn1Id, setSpn1Id] = useState('');
	const [spn1Url, setSpn1Url] = useState('');
	const [spn2Id, setSpn2Id] = useState('');
	const [spn2Url, setSpn2Url] = useState('');
	const [spn3Id, setSpn3Id] = useState('');
	const [spn3Url, setSpn3Url] = useState('');
	//Custom Buttons
	const [btnNum, setBtnNum] = useState('');
	const [btnStop, setBtnStop] = useState(false);
	const [btn1Txt, setBtn1Txt] = useState('');
	const [btn1Url, setBtn1Url] = useState('');
	const [btn2Txt, setBtn2Txt] = useState('');
	const [btn2Url, setBtn2Url] = useState('');
	const [btn3Txt, setBtn3Txt] = useState('');
	const [btn3Url, setBtn3Url] = useState('');
	const [btn4Txt, setBtn4Txt] = useState('');
	const [btn4Url, setBtn4Url] = useState('');
	const [btn5Txt, setBtn5Txt] = useState('');
	const [btn5Url, setBtn5Url] = useState('');
	//Capture Form
	const [uifStop, setUifStop] = useState(false);
	const [fNameC, setFNameC] = useState(false);
	const [fNameR, setFNameR] = useState(false);
	const [lNameC, setLNameC] = useState(false);
	const [lNameR, setLNameR] = useState(false);
	const [emailC, setEmailC] = useState(false);
	const [emailR, setEmailR] = useState(false);
	const [add1C, setAdd1C] = useState(false);
	const [add1R, setAdd1R] = useState(false);
	const [add2C, setAdd2C] = useState(false);
	const [add2R, setAdd2R] = useState(false);
	const [cityC, setCityC] = useState(false);
	const [cityR, setCityR] = useState(false);
	const [stateC, setStateC] = useState(false);
	const [stateR, setStateR] = useState(false);
	const [zipC, setZipC] = useState(false);
	const [zipR, setZipR] = useState(false);
	const [countryC, setCountryC] = useState(false);
	const [countryR, setCountryR] = useState(false);
	const [hPhnC, setHphnC] = useState(false);
	const [hPhnR, setHphnR] = useState(false);
	const [cPhnC, setCphnC] = useState(false);
	const [cPhnR, setCphnR] = useState(false);
	const [wPhnC, setWphnC] = useState(false);
	const [wPhnR, setWphnR] = useState(false);
	const [wExtC, setWextC] = useState(false);
	const [wExtR, setWextR] = useState(false);
	const [commC, setCommC] = useState(false);
	const [commR, setCommR] = useState(false);
	const [cust1, setCust1] = useState('');
	const [cust1C, setCust1C] = useState(false);
	const [cust1R, setCust1R] = useState(false);
	const [cust2, setCust2] = useState('');
	const [cust2C, setCust2C] = useState(false);
	const [cust2R, setCust2R] = useState(false);
	const [cust3, setCust3] = useState('');
	const [cust3C, setCust3C] = useState(false);
	const [cust3R, setCust3R] = useState(false);
	const [cust4, setCust4] = useState('');
	const [cust4C, setCust4C] = useState(false);
	const [cust4R, setCust4R] = useState(false);
	const [cust5, setCust5] = useState('');
	const [cust5C, setCust5C] = useState(false);
	const [cust5R, setCust5R] = useState(false);
	const [cust6, setCust6] = useState('');
	const [cust6C, setCust6C] = useState(false);
	const [cust6R, setCust6R] = useState(false);
	//Trivia
	const [newQuestion, setNewQuestion] = useState('');
	const [newAnswer, setNewAnswer] = useState('');
	const [updQuestion, setUpdQuestion] = useState('');
	const [updAnswer, setUpdAnswer] = useState('');
	const [trivia, setTrivia] = useState([]);
	const [trvStop, setTrvStop] = useState(false);
	//Product
	const [curProdImg1, setCurProdImg1] = useState('');
	const [newProdImg1, setNewProdImg1] = useState('');
	const [curProdImg2, setCurProdImg2] = useState('');
	const [newProdImg2, setNewProdImg2] = useState('');
	//Player Lineup
	const [profiles, setProfiles] = useState([]);
	const [arrPlayers, setArrPlayers] = useState([]);
	//Profile
	const [profileId, setProfileId] = useState('');
	//Qpon
	const [curQponUrl, setCurQponUrl] = useState('');
	const [newQponUrl, setNewQponUrl] = useState('');

	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/promos');
		}
	});

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// LOAD FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadTemps = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getTemplates`);
			setTemps(data);
		} catch (err) {
			toast.error(err);
		}
	}, []);
	const loadVenues = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomerVenues/${auth.masterCust.id}`);
			setVenues(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);
	const loadLevels = useCallback(async () => {
		if (venId !== '' && venId !== undefined) {
			try {
				const { data } = await axios.get(`/getVenueLevels/${venId}`);
				setLevels(data);
			} catch (err) {
				toast.error(err);
			}
		}
	}, [venId]);
	const loadSocial = useCallback(async () => {
		if (id !== '' && id !== undefined) {
			try {
				const { data } = await axios.get(`/getSocial/${id}`);
				if (data.length === 0) {
					setSocId('');
				} else {
					setSocId(data._id);
					setFb(data.facebook);
					setTw(data.twitter);
					setIg(data.instagram);
					setTt(data.tiktok);
					setDonation(data.donation);
					setSecurity(data.security);
					setEmail(data.email);
					setSubject(data.subject);
					setPhone(data.phone);
					setSocStop(true);
				}
			} catch (err) {
				toast.error(err);
			}
		}
	}, [id]);
	const loadAllSponsors = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomerSponsors/${auth.masterCust.id}`);
			setSponsors(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);
	const loadPrmSponsors = useCallback(async () => {
		if (id !== '' && id !== undefined) {
			try {
				const { data } = await axios.get(`/getPrmSpns/${id}`);
				data.forEach((spn) => {
					if (spn.order === 1) {
						setSpn1Id(spn.sponsorId);
						setSpn1Url(spn.url);
					}
					if (spn.order === 2) {
						setSpn2Id(spn.sponsorId);
						setSpn2Url(spn.url);
					}
					if (spn.order === 3) {
						setSpn3Id(spn.sponsorId);
						setSpn3Url(spn.url);
					}
				});
				setPrmSpnStop(true);
			} catch (err) {
				toast.error(err);
			}
		}
	}, [id]);
	const loadButtons = useCallback(async () => {
		try {
			let num = 0;
			const { data } = await axios.get(`/getButtons/${id}`);
			data.forEach((btn) => {
				if (btn.order === 1) {
					setBtn1Txt(btn.text);
					setBtn1Url(btn.url);
				}
				if (btn.order === 2) {
					setBtn2Txt(btn.text);
					setBtn2Url(btn.url);
				}
				if (btn.order === 3) {
					setBtn3Txt(btn.text);
					setBtn3Url(btn.url);
				}
				if (btn.order === 4) {
					setBtn4Txt(btn.text);
					setBtn4Url(btn.url);
				}
				if (btn.order === 5) {
					setBtn5Txt(btn.text);
					setBtn5Url(btn.url);
				}
				num++;
			});
			setBtnStop(true);
			setBtnNum(num);
		} catch (err) {
			toast.error(err);
		}
	}, [id]);
	const loadCaptureForm = useCallback(async () => {
		try {
			let count = 1;
			const arrCheck = ['fname', 'lname', 'email', 'add1', 'add2', 'city', 'state', 'zip', 'country', 'hphn', 'cphn', 'wphn', 'wext', 'comm'];
			const { data } = await axios.get(`/getCaptureForm/${id}`);
			data.forEach((frm) => {
				if (frm.field === 'fname') {
					setFNameC(true);
					setFNameR(frm.required);
				}
				if (frm.field === 'lname') {
					setLNameC(true);
					setLNameR(frm.required);
				}
				if (frm.field === 'email') {
					setEmailC(true);
					setEmailR(frm.required);
				}
				if (frm.field === 'add1') {
					setAdd1C(true);
					setAdd1R(frm.required);
				}
				if (frm.field === 'add2') {
					setAdd2C(true);
					setAdd2R(frm.required);
				}
				if (frm.field === 'city') {
					setCityC(true);
					setCityR(frm.required);
				}
				if (frm.field === 'state') {
					setStateC(true);
					setStateR(frm.required);
				}
				if (frm.field === 'zip') {
					setZipC(true);
					setZipR(frm.required);
				}
				if (frm.field === 'country') {
					setCountryC(true);
					setCountryR(frm.required);
				}
				if (frm.field === 'hphn') {
					setHphnC(true);
					setHphnR(frm.required);
				}
				if (frm.field === 'cphn') {
					setCphnC(true);
					setCphnR(frm.required);
				}
				if (frm.field === 'wphn') {
					setWphnC(true);
					setWphnR(frm.required);
				}
				if (frm.field === 'wext') {
					setWextC(true);
					setWextR(frm.required);
				}
				if (frm.field === 'comm') {
					setCommC(true);
					setCommR(frm.required);
				}
				if (!arrCheck.includes(frm.field)) {
					if (count === 1) {
						setCust1(frm.label);
						setCust1C(true);
						setCust1R(frm.required);
						count++;
					}
					if (count === 2) {
						setCust2(frm.label);
						setCust2C(true);
						setCust2R(frm.required);
						count++;
					}
					if (count === 3) {
						setCust3(frm.label);
						setCust3C(true);
						setCust3R(frm.required);
						count++;
					}
					if (count === 4) {
						setCust4(frm.label);
						setCust4C(true);
						setCust4R(frm.required);
						count++;
					}
					if (count === 5) {
						setCust5(frm.label);
						setCust5C(true);
						setCust5R(frm.required);
						count++;
					}
					if (count === 6) {
						setCust6(frm.label);
						setCust6C(true);
						setCust6R(frm.required);
						count++;
					}
				}
			});
			setUifStop(true);
		} catch (err) {
			toast.error(err);
		}
	}, [id]);
	const loadTrivia = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getTrivia/${id}`);
			setTrivia(data);
			setTrvStop(true);
		} catch (err) {
			toast.error(err);
		}
	}, [id]);
	const loadProfiles = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomerProfiles/${auth.masterCust.id}`);
			setProfiles(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);
	const loadPlayers = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getGamePlayers/${id}`);
			setArrPlayers(data);
		} catch (err) {
			toast.error(err);
		}
	}, [id]);
	const loadSavedSocials = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSvdSocs/${auth.masterCust.id}`);
			setSvdSoc({ data });
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// LOAD ALL ARRAYS
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (Object.keys(promo).length === 0 || Object.keys(promo).length === undefined) {
			const initPromo = async () => {
				const { data } = await axios.get(`/getPromo/${id}`);
				setPromo(data);
			};
			initPromo();
		}
	}, [promo, setPromo, id]);

	useEffect(() => {
		if (temps.length === 0) {
			loadTemps();
		}
	}, [temps, loadTemps]);

	useEffect(() => {
		if (venues.length === 0) {
			loadVenues();
		}
	}, [venues, loadVenues]);

	useEffect(() => {
		if (venId !== '' && levels.length === 0) {
			loadLevels();
		}
	}, [venId, levels, loadLevels]);

	useEffect(() => {
		if (promo.shwsocial && !socStop) {
			loadSocial();
		}
	}, [promo, socStop, loadSocial]);

	useEffect(() => {
		if (sponsors.length === 0) {
			loadAllSponsors();
		}
	}, [sponsors, loadAllSponsors]);

	useEffect(() => {
		if (
			(promo.tempname === 'Multi-Sponsor' ||
				promo.tempname === 'Single Sponsor' ||
				promo.tempname === 'Product Page' ||
				promo.tempname === 'Player Lineup' ||
				promo.tempname === 'Profile Page') &&
			!spnPrmStop
		) {
			loadPrmSponsors();
		}
	}, [promo, spnPrmStop, loadPrmSponsors]);

	useEffect(() => {
		if (promo.tempname !== 'Qpon Page' && !btnStop) {
			loadButtons();
		}
	}, [promo, btnStop, loadButtons]);

	useEffect(() => {
		if (promo.tempname === 'User Input Form' && !uifStop) {
			loadCaptureForm();
		}
	}, [promo, uifStop, loadCaptureForm]);

	useEffect(() => {
		if (promo.tempname === 'Trivia Page' && !trvStop) {
			loadTrivia();
		}
	}, [promo, trvStop, loadTrivia]);

	useEffect(() => {
		if (profiles.length === 0 && (tempName === 'Player Lineup' || tempName === 'Profile Page')) {
			loadProfiles();
		}
	}, [profiles, tempName, loadProfiles]);

	useEffect(() => {
		if (arrPlayers.length === 0 && tempName === 'Player Lineup') {
			loadPlayers();
		}
	}, [arrPlayers, tempName, loadPlayers]);

	useEffect(() => {
		loadSavedSocials();
	}, [loadSavedSocials]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// LOAD ALL VALUES
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Promo
	useEffect(() => {
		if (Object.keys(promo).length !== 0 && name === '') {
			if (promo.name !== '') {
				setName(promo.name);
			}
			if (promo.sequence !== '') {
				setSeq(promo.sequence);
			}
			if (promo.qrimg !== '') {
				setQrUrl(promo.qrimg);
				setNewQRImgUrl(promo.qrimg);
			}
			if (promo.recurring !== '') {
				setRecurring(promo.recurring);
			}
			if (promo.recurinterval !== '' && promo.recurinterval !== null) {
				setInterval(promo.recurinterval);
			}
			if (promo.header !== '') {
				setCurHdrUrl(promo.header);
				setNewHdrUrl(promo.header);
			}
			if (promo.headline !== '') {
				setHeadline(promo.headline);
			}
			if (promo.bodytext !== '') {
				setBdyText(promo.bodytext);
			}
			if (promo.video !== '') {
				setVideo(promo.video);
			}
			if (promo.qpon !== '') {
				setCurQponUrl(promo.qpon);
				setNewQponUrl(promo.qpon);
			}
			if (promo.photo1 !== '') {
				setCurProdImg1(promo.photo1);
				setNewProdImg1(promo.photo1);
			}
			if (promo.photo2 !== '') {
				setCurProdImg2(promo.photo2);
				setNewProdImg2(promo.photo2);
			}
			if (promo.shwsocial !== '') {
				setShwSoc(promo.shwsocial);
			}
			if (promo.startdate !== '') {
				setStDate(promo.startdate);
			}
			if (promo.expdate !== '') {
				setExDate(promo.expdate);
			}
			if (promo.tempname !== '') {
				setTempName(promo.tempname);
			}
			if (promo.templateId !== '' && promo.templateId !== null) {
				setTempId(promo.templateId);
			}
			if (promo.profileId !== '' && promo.profileId !== null) {
				setProfileId(promo.profileId);
			}
			if (promo.venueId !== '' && promo.venueId !== null) {
				setVenId(promo.venueId);
			}
			if (promo.levelId !== '' && promo.levelId !== null) {
				setLvlId(promo.levelId);
			}
		}
	}, [promo, name, setPromo]);

	useEffect(() => {
		if (svdSoc.data && fb === '' && tw === '' && ig === '' && tt === '' && donation === '' && security === '' && email === '' && subject === '' && phone === '') {
			setFb(svdSoc.data.facebook);
			setTw(svdSoc.data.twitter);
			setIg(svdSoc.data.instagram);
			setTt(svdSoc.data.tiktok);
			setDonation(svdSoc.data.donation);
			setSecurity(svdSoc.data.security);
			setDispSecurity(formatPhone(svdSoc.data.security));
			setEmail(svdSoc.data.email);
			setSubject(svdSoc.data.subject);
			setPhone(svdSoc.data.phone);
			setDispPhone(formatPhone(svdSoc.data.phone));
		}
	}, [svdSoc, fb, tw, ig, tt, donation, security, email, subject, phone]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SUBMIT FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const handleSubmit = async (e) => {
		e.preventDefault();
		//HANDLE DATES AND TIMES
		//Get GMT offset hours
		let offset = new Date().getTimezoneOffset();
		offset = offset / 60;
		//Set Unix Times
		const unixstart = parseInt((new Date(stDate).getTime() / 1000).toFixed(0));
		const unixend = parseInt((new Date(exDate).getTime() / 1000).toFixed(0));

		try {
			setLoading(true);
			//Fix any null ObjectIds
			let prmProfileId = '';
			let prmVenueId = '';
			let prmLevelId = '';
			if (profileId === '') {
				prmProfileId = null; //'000000000000000000000000';
			} else {
				prmProfileId = profileId;
			}
			if (venId === '') {
				prmVenueId = null; //'000000000000000000000000';
			} else {
				prmVenueId = venId;
			}
			if (lvlId === '') {
				prmLevelId = null; //'000000000000000000000000';
			} else {
				prmLevelId = lvlId;
			}

			//Save current social media to saved social
			if (!svdSoc.data) {
				await axios.post('/addSavedSocial', {
					fb,
					tw,
					ig,
					tt,
					donation,
					security,
					email,
					subject,
					phone,
					customerId: auth.user.customerId,
				});
			} else {
				await axios.put(`/updSavedSocial/${svdSoc.data._id}`, {
					facebook: fb,
					twitter: tw,
					instagram: ig,
					tiktok: tt,
					donation,
					security,
					email,
					subject,
					phone,
				});
			}

			await axios.put(`/updPromo/${id}`, {
				name,
				sequence: seq,
				qrimg: newQRImgUrl,
				recurring,
				recurinterval: interval,
				header: newHdrUrl,
				headline,
				bodytext: bdyText,
				video,
				shwsocial: shwSoc,
				colorscheme: scheme,
				startdate: stDate,
				expdate: exDate,
				tzoffset: offset,
				unixstart,
				unixend,
				tempname: tempName,
				templateId: tempId,
				profileId: prmProfileId,
				venueId: prmVenueId,
				levelId: prmLevelId,
			});

			//Product Images
			if (newProdImg1 !== curProdImg1) {
				await axios.put(`/updPromo/${id}`, {
					photo1: newProdImg1,
				});
			}
			if (newProdImg2 !== curProdImg2) {
				await axios.put(`/updPromo/${id}`, {
					photo2: newProdImg2,
				});
			}

			//Social Links
			if (shwSoc) {
				if (socId === '') {
					await axios.post('/addSocial', {
						fb,
						tw,
						ig,
						tt,
						donation,
						security,
						email,
						subject,
						phone,
						promoId: id,
					});
				} else {
					await axios.put(`/updSocial/${socId}`, {
						facebook: fb,
						twitter: tw,
						instagram: ig,
						tiktok: tt,
						donation,
						security,
						email,
						subject,
						phone,
					});
				}
			}

			//Sponsors
			if (spn1Id !== '' && spn1Url !== '') {
				// delete all promo sponsors first then re-add them
				await axios.delete(`/delPrmSpn/${id}`);
				await axios.post('/addPrmSpn', {
					order: 1,
					url: spn1Url,
					sponsorId: spn1Id,
					promoId: id,
				});
			}
			if (spn2Id !== '' && spn2Url !== '') {
				await axios.post('/addPrmSpn', {
					order: 2,
					url: spn2Url,
					sponsorId: spn2Id,
					promoId: id,
				});
			}
			if (spn3Id !== '' && spn3Url !== '') {
				await axios.post('/addPrmSpn', {
					order: 3,
					url: spn3Url,
					sponsorId: spn3Id,
					promoId: id,
				});
			}

			//Custom Buttons
			if (btn1Txt !== '' && btn1Url !== '') {
				// delete all custom buttons first then re-add them
				await axios.delete(`/delButton/${id}`);
				await axios.post('/addButton', {
					order: 1,
					text: btn1Txt,
					url: btn1Url,
					promoId: id,
				});
			}
			if (btn2Txt !== '' && btn2Url !== '') {
				await axios.post('/addButton', {
					order: 2,
					text: btn2Txt,
					url: btn2Url,
					promoId: id,
				});
			}
			if (btn3Txt !== '' && btn3Url !== '') {
				await axios.post('/addButton', {
					order: 3,
					text: btn3Txt,
					url: btn3Url,
					promoId: id,
				});
			}
			if (btn4Txt !== '' && btn4Url !== '') {
				await axios.post('/addButton', {
					order: 4,
					text: btn4Txt,
					url: btn4Url,
					promoId: id,
				});
			}
			if (btn5Txt !== '' && btn5Url !== '') {
				await axios.post('/addButton', {
					order: 5,
					text: btn5Txt,
					url: btn5Url,
					promoId: id,
				});
			}

			//Capture Form
			if (tempName === 'User Input Form') {
				// delete all form fields first then re-add them
				await axios.delete(`/delCaptureForm/${id}`);
				let count = 1;
				if (fNameC) {
					await axios.post('/addCaptureForm', {
						label: 'First Name',
						field: 'fname',
						order: count,
						required: fNameR,
						promoId: id,
					});
					count = count + 1;
				}
				if (lNameC) {
					await axios.post('/addCaptureForm', {
						label: 'Last Name',
						field: 'lname',
						order: count,
						required: lNameR,
						promoId: id,
					});
					count = count + 1;
				}
				if (emailC) {
					await axios.post('/addCaptureForm', {
						label: 'Email',
						field: 'email',
						order: count,
						required: emailR,
						promoId: id,
					});
					count = count + 1;
				}
				if (add1C) {
					await axios.post('/addCaptureForm', {
						label: 'Address 1',
						field: 'add1',
						order: count,
						required: add1R,
						promoId: id,
					});
					count = count + 1;
				}
				if (add2C) {
					await axios.post('/addCaptureForm', {
						label: 'Address 2',
						field: 'add2',
						order: count,
						required: add2R,
						promoId: id,
					});
					count = count + 1;
				}
				if (cityC) {
					await axios.post('/addCaptureForm', {
						label: 'City',
						field: 'city',
						order: count,
						required: cityR,
						promoId: id,
					});
					count = count + 1;
				}
				if (stateC) {
					await axios.post('/addCaptureForm', {
						label: 'State',
						field: 'state',
						order: count,
						required: stateR,
						promoId: id,
					});
					count = count + 1;
				}
				if (zipC) {
					await axios.post('/addCaptureForm', {
						label: 'Zip Code',
						field: 'zip',
						order: count,
						required: zipR,
						promoId: id,
					});
					count = count + 1;
				}
				if (countryC) {
					await axios.post('/addCaptureForm', {
						label: 'Country',
						field: 'country',
						order: count,
						required: countryR,
						promoId: id,
					});
					count = count + 1;
				}
				if (hPhnC) {
					await axios.post('/addCaptureForm', {
						label: 'Home Phone',
						field: 'hphn',
						order: count,
						required: hPhnR,
						promoId: id,
					});
					count = count + 1;
				}
				if (cPhnC) {
					await axios.post('/addCaptureForm', {
						label: 'Cell Phone',
						field: 'cphn',
						order: count,
						required: cPhnR,
						promoId: id,
					});
					count = count + 1;
				}
				if (wPhnC) {
					await axios.post('/addCaptureForm', {
						label: 'Work Phone',
						field: 'wphn',
						order: count,
						required: wPhnR,
						promoId: id,
					});
					count = count + 1;
				}
				if (wExtC) {
					await axios.post('/addCaptureForm', {
						label: 'Work Phone Ext.',
						field: 'wext',
						order: count,
						required: wExtR,
						promoId: id,
					});
					count = count + 1;
				}
				if (commC) {
					await axios.post('/addCaptureForm', {
						label: 'Comments',
						field: 'comm',
						order: count,
						required: commR,
						promoId: id,
					});
					count = count + 1;
				}
				if (cust1 !== '' && cust1C) {
					const fld = cust1.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust1,
						field: fld,
						order: count,
						required: cust1R,
						promoId: id,
					});
					count = count + 1;
				}
				if (cust2 !== '' && cust2C) {
					const fld = cust2.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust2,
						field: fld,
						order: count,
						required: cust2R,
						promoId: id,
					});
					count = count + 1;
				}
				if (cust3 !== '' && cust3C) {
					const fld = cust3.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust3,
						field: fld,
						order: count,
						required: cust3R,
						promoId: id,
					});
					count = count + 1;
				}
				if (cust4 !== '' && cust4C) {
					const fld = cust4.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust4,
						field: fld,
						order: count,
						required: cust4R,
						promoId: id,
					});
					count = count + 1;
				}
				if (cust5 !== '' && cust5C) {
					const fld = cust5.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust5,
						field: fld,
						order: count,
						required: cust5R,
						promoId: id,
					});
					count = count + 1;
				}
				if (cust6 !== '' && cust6C) {
					const fld = cust6.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust6,
						field: fld,
						order: count,
						required: cust6R,
						promoId: id,
					});
					count = count + 1;
				}
			}

			// Game Players
			if (arrPlayers.length !== 0) {
				//First delete all current players
				await axios.delete(`/delGamePlayers/${id}`);
				//Add each player again
				arrPlayers.forEach(async (plyr) => {
					await axios.post('/addGamePlayer', {
						profileId: plyr,
						promoId: id,
					});
				});
			}

			toast.success('Promo successfully updated');
			setLoading(false);
			navigate('/promos');
		} catch (err) {
			toast.error(err);
		}
	};

	// Trivia
	const handleUpdTrivia = async (e, trvId) => {
		e.preventDefault();
		setLoading(true);
		const { data } = await axios.put(`/updTrivia/${trvId}`, {
			question: updQuestion,
			answer: updAnswer,
		});
		if (data.error) {
			toast.error(data.error);
			setLoading(false);
			return;
		} else {
			await loadTrivia();
			toast.success('Trivia Q&A updated successfully');
			setLoading(false);
		}
	};
	const handleAddTrivia = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { data } = await axios.post(`/addTrivia`, {
			question: newQuestion,
			answer: newAnswer,
			promoId: id,
		});
		if (data.error) {
			toast.error(data.error);
			setLoading(false);
			return;
		} else {
			await loadTrivia();
			toast.success('Trivia Q&A added successfully');
			setLoading(false);
		}
	};

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SET VARIABLES
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// General Values
	const handleTempChange = (e) => {
		const value = e.target.value;
		const index = e.nativeEvent.target.selectedIndex;
		setTempId(value);
		setTempName(e.nativeEvent.target[index].text);
	};
	const handleVenue = (e) => {
		const value = e.target.value;
		setVenId(value);
		loadLevels();
	};
	const handleLevel = (e) => {
		const value = e.target.value;
		setLvlId(value);
	};

	// Settings Values
	const handleSeq = (e) => {
		const value = e.target.value;
		const newNum = onlyNums(value);
		setSeq(newNum);
	};
	const handleQRChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(async () => {
				getDownloadURL(storageRef).then((URL) => {
					setNewQRImgUrl(URL);
				});
				//Delete old file from storage
				if (qrUrl !== '') {
					const qrRef = ref(storage, qrUrl);
					await deleteObject(qrRef);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};
	const handleRecurring = (e) => {
		const value = e.target.checked;
		setRecurring(value);
	};
	const handleInterval = (e) => {
		const value = e.target.value;
		const newNum = onlyNums(value);
		setInterval(newNum);
	};
	const handleShwSoc = (e) => {
		const value = e.target.checked;
		setShwSoc(value);
	};
	const handleSecurity = (e) => {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhone(value);
		setDispSecurity(formattedPhoneNumber);
		setSecurity(formattedPhoneNumber.replace(/[^\d]/g, ''));
	};
	const handlePhone = (e) => {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhone(value);
		setDispPhone(formattedPhoneNumber);
		setPhone(formattedPhoneNumber.replace(/[^\d]/g, ''));
	};

	// Layout Values
	// Header Image
	const handleFileChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(async () => {
				getDownloadURL(storageRef).then((URL) => {
					setNewHdrUrl(URL);
				});
				//Delete old file from storage
				if (curHdrUrl !== '') {
					const hdrRef = ref(storage, curHdrUrl);
					await deleteObject(hdrRef);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
		file = null;
	};
	// Body Text
	const handleBdyText = (e) => {
		const value = e.target.value;
		setBdyText(value);
	};
	// Sponsors
	const handleSpn1Change = (e) => {
		const value = e.target.value;
		setSpn1Id(value);
		setSpn2Id('');
		setSpn2Url('');
		setSpn3Id('');
		setSpn3Url('');
	};
	const handleSpn2Change = (e) => {
		const value = e.target.value;
		setSpn2Id(value);
		setSpn3Id('');
		setSpn3Url('');
	};
	const handleSpn3Change = (e) => {
		const value = e.target.value;
		setSpn3Id(value);
	};
	/*** Custom Buttons ***/
	const handleBtnNum = (e) => {
		const value = e.target.value;
		setBtnNum(value);
		if (value === '0') {
			setBtn1Txt('');
			setBtn1Url('');
			setBtn2Txt('');
			setBtn2Url('');
			setBtn3Txt('');
			setBtn3Url('');
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '1') {
			setBtn2Txt('');
			setBtn2Url('');
			setBtn3Txt('');
			setBtn3Url('');
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '2') {
			setBtn3Txt('');
			setBtn3Url('');
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '3') {
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '4') {
			setBtn5Txt('');
			setBtn5Url('');
		}
	};
	/*** Capture Form ***/
	const handleFNameC = (e) => {
		const value = e.target.checked;
		setFNameC(value);
	};
	const handleFNameR = (e) => {
		const value = e.target.checked;
		setFNameR(value);
	};
	const handleLNameC = (e) => {
		const value = e.target.checked;
		setLNameC(value);
	};
	const handleLNameR = (e) => {
		const value = e.target.checked;
		setLNameR(value);
	};
	const handleEmailC = (e) => {
		const value = e.target.checked;
		setEmailC(value);
	};
	const handleEmailR = (e) => {
		const value = e.target.checked;
		setEmailR(value);
	};
	const handleAdd1C = (e) => {
		const value = e.target.checked;
		setAdd1C(value);
	};
	const handleAdd1R = (e) => {
		const value = e.target.checked;
		setAdd1R(value);
	};
	const handleAdd2C = (e) => {
		const value = e.target.checked;
		setAdd2C(value);
	};
	const handleAdd2R = (e) => {
		const value = e.target.checked;
		setAdd2R(value);
	};
	const handleCityC = (e) => {
		const value = e.target.checked;
		setCityC(value);
	};
	const handleCityR = (e) => {
		const value = e.target.checked;
		setCityR(value);
	};
	const handleStateC = (e) => {
		const value = e.target.checked;
		setStateC(value);
	};
	const handleStateR = (e) => {
		const value = e.target.checked;
		setStateR(value);
	};
	const handleZipC = (e) => {
		const value = e.target.checked;
		setZipC(value);
	};
	const handleZipR = (e) => {
		const value = e.target.checked;
		setZipR(value);
	};
	const handleCountryC = (e) => {
		const value = e.target.checked;
		setCountryC(value);
	};
	const handleCountryR = (e) => {
		const value = e.target.checked;
		setCountryR(value);
	};
	const handleHPhnC = (e) => {
		const value = e.target.checked;
		setHphnC(value);
	};
	const handleHPhnR = (e) => {
		const value = e.target.checked;
		setHphnR(value);
	};
	const handleCPhnC = (e) => {
		const value = e.target.checked;
		setCphnC(value);
	};
	const handleCPhnR = (e) => {
		const value = e.target.checked;
		setCphnR(value);
	};
	const handleWPhnC = (e) => {
		const value = e.target.checked;
		setWphnC(value);
	};
	const handleWPhnR = (e) => {
		const value = e.target.checked;
		setWphnR(value);
	};
	const handleWExtC = (e) => {
		const value = e.target.checked;
		setWextC(value);
	};
	const handleWExtR = (e) => {
		const value = e.target.checked;
		setWextR(value);
	};
	const handleCommC = (e) => {
		const value = e.target.checked;
		setCommC(value);
	};
	const handleCommR = (e) => {
		const value = e.target.checked;
		setCommR(value);
	};
	const handleCust1C = (e) => {
		const value = e.target.checked;
		setCust1C(value);
	};
	const handleCust1R = (e) => {
		const value = e.target.checked;
		setCust1R(value);
	};
	const handleCust2C = (e) => {
		const value = e.target.checked;
		setCust2C(value);
	};
	const handleCust2R = (e) => {
		const value = e.target.checked;
		setCust2R(value);
	};
	const handleCust3C = (e) => {
		const value = e.target.checked;
		setCust3C(value);
	};
	const handleCust3R = (e) => {
		const value = e.target.checked;
		setCust3R(value);
	};
	const handleCust4C = (e) => {
		const value = e.target.checked;
		setCust4C(value);
	};
	const handleCust4R = (e) => {
		const value = e.target.checked;
		setCust4R(value);
	};
	const handleCust5C = (e) => {
		const value = e.target.checked;
		setCust5C(value);
	};
	const handleCust5R = (e) => {
		const value = e.target.checked;
		setCust5R(value);
	};
	const handleCust6C = (e) => {
		const value = e.target.checked;
		setCust6C(value);
	};
	const handleCust6R = (e) => {
		const value = e.target.checked;
		setCust6R(value);
	};
	/*** Product ***/
	const handleProdImg1Change = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(async () => {
				getDownloadURL(storageRef).then((URL) => {
					setNewProdImg1(URL);
				});
				//Delete old file from storage
				if (curProdImg1 !== '') {
					const prodRef = ref(storage, curProdImg1);
					await deleteObject(prodRef);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
		file = null;
	};
	const handleProdImg2Change = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(async () => {
				getDownloadURL(storageRef).then((URL) => {
					setNewProdImg2(URL);
				});
				//Delete old file from storage
				if (curProdImg2 !== '') {
					const prodRef = ref(storage, curProdImg2);
					await deleteObject(prodRef);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
		file = null;
	};
	/*** Player Lineup ***/
	const handleAddPlayer = (e, prfId) => {
		const value = e.target.checked;
		if (value) {
			setArrPlayers([...arrPlayers, prfId]);
		} else {
			const idx = arrPlayers.indexOf(prfId);
			setArrPlayers([...arrPlayers.slice(0, idx), ...arrPlayers.slice(idx + 1, arrPlayers.length)]);
		}
	};
	/*** Qpon ***/
	const handleQponChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(async () => {
				getDownloadURL(storageRef).then((URL) => {
					setNewQponUrl(URL);
				});
				//Delete old file from storage
				if (curQponUrl !== '') {
					const qponRef = ref(storage, curQponUrl);
					await deleteObject(qponRef);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
		file = null;
	};

	return (
		<AdminPageTemplate title='Edit Promo'>
			<ShadowBoxMd>
				<form onSubmit={handleSubmit}>
					{temps.length >= 1 && (
						<div className='row mb-3 d-flex justify-content-center align-items-center'>
							<div className='col-12 col-md-3 pe-1 text-start text-md-end'>
								<label className='frmLabel'>Template</label>
							</div>
							<div className='col-12 col-md-6 ps-2'>
								<select className='form-select' id='template' required value={tempId} onChange={(e) => handleTempChange(e)}>
									<option key='0' value=''></option>
									{temps.map((tmp) => (
										<option key={tmp._id} value={tmp._id}>
											{tmp.name}
										</option>
									))}
								</select>
							</div>
							<div className='col-12 col-md-3 text-center'>
								<Link className='samplesLink col-3 text-center' to='/samples'>
									View Template Samples
								</Link>
							</div>
						</div>
					)}
					<div className='row mb-3 d-flex justify-content-center align-items-center'>
						<div className='col-12 col-md-3 pe-1 text-start text-md-end'>
							<label className='frmLabel'>Use Price Level</label>
						</div>
						<div className='col-12 col-md-6 ps-2'>
							<select className='form-select' id='venue' value={venId} onChange={(e) => handleVenue(e)}>
								<option key='1' value=''></option>
								{venues.map((ven) => (
									<option key={ven._id} value={ven._id}>
										{ven.name}
									</option>
								))}
							</select>
						</div>
						<div className='col-md-3 d-none d-md-block'></div>
					</div>
					{venId !== '' && (
						<div className='row mb-3 d-flex justify-content-center align-items-center'>
							<div className='col-12 col-md-3 pe-1 text-start text-md-end'>
								<label className='frmLabel'>Price Level</label>
							</div>
							<div className='col-12 col-md-6 ps-2'>
								<select className='form-select' id='level' value={lvlId} onChange={(e) => handleLevel(e)}>
									<option key='2' value=''></option>
									{levels.map((lvl) => (
										<option key={lvl._id} value={lvl._id}>
											{lvl.description}
										</option>
									))}
								</select>
							</div>
							<div className='col-md-3 d-none d-md-block'></div>
						</div>
					)}
					<div className='row d-flex justify-content-evenly'>
						<div className='col-12 col-md-6 px-4'>
							<h2 className='colHdng my-3 text-center'>Settings</h2>
							<Input label='Name' type='text' id='name' required value={name} setValue={setName} />
							<Input label='Sequence' type='number' id='seq' max='2' required value={seq} funcCall={handleSeq} />
							<label className='frmLabel mb-1'>Image for QR Code</label>
							<ImageEdit inputId='imgQR' type='file' handler={handleQRChange} newUrl={newQRImgUrl} image={newQRImgUrl} imgDefault={defQponImg} />
							<div className='admInfo mb-1'>recommended upload size: 200 x 200</div>
							<div className='admInfo mb-3'>max file size: 2mb</div>
							<Input label='Start Date' type='datetime-local' value={stDate} setValue={setStDate} />
							<Input label='Expire Date' type='datetime-local' value={exDate} setValue={setExDate} />
							<div className='row mb-3'>
								<div className='col-auto pe-2'>
									<Checkbox checked={recurring} funcCall={handleRecurring} />
								</div>
								<div className='col ps-2'>
									<div className='textSemi'>Recurring</div>
								</div>
							</div>
							{recurring && <Input label='Recur Interval' type='text' placeholder='# of minutes' max='3' value={interval} funcCall={handleInterval} />}
							<div className='row mb-3'>
								<div className='col-auto pe-2'>
									<Checkbox checked={shwSoc} funcCall={handleShwSoc} />
								</div>
								<div className='col ps-2'>
									<div className='textSemi'>Show Social Links</div>
								</div>
							</div>
							{shwSoc && (
								<>
									<Input label='Facebook' type='text' placeholder='https://' value={fb} setValue={setFb} />
									<Input label='Twitter' type='text' placeholder='https://' value={tw} setValue={setTw} />
									<Input label='Instagram' type='text' placeholder='https://' value={ig} setValue={setIg} />
									<Input label='Tik-Tok' type='text' placeholder='https://' value={tt} setValue={setTt} />
									<Input label='Donation Link' type='text' placeholder='https://' value={donation} setValue={setDonation} />
									<Input label='Security #' type='tel' value={dispSecurity} funcCall={handleSecurity} />
									<Input label='Email' type='email' value={email} setValue={setEmail} />
									<Input label='Email Subject' type='text' value={subject} setValue={setSubject} />
									<Input label='Phone #' type='tel' value={dispPhone} funcCall={handlePhone} />
								</>
							)}
						</div>
						<div className='col-12 col-md-6 px-4'>
							<h2 className='colHdng my-3 text-center'>Layout</h2>
							{/*** HEADER ***/}
							{tempName !== 'Qpon Page' && tempName !== '' && (
								<>
									<HdrImgEdit label='Header' inputId='imgHdr' type='file' handler={handleFileChange} newUrl={newHdrUrl} image={newHdrUrl} imgDefault={defHdrImg} />
									<div className='admInfo mb-1'>recommended upload size: 800 x 200</div>
									<div className='admInfo mb-3'>max file size: 2mb</div>
								</>
							)}
							{/*** HEADLINE ***/}
							{tempName !== 'Qpon Page' && tempName !== '' && <Input label='Headline' type='text' max='60' value={headline} setValue={setHeadline} />}
							{/*** BODY TEXT ***/}
							{tempName !== '2 - Buttons' && tempName !== 'Trivia Page' && tempName !== '' && (
								<Textarea inputId='bdyText' inputLabel='Body Text' max='1500' value={bdyText} handler={handleBdyText} />
							)}
							{/*** VIDEO ***/}
							{(tempName === 'Multi-Sponsor' ||
								tempName === 'Announcement' ||
								tempName === 'Single Sponsor' ||
								tempName === 'Player Lineup' ||
								tempName === 'Profile Page') && <Input label='Video' placeholder='e.g. KKJprZqU_oU' max='20' value={video} setValue={setVideo} />}
							{/*** SPONSORS ***/}
							{(tempName === 'Multi-Sponsor' ||
								tempName === 'Single Sponsor' ||
								tempName === 'Product Page' ||
								tempName === 'Player Lineup' ||
								tempName === 'Profile Page') && (
								<SponsorElement
									urlId='spn1Url'
									urlLabel='Sponsor 1'
									placeholder='https://'
									urlValue={spn1Url}
									setValue={setSpn1Url}
									selId='spn1Id'
									value={spn1Id}
									handler={handleSpn1Change}
									arr={sponsors}
								/>
							)}
							{spn1Id && (tempName === 'Multi-Sponsor' || tempName === 'Product Page' || tempName === 'Player Lineup' || tempName === 'Profile Page') && (
								<SponsorElement
									urlId='spn2Url'
									urlLabel='Sponsor 2'
									placeholder='https://'
									urlValue={spn2Url}
									setValue={setSpn2Url}
									selId='spn2Id'
									value={spn2Id}
									handler={handleSpn2Change}
									arr={sponsors}
								/>
							)}
							{spn1Id && spn2Id && (tempName === 'Multi-Sponsor' || tempName === 'Product Page' || tempName === 'Player Lineup' || tempName === 'Profile Page') && (
								<SponsorElement
									urlId='spn3Url'
									urlLabel='Sponsor 3'
									placeholder='https://'
									urlValue={spn3Url}
									setValue={setSpn3Url}
									selId='spn3Id'
									value={spn3Id}
									handler={handleSpn3Change}
									arr={sponsors}
								/>
							)}
							{/*** SELECT NUMBER OF CUSTOM BUTTONS ***/}
							{tempName === 'Announcement' && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2, 3, 4, 5].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{(tempName === 'Multi-Sponsor' || tempName === 'Single Sponsor' || tempName === 'User Input Form') && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2, 3, 4].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{(tempName === 'Trivia Page' || tempName === 'Survey Page' || tempName === 'Profile Page') && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2, 3].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{(tempName === 'Player Lineup' || tempName === '2 - Buttons') && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{/*** CUSTOM BUTTONS ***/}
							{btnNum >= 1 && 1 <= btnNum && (
								<CustomBtnElement
									label='Button 1'
									txtId='btn1Txt'
									max='25'
									txtValue={btn1Txt}
									setTxtValue={setBtn1Txt}
									urlId='btn1Url'
									urlValue={btn1Url}
									setUrlValue={setBtn1Url}
								/>
							)}
							{btnNum >= 1 && 2 <= btnNum && (
								<CustomBtnElement
									label='Button 2'
									txtId='btn2Txt'
									max='25'
									txtValue={btn2Txt}
									setTxtValue={setBtn2Txt}
									urlId='btn2Url'
									urlValue={btn2Url}
									setUrlValue={setBtn2Url}
								/>
							)}
							{btnNum >= 1 && 3 <= btnNum && (
								<CustomBtnElement
									label='Button 3'
									txtId='btn3Txt'
									max='25'
									txtValue={btn3Txt}
									setTxtValue={setBtn3Txt}
									urlId='btn3Url'
									urlValue={btn3Url}
									setUrlValue={setBtn3Url}
								/>
							)}
							{btnNum >= 1 && 4 <= btnNum && (
								<CustomBtnElement
									label='Button 4'
									txtId='btn4Txt'
									max='25'
									txtValue={btn4Txt}
									setTxtValue={setBtn4Txt}
									urlId='btn4Url'
									urlValue={btn4Url}
									setUrlValue={setBtn4Url}
								/>
							)}
							{btnNum >= 1 && 5 <= btnNum && (
								<CustomBtnElement
									label='Button 5'
									txtId='btn5Txt'
									max='25'
									txtValue={btn5Txt}
									setTxtValue={setBtn5Txt}
									urlId='btn5Url'
									urlValue={btn5Url}
									setUrlValue={setBtn5Url}
								/>
							)}
							{/*** CAPTURE FORM (TEMPLATE 4) ***/}
							{tempName === 'User Input Form' && (
								<CaptureForm>
									<CaptureFormElement inputIdC='fnamec' inputIdR='fnamer' inputLabel='First Name' handlerC={handleFNameC} handlerR={handleFNameR} />
									<CaptureFormElement inputIdC='lnamec' inputIdR='lnamer' inputLabel='Last Name' handlerC={handleLNameC} handlerR={handleLNameR} />
									<CaptureFormElement inputIdC='emailc' inputIdR='emailr' inputLabel='Email' handlerC={handleEmailC} handlerR={handleEmailR} />
									<CaptureFormElement inputIdC='add1c' inputIdR='add1r' inputLabel='Address 1' handlerC={handleAdd1C} handlerR={handleAdd1R} />
									<CaptureFormElement inputIdC='add2c' inputIdR='add2r' inputLabel='Address 2' handlerC={handleAdd2C} handlerR={handleAdd2R} />
									<CaptureFormElement inputIdC='cityc' inputIdR='cityr' inputLabel='City' handlerC={handleCityC} handlerR={handleCityR} />
									<CaptureFormElement inputIdC='statec' inputIdR='stater' inputLabel='State' handlerC={handleStateC} handlerR={handleStateR} />
									<CaptureFormElement inputIdC='zipc' inputIdR='zipr' inputLabel='Zip Code' handlerC={handleZipC} handlerR={handleZipR} />
									<CaptureFormElement inputIdC='countryc' inputIdR='countryr' inputLabel='Country' handlerC={handleCountryC} handlerR={handleCountryR} />
									<CaptureFormElement inputIdC='hphnc' inputIdR='hphnr' inputLabel='Home Phone' handlerC={handleHPhnC} handlerR={handleHPhnR} />
									<CaptureFormElement inputIdC='cphnc' inputIdR='cphnr' inputLabel='Cell Phone' handlerC={handleCPhnC} handlerR={handleCPhnR} />
									<CaptureFormElement inputIdC='wphnc' inputIdR='wphnr' inputLabel='Work Phone' handlerC={handleWPhnC} handlerR={handleWPhnR} />
									<CaptureFormElement inputIdC='wextc' inputIdR='wextr' inputLabel='Work Phone Ext.' handlerC={handleWExtC} handlerR={handleWExtR} />
									<CaptureFormElement inputIdC='commc' inputIdR='commr' inputLabel='Comments' handlerC={handleCommC} handlerR={handleCommR} />
									<CaptureFormCustom
										inputIdTxt='cust1'
										txtValue={cust1}
										max='20'
										handlerT={setCust1}
										inputIdC='cust1c'
										inputIdR='cust1r'
										handlerC={handleCust1C}
										handlerR={handleCust1R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust2'
										txtValue={cust2}
										max='20'
										handlerT={setCust2}
										inputIdC='cust2c'
										inputIdR='cust2r'
										handlerC={handleCust2C}
										handlerR={handleCust2R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust3'
										txtValue={cust3}
										max='20'
										handlerT={setCust3}
										inputIdC='cust3c'
										inputIdR='cust3r'
										handlerC={handleCust3C}
										handlerR={handleCust3R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust4'
										txtValue={cust4}
										max='20'
										handlerT={setCust4}
										inputIdC='cust4c'
										inputIdR='cust4r'
										handlerC={handleCust4C}
										handlerR={handleCust4R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust5'
										txtValue={cust5}
										max='20'
										handlerT={setCust5}
										inputIdC='cust5c'
										inputIdR='cust5r'
										handlerC={handleCust5C}
										handlerR={handleCust5R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust6'
										txtValue={cust6}
										max='20'
										handlerT={setCust6}
										inputIdC='cust6c'
										inputIdR='cust6r'
										handlerC={handleCust6C}
										handlerR={handleCust6R}
									/>
								</CaptureForm>
							)}
							{/*** TRIVIA PAGE (TEMPLATE 5) ***/}
							{tempName === 'Trivia Page' && (
								<>
									{trivia.map((trv) => (
										<div key={trv._id}>
											<TriviaElement question={trv.question} setQstValue={setUpdQuestion} answer={trv.answer} setAnsValue={setUpdAnswer} />
											<div className='row mb-1 d-flex justify-content-center align-items-center'>
												<div className='col-12 text-center'>
													<div className='samplesLink' onClick={(e) => handleUpdTrivia(e, trv._id)}>
														Save Changes
													</div>
												</div>
											</div>
										</div>
									))}
									<TriviaElement question={newQuestion} setQstValue={setNewQuestion} answer={newAnswer} setAnsValue={setNewAnswer} />
									<div className='row mb-1 d-flex justify-content-center align-items-center'>
										<div className='col-12 text-center'>
											<div className='samplesLink' onClick={(e) => handleAddTrivia(e)}>
												Save Question
											</div>
										</div>
									</div>
								</>
							)}
							{/*** PRODUCT PAGE (TEMPLATE 6) ***/}
							{tempName === 'Product Page' && (
								<>
									<label className='frmLabel'>Product Image #1</label>
									<ImageEdit inputId='prodImg1' type='file' handler={handleProdImg1Change} newUrl={newProdImg1} image={newProdImg1} imgDefault={defQponImg} />
									<label className='frmLabel'>Product Image #2</label>
									<ImageEdit inputId='prodImg2' type='file' handler={handleProdImg2Change} newUrl={newProdImg2} image={newProdImg2} imgDefault={defQponImg} />
								</>
							)}
							{/*** PLAYER LINEUP (TEMPLATE 7) ***/}
							{tempName === 'Player Lineup' && (
								<PlayersForm>
									{profiles.map((prf) => (
										<div className='row mb-3 d-flex align-items-center' key={prf._id}>
											<div className='col-0 col-sm-1'></div>
											<div className='col-1 text-center'>
												{arrPlayers.includes(prf._id) && <input className='chkBox' type='checkbox' checked onChange={(e) => handleAddPlayer(e, prf._id)} />}
												{!arrPlayers.includes(prf._id) && <input className='chkBox' type='checkbox' onChange={(e) => handleAddPlayer(e, prf._id)} />}
											</div>
											<div className='col-6'>
												{prf.lname}, {prf.fname}
											</div>
											<div className='col-2 text-center'>{prf.posabbr}</div>
											<div className='col-2 text-center'>{prf.number}</div>
										</div>
									))}
								</PlayersForm>
							)}
							{/*** PROFILE PAGE (TEMPLATE 8) ***/}
							{tempName === 'Profile Page' && (
								<div className='mb-3'>
									<label className='frmLabel'>Profile</label>
									<select className='form-select' id='profile' value={profileId} onChange={(e) => setProfileId(e.target.value)}>
										<option value=''>Select One...</option>
										{profiles.map((prf) => (
											<option key={prf._id} value={prf._id}>
												{prf.lname}, {prf.fname}
											</option>
										))}
									</select>
								</div>
							)}
							{/*** QPON PAGE (TEMPLATE 9) ***/}
							{tempName === 'Qpon Page' && (
								<>
									<HdrImgEdit label='Qpon Image' inputId='imgQpon' type='file' handler={handleQponChange} newUrl={newQponUrl} image={newQponUrl} imgDefault={defQponImg} />
									<div className='admInfo mb-1'>recommended upload size: 800 x 1300</div>
									<div className='admInfo mb-3'>max file size: 2mb</div>
								</>
							)}
						</div>
					</div>
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!tempId}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxMd>
		</AdminPageTemplate>
	);
}
