import React from 'react';
import './Forms.css';
import { SpinnerInfinity } from 'spinners-react';

export default function Button({ loading, type, disabled, onClick, children }) {
	return (
		<>
			{loading && <SpinnerInfinity color={'#9b00ef'} secondaryColor={'transparent'} size={60} />}
			{!loading && (
				<button className='button' type={type} disabled={disabled} onClick={onClick}>
					{children}
				</button>
			)}
		</>
	);
}
