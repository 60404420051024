import React, { useContext, useEffect, useState } from 'react';
import './Account.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { removeFromLocalStorage } from '../../../helpers/auth';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';

export default function Password() {
	const navigate = useNavigate();
	const { auth, setAuth } = useContext(AuthContext);
	const [curPword, setCurPword] = useState('');
	const [newPword, setNewPword] = useState('');
	const [cnfPword, setCnfPword] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/account');
		}
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);

			if (newPword !== cnfPword) {
				toast.error('New and Confirm Passwords Do Not Match!');
				const pword = document.getElementById('newPword');
				const conf = document.getElementById('cnfPword');
				pword.value = '';
				conf.value = '';
				pword.focus();
				setLoading(false);
				return;
			}

			if (curPword.trim() === '') {
				const input = document.getElementById('curPword');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Current Password is required');
				return;
			}
			if (newPword.trim() === '' || newPword.length <= 5) {
				const input = document.getElementById('newPword');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('New Password is required, and must be at least 6 characters');
				return;
			}
			if (cnfPword.trim() === '' || cnfPword.length <= 5) {
				const input = document.getElementById('cnfPword');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Confirm Password is required, and must be at least 6 characters');
				return;
			}

			await axios
				.put(`/chgPword/${auth.user._id}`, {
					curPword,
					newPword,
				})
				.then((res) => {
					const ok = res.data.ok;
					if (ok) {
						toast.success('Password updated successfully. Please login again.');
						setLoading(false);
						setAuth(null);
						removeFromLocalStorage('auth');
						removeFromLocalStorage('userData');
						removeFromLocalStorage('_grecaptcha');
						navigate('/login');
					} else {
						toast.error('Password update failed. Please try again.');
					}
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Change Password'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='curPword' id='curPword' label='Current Password' type='password' value={curPword} setValue={setCurPword} />
					<Input name='newPword' id='newPword' label='New Password' type='password' value={newPword} setValue={setNewPword} />
					<Input
						name='cnfPword'
						id='cnfPword'
						label='Confirm New Password'
						type='password'
						value={cnfPword}
						setValue={setCnfPword}
					/>
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!curPword || !newPword || !cnfPword}>
							Update Password
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
