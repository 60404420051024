import React, { useCallback, useEffect, useState } from 'react';
import './Venues.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import AddIcon from '../../../components/icons/AddIcon';
import DelIcon from '../../../components/icons/DelIcon';
import ListRow from '../../../components/listrow/ListRow';
import Pagination from '../../../components/pagination/Pagination';
import ContainerSm from '../../../components/containers/ContainerSm';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';

export default function Users() {
	const navigate = useNavigate();
	const { venId } = useParams();
	const [levels, setLevels] = useState([]);
	const [delItem, setDelItem] = useState(false);
	const [delDoc, setDelDoc] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(10);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = levels.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(levels.length / recordsPerPage);
	const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

	const loadLevels = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getVenueLevels/${venId}`);
			setLevels(data);
		} catch (err) {
			console.log(err);
		}
	}, [venId]);

	useEffect(() => {
		if (levels.length === 0) {
			loadLevels();
		}
	}, [levels, loadLevels]);

	const handleConfirmationBox = (level) => {
		if (!delItem) {
			document.querySelector('.confirm-bg').style.display = 'flex';
			document.querySelector('.confirmation-container').style.display = 'flex';
			setDelItem(true);
			setDelDoc(level);
		} else {
			document.querySelector('.confirm-bg').style.display = 'none';
			document.querySelector('.confirmation-container').style.display = 'none';
			setDelItem(false);
		}
	};

	const handleEdit = (e, lvlId) => {
		e.preventDefault();
		navigate(`${lvlId}/edit`);
	};

	const handleDelete = async (e) => {
		try {
			//delete price level from database
			await axios.delete(`/delLevel/${delDoc._id}`).then((res) => {
				const ok = res.data.ok;
				if (ok) {
					//update the list of levels
					loadLevels();
					setDelItem(true);
					handleConfirmationBox();
					toast.success('Price Level deleted successfully');
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const nextPage = () => {
		if (currentPage !== nPages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<AdminPageTemplate title='Price Levels'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link to='add'>
					<AddIcon alt='Add Price Level' />
				</Link>
			</div>
			<div className='mb-4 d-flex justify-content-center'>
				<Link className='backText' to='/venues'>
					&laquo; Back to Venues
				</Link>
			</div>
			<ContainerSm>
				{currentRecords.length === 0 && <div className='noRecsText d-flex justify-content-center'>No Price Levels Added Yet</div>}
				{currentRecords.map((lvl) => (
					<ListRow key={lvl._id}>
						<div className='listLinkText col-6' onClick={(e) => handleEdit(e, lvl._id)}>
							{lvl.description}
						</div>
						<div className='listInfoText col-4'>{lvl.abbr}</div>
						<div className='col-2 d-flex justify-content-end'>
							<DelIcon alt='Delete' funcCall={() => handleConfirmationBox(lvl)} />
						</div>
					</ListRow>
				))}
			</ContainerSm>
			{nPages >= 2 && <Pagination prev={prevPage} pgNums={pageNumbers} curPage={currentPage} handler={setCurrentPage} next={nextPage} />}
			{/* DELETE CONFIRMATION BOX */}
			<div className='confirmation-container'>
				<div className='confirmation-text'>
					Are you sure?
					<br />
					This cannot be undone!
				</div>
				<div className='button-container'>
					<button className='cancel-button' onClick={() => handleConfirmationBox()}>
						Cancel
					</button>
					<button className='confirmation-button' onClick={() => handleDelete()}>
						Delete
					</button>
				</div>
			</div>
			<div className='confirm-bg' onClick={() => handleConfirmationBox()}></div>
			{/* END DELETE CONFIRMATION BOX */}
		</AdminPageTemplate>
	);
}
