import React, { useState } from 'react';
import './Qsite.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import Input from '../forms/Input';
import Button from '../forms/Button';
import Headline from './Headline';

const randomStringMake = (count) => {
	const letter = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let randomString = '';
	for (let i = 0; i < count; i++) {
		const randomStringNumber = Math.floor(1 + Math.random() * (letter.length - 1));
		randomString += letter.substring(randomStringNumber, randomStringNumber + 1);
	}
	return randomString;
};

export default function Capture({ fields, prmId }) {
	let count = 1;
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [add1, setAdd1] = useState('');
	const [add2, setAdd2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [country, setCountry] = useState('');
	const [hPhn, setHPhn] = useState('');
	const [cPhn, setCPhn] = useState('');
	const [wPhn, setWPhn] = useState('');
	const [wExt, setWExt] = useState('');
	const [comment, setComment] = useState('');
	const [custFld1, setCustFld1] = useState('');
	const [custRes1, setCustRes1] = useState('');
	const [custFld2, setCustFld2] = useState('');
	const [custRes2, setCustRes2] = useState('');
	const [custFld3, setCustFld3] = useState('');
	const [custRes3, setCustRes3] = useState('');
	const [custFld4, setCustFld4] = useState('');
	const [custRes4, setCustRes4] = useState('');
	const [custFld5, setCustFld5] = useState('');
	const [custRes5, setCustRes5] = useState('');
	const [custFld6, setCustFld6] = useState('');
	const [custRes6, setCustRes6] = useState('');
	const [loading, setLoading] = useState(false);
	const [shwForm, setShwForm] = useState(true);
	const arrCapture = [
		'First Name',
		'Last Name',
		'Email',
		'Address 1',
		'Address 2',
		'City',
		'State',
		'Zip Code',
		'Country',
		'Home Phone',
		'Cell Phone',
		'Work Phone',
		'Work Phone Ext.',
		'Comments',
	];

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		// Set group number
		const grpNum = randomStringMake(8);

		if (fname !== '') {
			await axios.post('addResult4Qsite', {
				field: 'First Name',
				value: fname,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (lname !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Last Name',
				value: lname,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (email !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Email',
				value: email,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (add1 !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Address 1',
				value: add1,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (add2 !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Address 2',
				value: add2,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (city !== '') {
			await axios.post('addResult4Qsite', {
				field: 'City',
				value: city,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (state !== '') {
			await axios.post('addResult4Qsite', {
				field: 'State',
				value: state,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (zip !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Zip Code',
				value: zip,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (country !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Country',
				value: country,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (hPhn !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Home Phone',
				value: hPhn,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (cPhn !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Cell Phone',
				value: cPhn,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (wPhn !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Work Phone',
				value: wPhn,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (wExt !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Work Phone Ext.',
				value: wExt,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (comment !== '') {
			await axios.post('addResult4Qsite', {
				field: 'Comment',
				value: comment,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (custRes1 !== '') {
			await axios.post('addResult4Qsite', {
				field: custFld1,
				value: custRes1,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (custRes2 !== '') {
			await axios.post('addResult4Qsite', {
				field: custFld2,
				value: custRes2,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (custRes3 !== '') {
			await axios.post('addResult4Qsite', {
				field: custFld3,
				value: custRes3,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (custRes4 !== '') {
			await axios.post('addResult4Qsite', {
				field: custFld4,
				value: custRes4,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (custRes5 !== '') {
			await axios.post('addResult4Qsite', {
				field: custFld5,
				value: custRes5,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		if (custRes6 !== '') {
			await axios.post('addResult4Qsite', {
				field: custFld6,
				value: custRes6,
				groupId: grpNum,
				promoId: prmId,
			});
		}
		toast.success('Thank you for submitting the form');
		setShwForm(false);
		setLoading(false);
	};

	const handleResponse = (e, fld, num) => {
		const value = e.target.value;
		if (num === 1) {
			setCustFld1(fld);
			setCustRes1(value);
		}
		if (num === 2) {
			setCustFld2(fld);
			setCustRes2(value);
		}
		if (num === 3) {
			setCustFld3(fld);
			setCustRes3(value);
		}
		if (num === 4) {
			setCustFld4(fld);
			setCustRes4(value);
		}
		if (num === 5) {
			setCustFld5(fld);
			setCustRes5(value);
		}
		if (num === 6) {
			setCustFld6(fld);
			setCustRes6(value);
		}
	};

	return (
		<>
			{shwForm && (
				<form onSubmit={handleSubmit}>
					{fields.map((fld) => (
						<div className='px-3' key={fld._id}>
							{fld.label === 'First Name' && <Input label={fld.label} required={fld.required} value={fname} setValue={setFname} />}
							{fld.label === 'Last Name' && <Input label={fld.label} required={fld.required} value={lname} setValue={setLname} />}
							{fld.label === 'Email' && <Input label={fld.label} required={fld.required} value={email} setValue={setEmail} />}
							{fld.label === 'Address 1' && <Input label={fld.label} required={fld.required} value={add1} setValue={setAdd1} />}
							{fld.label === 'Address 2' && <Input label={fld.label} required={fld.required} value={add2} setValue={setAdd2} />}
							{fld.label === 'City' && <Input label={fld.label} required={fld.required} value={city} setValue={setCity} />}
							{fld.label === 'State' && (
								<>
									<label className='frmLabel'>{fld.label}</label>
									<select className='form-control mb-3' required={fld.required} value={state} onChange={(e) => setState(e.target.value)}>
										<option value=''>Select One...</option>
										<option value='AL'>Alabama</option>
										<option value='AK'>Alaska</option>
										<option value='AZ'>Arizona</option>
										<option value='AR'>Arkansas</option>
										<option value='CA'>California</option>
										<option value='CO'>Colorado</option>
										<option value='CT'>Connecticut</option>
										<option value='DE'>Delaware</option>
										<option value='DC'>District of Columbia</option>
										<option value='FL'>Florida</option>
										<option value='GA'>Georgia</option>
										<option value='HI'>Hawaii</option>
										<option value='ID'>Idaho</option>
										<option value='IL'>Illinois</option>
										<option value='IN'>Indiana</option>
										<option value='IA'>Iowa</option>
										<option value='KS'>Kansas</option>
										<option value='KY'>Kentucky</option>
										<option value='LA'>Louisiana</option>
										<option value='ME'>Maine</option>
										<option value='MD'>Maryland</option>
										<option value='MA'>Massachusetts</option>
										<option value='MI'>Michigan</option>
										<option value='MN'>Minnesota</option>
										<option value='MS'>Mississippi</option>
										<option value='MO'>Missouri</option>
										<option value='MT'>Montana</option>
										<option value='NE'>Nebraska</option>
										<option value='NV'>Nevada</option>
										<option value='NH'>New Hampshire</option>
										<option value='NJ'>New Jersey</option>
										<option value='NM'>New Mexico</option>
										<option value='NY'>New York</option>
										<option value='NC'>North Carolina</option>
										<option value='ND'>North Dakota</option>
										<option value='OH'>Ohio</option>
										<option value='OK'>Oklahoma</option>
										<option value='OR'>Oregon</option>
										<option value='PA'>Pennsylvania</option>
										<option value='RI'>Rhode Island</option>
										<option value='SC'>South Carolina</option>
										<option value='SD'>South Dakota</option>
										<option value='TN'>Tennessee</option>
										<option value='TX'>Texas</option>
										<option value='UT'>Utah</option>
										<option value='VT'>Vermont</option>
										<option value='VA'>Virginia</option>
										<option value='WA'>Washington</option>
										<option value='WV'>West Virginia</option>
										<option value='WI'>Wisconsin</option>
										<option value='WY'>Wyoming</option>
									</select>
								</>
							)}
							{fld.label === 'Zip Code' && <Input label={fld.label} required={fld.required} value={zip} setValue={setZip} />}
							{fld.label === 'Country' && <Input label={fld.label} required={fld.required} value={country} setValue={setCountry} />}
							{fld.label === 'Home Phone' && <Input label={fld.label} required={fld.required} value={hPhn} setValue={setHPhn} />}
							{fld.label === 'Cell Phone' && <Input label={fld.label} required={fld.required} value={cPhn} setValue={setCPhn} />}
							{fld.label === 'Work Phone' && <Input label={fld.label} required={fld.required} value={wPhn} setValue={setWPhn} />}
							{fld.label === 'Work Phone Ext.' && <Input label={fld.label} required={fld.required} value={wExt} setValue={setWExt} />}
							{fld.label === 'Comments' && (
								<div className='mb-3'>
									<label className='frmLabel'>{fld.label}</label>
									<textarea className='form-control' rows='4' required={fld.required} value={comment} onChange={(e) => setComment(e.target.value)}>
										{comment}
									</textarea>
								</div>
							)}
							{!arrCapture.includes(fld.label) && (
								<>
									{count === 1 && (
										<div className='mb-3'>
											<label className='frmLabel'>{fld.label}</label>
											<input className='form-control' type='text' required={fld.required} value={custRes1} onChange={(e) => handleResponse(e, fld.label, 1)} />
										</div>
									)}
									{count === 2 && (
										<div className='mb-3'>
											<label className='frmLabel'>{fld.label}</label>
											<input className='form-control' type='text' required={fld.required} value={custRes2} onChange={(e) => handleResponse(e, fld.label, 2)} />
										</div>
									)}
									{count === 3 && (
										<div className='mb-3'>
											<label className='frmLabel'>{fld.label}</label>
											<input className='form-control' type='text' required={fld.required} value={custRes3} onChange={(e) => handleResponse(e, fld.label, 3)} />
										</div>
									)}
									{count === 4 && (
										<div className='mb-3'>
											<label className='frmLabel'>{fld.label}</label>
											<input className='form-control' type='text' required={fld.required} value={custRes4} onChange={(e) => handleResponse(e, fld.label, 4)} />
										</div>
									)}
									{count === 5 && (
										<div className='mb-3'>
											<label className='frmLabel'>{fld.label}</label>
											<input className='form-control' type='text' required={fld.required} value={custRes5} onChange={(e) => handleResponse(e, fld.label, 5)} />
										</div>
									)}
									{count === 6 && (
										<div className='mb-3'>
											<label className='frmLabel'>{fld.label}</label>
											<input className='form-control' type='text' required={fld.required} value={custRes6} onChange={(e) => handleResponse(e, fld.label, 6)} />
										</div>
									)}
									<div style={{ display: 'none' }}>{count++}</div>
								</>
							)}
						</div>
					))}
					<div className='my-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading}>
							Submit Form
						</Button>
					</div>
				</form>
			)}
			{!shwForm && <Headline className='my-5' text='Form Submitted Successfully' />}
		</>
	);
}
