import React from 'react';
import './Forms.css';

export default function Textarea(props) {
	return (
		<div className='mb-3'>
			{props.noLabel && (
				<textarea
					className='form-control'
					id={props.inputId}
					value={props.value}
					maxLength={props.max}
					onChange={(e) => props.handler(e)}
				>
					{props.value}
				</textarea>
			)}
			{!props.noLabel && (
				<>
					<label className='form-label' htmlFor={props.inputId}>
						{props.inputLabel}
					</label>
					<textarea
						className='form-control'
						id={props.inputId}
						value={props.value}
						maxLength={props.max}
						onChange={(e) => props.handler(e)}
					>
						{props.value}
					</textarea>
				</>
			)}
		</div>
	);
}
