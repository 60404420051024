import { useCallback, useEffect, useState } from 'react';
import './Sponsors.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { storage } from '../../../firebase';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ImageEdit from '../../../components/forms/ImageEdit';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import defNoImg from '../../../assets/images/defNoImage.png';

export default function EditSponsor() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [sponsor, setSponsor] = useState({});
	const [name, setName] = useState('');
	const [logo, setLogo] = useState('');
	const [newImgUrl, setNewImgUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadSponsor = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSponsor/${id}`);
			setSponsor(data);
		} catch (err) {
			console.log(err);
		}
	}, [id]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/sponsors');
		}
	});

	useEffect(() => {
		if (sponsor.length === 0 || sponsor.length === undefined) {
			loadSponsor();
		}
	}, [sponsor, loadSponsor]);

	useEffect(() => {
		if (sponsor.name !== undefined && name === '') {
			setName(sponsor.name);
			if (sponsor.logo !== undefined && logo === '') {
				setLogo(sponsor.logo);
			} else {
				setLogo(defNoImg);
			}
		}
	}, [sponsor, name, logo]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			let uplImgUrl = '';
			if (newImgUrl !== '') {
				uplImgUrl = newImgUrl;
			} else {
				uplImgUrl = logo;
			}

			if (name.trim() === '') {
				const input = document.getElementById('name');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Name is Required');
				return;
			}

			const { data } = await axios.put(`/updSponsor/${id}`, {
				name,
				logo: uplImgUrl,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Sponsor successfully updated');
				setLoading(false);
				navigate('/sponsors');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleFileChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewImgUrl(URL);
					//Delete old file from storage
					if (logo !== defNoImg && logo !== undefined) {
						const imgRef = ref(storage, logo);
						deleteObject(imgRef);
					}
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};

	return (
		<AdminPageTemplate title='Edit Sponsor'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Name' type='text' value={name} setValue={setName} />
					<ImageEdit inputId='imgLogo' type='file' handler={handleFileChange} newUrl={newImgUrl} image={logo} imgDefault={defNoImg} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name}>
							Save Sponsor
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
