import React, { useCallback, useContext, useEffect, useState } from 'react';
import './Users.css';
import { AuthContext } from '../../../context/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import AddIcon from '../../../components/icons/AddIcon';
import DelIcon from '../../../components/icons/DelIcon';
import ContainerMd from '../../../components/containers/ContainerMd';
import ListRow from '../../../components/listrow/ListRow';
import Pagination from '../../../components/pagination/Pagination';
import CheckBoxActive from '../../../components/forms/CheckBoxActive';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';

export default function Users() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [users, setUsers] = useState([]);
	const [delItem, setDelItem] = useState(false);
	const [delDoc, setDelDoc] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(10);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = users.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(users.length / recordsPerPage);
	const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

	const loadUsers = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomerUsers/${auth.masterCust.id}`);
			setUsers(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	useEffect(() => {
		if (users.length === 0) {
			loadUsers();
		}
	}, [users, loadUsers]);

	const handleConfirmationBox = (user) => {
		if (!delItem) {
			document.querySelector('.confirm-bg').style.display = 'flex';
			document.querySelector('.confirmation-container').style.display = 'flex';
			setDelItem(true);
			setDelDoc(user);
		} else {
			document.querySelector('.confirm-bg').style.display = 'none';
			document.querySelector('.confirmation-container').style.display = 'none';
			setDelItem(false);
		}
	};

	const handleEdit = (e, id) => {
		e.preventDefault();
		navigate(`edit/${id}`);
	};

	const handleDelete = async (e) => {
		try {
			//delete user from database
			await axios.delete(`/delUser/${delDoc._id}`).then((res) => {
				const ok = res.data.ok;
				if (ok) {
					//update the list of users
					loadUsers();
					setDelItem(true);
					handleConfirmationBox();
					toast.success('User deleted successfully');
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleActive = async (e, value, id) => {
		e.preventDefault();
		try {
			await axios.put(`/updUser/${id}`, { active: value }).then(async (res) => {
				loadUsers();
				if (value) {
					toast.success('User is now active');
				} else {
					toast.success('User is now inactive');
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const nextPage = () => {
		if (currentPage !== nPages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<AdminPageTemplate title='Users'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link to='add'>
					<AddIcon alt='Add User' />
				</Link>
			</div>
			<ContainerMd>
				{currentRecords.length === 0 && <div className='noRecsText d-flex justify-content-center'>No Users Added Yet</div>}
				{currentRecords.map((user) => (
					<ListRow key={user._id}>
						<div className='listLinkText col-12 col-md-4' onClick={(e) => handleEdit(e, user._id)}>
							{user.fname} {user.lname}
						</div>
						<div className='listInfoText col-12 col-md-4'>{user.email}</div>
						<div className='listInfoText col-6 col-md-2'>{user.cphn}</div>
						<div className='col-6 col-md-2 d-flex flex-row justify-content-end'>
							<div className='col-6 d-flex justify-content-end'>
								<CheckBoxActive active={user.active} funcCall={handleActive} value={!user.active} id={user._id} />
							</div>
							<div className='col-6 d-flex justify-content-end'>
								<DelIcon alt='Delete' funcCall={() => handleConfirmationBox(user)} />
							</div>
						</div>
					</ListRow>
				))}
			</ContainerMd>
			{nPages >= 2 && <Pagination prev={prevPage} pgNums={pageNumbers} curPage={currentPage} handler={setCurrentPage} next={nextPage} />}
			{/* DELETE CONFIRMATION BOX */}
			<div className='confirmation-container'>
				<div className='confirmation-text'>
					Are you sure?
					<br />
					This cannot be undone!
				</div>
				<div className='button-container'>
					<button className='cancel-button' onClick={() => handleConfirmationBox()}>
						Cancel
					</button>
					<button className='confirmation-button' onClick={() => handleDelete()}>
						Delete
					</button>
				</div>
			</div>
			<div className='confirm-bg' onClick={() => handleConfirmationBox()}></div>
			{/* END DELETE CONFIRMATION BOX */}
		</AdminPageTemplate>
	);
}
