import React, { useEffect, useState } from 'react';
import './Samples.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import temp1 from '../../../assets/images/temp1.png';
import temp2 from '../../../assets/images/temp2.png';
import temp3 from '../../../assets/images/temp3.png';
import temp4 from '../../../assets/images/temp4.png';
import temp5 from '../../../assets/images/temp5.png';
import temp6 from '../../../assets/images/temp6.png';
import temp7 from '../../../assets/images/temp7.png';
import temp8 from '../../../assets/images/temp8.png';
import temp9 from '../../../assets/images/temp9.png';
import temp10 from '../../../assets/images/temp10.png';

function Samples() {
	const [temps, setTemps] = useState([]);

	useEffect(() => {
		if (temps.length === 0) {
			loadTemps();
		}
	}, [temps.length]);

	const loadTemps = async () => {
		try {
			const { data } = await axios.get(`/getTemplates`);
			setTemps(data);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Template Samples'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link className='backText' to='/promos/add'>
					&laquo; Back to Add Promo
				</Link>
			</div>
			<div className='row d-flex justify-content-evenly'>
				{temps.map((tmp) => (
					<div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 d-flex flex-column align-items-center my-3' key={tmp._id}>
						{tmp.image && <img className='tmpImg' src={tmp.image} title={tmp.name} alt={tmp.name} />}
						{!tmp.image && (
							<>
								{tmp.name === 'Multi-Sponsor' && <img className='tmpImg' src={temp1} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'Announcement' && <img className='tmpImg' src={temp2} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'Single Sponsor' && <img className='tmpImg' src={temp3} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'User Input Form' && <img className='tmpImg' src={temp4} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'Trivia Page' && <img className='tmpImg' src={temp5} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'Product Page' && <img className='tmpImg' src={temp6} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'Player Lineup' && <img className='tmpImg' src={temp7} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'Profile Page' && <img className='tmpImg' src={temp8} title={tmp.name} alt={tmp.name} />}
								{tmp.name === 'Qpon Page' && <img className='tmpImg' src={temp9} title={tmp.name} alt={tmp.name} />}
								{tmp.name === '2 - Buttons' && <img className='tmpImg' src={temp10} title={tmp.name} alt={tmp.name} />}
							</>
						)}
						<div className='tmpName'>{tmp.name}</div>
					</div>
				))}
			</div>
		</AdminPageTemplate>
	);
}

export default Samples;
