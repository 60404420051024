import { useCallback, useContext, useEffect, useState } from 'react';
import './Sports.css';
import { AuthContext } from '../../../context/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';

export default function EditSport() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { auth } = useContext(AuthContext);
	const [sport, setSport] = useState({});
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadSport = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSport/${id}/${auth.user.superuser}`);
			setSport(data);
		} catch (err) {
			console.log(err);
		}
	}, [id, auth]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/sports');
		}
	});

	useEffect(() => {
		if (sport.length === 0 || sport.length === undefined) {
			loadSport();
		}
	}, [sport, loadSport]);

	useEffect(() => {
		if (sport.sport !== undefined && name === '') {
			setName(sport.sport);
		}
	}, [sport, name]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			const { data } = await axios.put(`/updSport/${id}/${auth.user.superuser}`, {
				sport: name,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Sport updated successfully');
				setLoading(false);
				navigate('/sports');
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Edit Sport'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Name' type='text' value={name} setValue={setName} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
