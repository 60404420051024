import React, { useEffect, useState } from 'react';
import './Qsite.css';
import { toast } from 'react-toastify';
import Button from '../forms/Button';

export default function Trivia({ arrTrivia }) {
	const [index, setIndex] = useState(0);
	const [curQst, setCurQst] = useState('');
	const [curAns, setCurAns] = useState('');
	const [shwAns, setShwAns] = useState(false);

	useEffect(() => {
		if (arrTrivia.length !== 0 && curQst === '') {
			setCurQst(arrTrivia[index].question);
			setCurAns(arrTrivia[index].answer);
		}
	}, [arrTrivia, curQst, index]);

	const next = () => {
		let idx = 0;
		if (index < arrTrivia.length - 1) {
			idx = index + 1;
		} else {
			idx = 0;
		}
		setIndex(idx);
		setCurQst('');
		setCurAns('');
		setShwAns(false);
		if (idx === 0) {
			toast.success('You have reached the end of trivia');
		}
	};

	const handleShwAnswer = () => {
		setShwAns(true);
	};

	return (
		<div className='mt-4 mb-5'>
			<div className='row' style={{ height: '35px' }}>
				<div className='trvDiv d-flex justify-content-center'>
					<div className='trvQst mb-2 p-0 m-0'>{curQst}</div>
				</div>
			</div>
			{shwAns && (
				<>
					<div className='row' style={{ height: '35px' }}>
						<div className='trvDiv d-flex justify-content-center'>
							<div className='trvAns mb-2 p-0 m-0'>{curAns}</div>
						</div>
					</div>
					<div className='row' style={{ height: '35px' }}>
						<div className='trvDiv d-flex justify-content-center'>
							<Button type='button' onClick={next}>
								Next Question
							</Button>
						</div>
					</div>
				</>
			)}
			{!shwAns && (
				<>
					<div className='row' style={{ height: '35px' }}>
						<div className='trvDiv d-flex justify-content-center'>
							<div className='trvAns mb-2 p-0 m-0'></div>
						</div>
					</div>
					<div className='row' style={{ height: '35px' }}>
						<div className='trvDiv d-flex justify-content-center'>
							<Button type='button' onClick={handleShwAnswer}>
								Show Answer
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
