import React from 'react';
import './Forms.css';
import Input from './Input';

export default function SponsorElement(props) {
	return (
		<>
			<div className='mb-1'>
				<Input
					id={props.urlId}
					label={props.urlLabel}
					placeholder={props.placeholder}
					value={props.urlValue}
					setValue={props.setValue}
				/>
			</div>
			<div className='mb-3'>
				<select className='form-select' id={props.selId} value={props.value} onChange={(e) => props.handler(e)}>
					<option key='0' value=''>
						Select One...
					</option>
					{props.arr.map((val) => (
						<option key={val._id} value={val._id}>
							{val.name}
						</option>
					))}
				</select>
			</div>
		</>
	);
}
