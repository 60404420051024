import React from 'react';
import './Forms.css';

export default function CaptureForm(props) {
	return (
		<>
			<div className='mb-3'>
				<div className='col-md-2 col-xl-4'></div>
				<div className='prmHdng col-12 col-md-10 col-xl-8'>Choose Data to Capture:</div>
			</div>
			<div className='row mb-3 d-flex align-items-center'>
				<div className='col-md-1'></div>
				<div className='col-6 col-md-5'>
					<div className='prmColHdng'>Data</div>
				</div>
				<div className='col-3 text-center'>
					<div className='prmColHdng'>Capture</div>
				</div>
				<div className='col-3 text-center'>
					<div className='prmColHdng'>Require</div>
				</div>
			</div>
			{props.children}
		</>
	);
}
