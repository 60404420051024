export const getFromLocalStorage = (key) => {
	const auth = localStorage.getItem(key);
	if (auth) {
		return JSON.parse(auth);
	}

	return null;
};

export const saveInLocalStorage = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocalStorage = (key) => {
	const item = localStorage.getItem(key);
	if (item) {
		localStorage.removeItem(key);
	}
};
