import React from 'react';
import './QrqsAdmin.css';
import { Link } from 'react-router-dom';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import icoCusts from '../../../assets/images/pgCustomers.png';
import icoSports from '../../../assets/images/pgSports.png';
import icoTeams from '../../../assets/images/pgTeams.png';
import icoTypes from '../../../assets/images/pgTypes.png';
import icoTemps from '../../../assets/images/pgTemplate.png';

export default function QrqsAdmin() {
	return (
		<AdminPageTemplate title='Admin'>
			<div className='row d-flex justify-content-center'>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/customers'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoCusts} alt='Customers' />
							<br />
							Customers
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/sports'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoSports} alt='Sports' />
							<br />
							Sports
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/teams'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoTeams} alt='Teams' />
							<br />
							Teams
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/types'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoTypes} alt='Types' />
							<br />
							Types
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/templates'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoTemps} alt='Templates' />
							<br />
							Templates
						</div>
					</Link>
				</div>
			</div>
		</AdminPageTemplate>
	);
}
