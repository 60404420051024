import React from 'react';
import './Qsite.css';

export default function Buttons({ btns }) {
	return (
		<div className='row mb-3'>
			{btns.map((btn) => (
				<div className='col-8 offset-2' key={btn._id}>
					<a className='fw-bold fs-5 text-decoration-none' href={btn.url} target='_blank' rel='noreferrer'>
						<div className='btnDiv shadow'>{btn.text}</div>
					</a>
				</div>
			))}
		</div>
	);
}
