import { useContext, useEffect, useState } from 'react';
import './Templates.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import { storage } from '../../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import ImageEdit from '../../../components/forms/ImageEdit';
import defTempImg from '../../../assets/images/defTemplate.png';

export default function AddTemplate() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [name, setName] = useState('');
	const [imgUrl] = useState('');
	const [newImgUrl, setNewImgUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/templates');
		}
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			let uplImgUrl = '';
			if (newImgUrl !== '') {
				uplImgUrl = newImgUrl;
			} else {
				uplImgUrl = '';
			}

			if (name.trim() === '') {
				const input = document.getElementById('name');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Name is Required');
				return;
			}

			const { data } = await axios.post(`/addTemplate/${auth.user.superuser}`, {
				name,
				uplImgUrl,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Successfully added template');
				setLoading(false);
				navigate('/templates');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleFileChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/templates/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewImgUrl(URL);
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};

	return (
		<AdminPageTemplate title='Add Template'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Name' type='text' value={name} setValue={setName} />
					<div className='frmLabel mb-3'>Display Image</div>
					<ImageEdit inputId='imgTemp' type='file' handler={handleFileChange} newUrl={newImgUrl} image={imgUrl} imgDefault={defTempImg} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name}>
							Save Template
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
