import React, { useContext, useEffect, useState } from 'react';
import './Templates.css';
import { AuthContext } from '../../../context/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { storage } from '../../../firebase';
import { deleteObject, ref } from 'firebase/storage';
import axios from 'axios';
import AddIcon from '../../../components/icons/AddIcon';
import DelIcon from '../../../components/icons/DelIcon';
import ContainerSm from '../../../components/containers/ContainerSm';
import ListRow from '../../../components/listrow/ListRow';
import Pagination from '../../../components/pagination/Pagination';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';

export default function Templates() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [temps, setTemps] = useState([]);
	const [delItem, setDelItem] = useState(false);
	const [delDoc, setDelDoc] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(10);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = temps.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(temps.length / recordsPerPage);
	const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

	useEffect(() => {
		if (temps.length === 0) {
			loadTemps();
		}
	}, [temps.length]);

	const loadTemps = async () => {
		try {
			const { data } = await axios.get(`/getTemplates`);
			setTemps(data);
		} catch (err) {
			console.log(err);
		}
	};

	const handleConfirmationBox = (temp) => {
		if (!delItem) {
			document.querySelector('.confirm-bg').style.display = 'flex';
			document.querySelector('.confirmation-container').style.display = 'flex';
			setDelItem(true);
			setDelDoc(temp);
		} else {
			document.querySelector('.confirm-bg').style.display = 'none';
			document.querySelector('.confirmation-container').style.display = 'none';
			setDelItem(false);
		}
	};

	const handleEdit = (e, id) => {
		e.preventDefault();
		navigate(`edit/${id}`);
	};

	const handleDelete = async (e) => {
		try {
			//delete image from storage
			if (delDoc.image !== '' && delDoc !== undefined) {
				const imgRef = ref(storage, delDoc.image);
				deleteObject(imgRef);
			}
			//delete template from database
			await axios.delete(`/delTemplate/${delDoc._id}/${auth.user.superuser}`).then((res) => {
				const ok = res.data.ok;
				if (ok) {
					//update the list of templates
					loadTemps();
					setDelItem(true);
					handleConfirmationBox();
					toast.success('Template deleted successfully');
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const nextPage = () => {
		if (currentPage !== nPages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<AdminPageTemplate title='Templates'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link to='add'>
					<AddIcon alt='Add Template' />
				</Link>
			</div>
			<ContainerSm>
				{currentRecords.map((temp) => (
					<ListRow key={temp._id}>
						<div className='listLinkText col-8 col-md-10' onClick={(e) => handleEdit(e, temp._id)}>
							{temp.name}
						</div>
						<div className='col-4 col-md-2'>
							<div className='d-flex justify-content-end'>
								<DelIcon alt='Delete' funcCall={() => handleConfirmationBox(temp)} />
							</div>
						</div>
					</ListRow>
				))}
			</ContainerSm>
			{nPages >= 2 && <Pagination prev={prevPage} pgNums={pageNumbers} curPage={currentPage} handler={setCurrentPage} next={nextPage} />}
			{/* DELETE CONFIRMATION BOX */}
			<div className='confirmation-container'>
				<div className='confirmation-text'>
					Are you sure?
					<br />
					This cannot be undone!
				</div>
				<div className='button-container'>
					<button className='cancel-button' onClick={() => handleConfirmationBox()}>
						Cancel
					</button>
					<button className='confirmation-button' onClick={() => handleDelete()}>
						Delete
					</button>
				</div>
			</div>
			<div className='confirm-bg' onClick={() => handleConfirmationBox()}></div>
			{/* END DELETE CONFIRMATION BOX */}
		</AdminPageTemplate>
	);
}
