import React from 'react';
import './Links.css';

export default function Cancel({ text, action }) {
	return (
		<div className='cncLink' style={{ backgroundColor: 'transparent', border: 'none' }} onClick={action}>
			{text}
		</div>
	);
}
