import React, { useContext, useEffect, useState } from 'react';
import './Account.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { removeFromLocalStorage } from '../../../helpers/auth';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';

export default function Email() {
	const navigate = useNavigate();
	const { auth, setAuth } = useContext(AuthContext);
	const [curEmail, setCurEmail] = useState('');
	const [newEmail, setNewEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/account');
		}
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);

			if (curEmail.trim() === '') {
				const input = document.getElementById('curEmail');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Current Email is Required');
				return;
			}
			if (newEmail.trim() === '') {
				const input = document.getElementById('newEmail');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('New Email is Required');
				return;
			}

			await axios
				.put(`/chgEmail/${auth.user._id}`, {
					curEmail,
					newEmail,
				})
				.then((res) => {
					const ok = res.data.ok;
					if (ok) {
						toast.success('Email updated successfully. Please login again.');
						setLoading(false);
						setAuth(null);
						removeFromLocalStorage('auth');
						removeFromLocalStorage('userData');
						removeFromLocalStorage('_grecaptcha');
						navigate('/login');
					} else {
						toast.success('Email update failed. Please try again.');
					}
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Change Email'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='curEmail' id='curEmail' label='Current Email' type='email' value={curEmail} setValue={setCurEmail} />
					<Input name='newEmail' id='newEmail' label='New Email' type='email' value={newEmail} setValue={setNewEmail} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!curEmail || !newEmail}>
							Update Email
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
