import { useCallback, useContext, useEffect, useState } from 'react';
import './Templates.css';
import { AuthContext } from '../../../context/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { storage } from '../../../firebase';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import Input from '../../../components/forms/Input';
import ImageEdit from '../../../components/forms/ImageEdit';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import defTempImg from '../../../assets/images/defTemplate.png';

export default function EditTemplate() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { auth } = useContext(AuthContext);
	const [temp, setTemp] = useState({});
	const [name, setName] = useState('');
	const [imgUrl, setImgUrl] = useState('');
	const [newImgUrl, setNewImgUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadTemp = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getTemplate/${id}/${auth.user.superuser}`);
			setTemp(data);
		} catch (err) {
			console.log(err);
		}
	}, [id, auth]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/templates');
		}
	});

	useEffect(() => {
		if (temp.length === 0 || temp.length === undefined) {
			loadTemp();
		}
	}, [temp, loadTemp]);

	useEffect(() => {
		if (temp.name !== undefined && name === '') {
			setName(temp.name);
			setImgUrl(temp.image);
		}
	}, [temp, name]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let updUrl = '';
		if (newImgUrl !== '') {
			updUrl = newImgUrl;
		} else {
			updUrl = imgUrl;
		}
		try {
			setLoading(true);
			const { data } = await axios.put(`/updTemplate/${id}/${auth.user.superuser}`, {
				name,
				image: updUrl,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Template updated successfully');
				setLoading(false);
				navigate('/templates');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleFileChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/templates/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewImgUrl(URL);
					//Delete old file from storage
					if (imgUrl !== defTempImg && imgUrl !== undefined) {
						const imgRef = ref(storage, imgUrl);
						deleteObject(imgRef);
					}
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};

	return (
		<AdminPageTemplate title='Edit Template'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Name' type='text' value={name} setValue={setName} />
					<div className='frmLabel mb-3'>Display Image</div>
					<ImageEdit inputId='imgTemp' type='file' handler={handleFileChange} newUrl={newImgUrl} image={imgUrl} imgDefault={defTempImg} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
