import { useCallback, useEffect, useState } from 'react';
import './Venues.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import Checkbox from '../../../components/forms/Checkbox';

export default function EditLevel() {
	const navigate = useNavigate();
	const { venId } = useParams();
	const { lvlId } = useParams();
	const [level, setLevel] = useState({});
	const [description, setDescription] = useState('');
	const [abbr, setAbbr] = useState('');
	const [suite, setSuite] = useState(false);
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadLevel = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getLevel/${lvlId}`);
			setLevel(data);
		} catch (err) {
			console.log(err);
		}
	}, [lvlId]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate(`/venues/${venId}/levels`);
		}
	});

	useEffect(() => {
		if (level.length === 0 || level.length === undefined) {
			loadLevel();
		}
	}, [level, loadLevel]);

	useEffect(() => {
		if (level.description !== undefined && description === '') {
			setDescription(level.description);
			setAbbr(level.abbr);
			setSuite(level.suite);
		}
	}, [level, description]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			if (description.trim() === '') {
				const input = document.getElementById('description');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Description is Required');
				return;
			}
			if (abbr.trim() === '') {
				const input = document.getElementById('abbr');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Abbreviation is Required');
				return;
			}

			const { data } = await axios.put(`/updLevel/${lvlId}`, {
				description,
				abbr,
				suite,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Price Level successfully updated');
				setLoading(false);
				navigate(`/venues/${venId}/levels`);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleSuite = (e) => {
		const value = e.target.checked;
		setSuite(value);
	};

	return (
		<AdminPageTemplate title='Edit Price Level'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='description' id='description' label='Description' type='text' value={description} setValue={setDescription} />
					<Input name='abbr' id='abbr' label='Abbreviation' type='text' max='5' value={abbr} setValue={setAbbr} />
					<div className='row mb-3'>
						<div className='col-auto pe-2'>
							<Checkbox checked={suite} funcCall={handleSuite} />
						</div>
						<div className='col ps-2'>
							<div className='textSemi'>Suite</div>
						</div>
					</div>
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!description || !abbr}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
