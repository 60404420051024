import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpinnerInfinity } from 'spinners-react';
import { AuthContext } from '../../context/auth';
import { removeFromLocalStorage } from '../../helpers/auth';

export default function LoadingRedirect() {
	const navigate = useNavigate();
	const { setAuth } = useContext(AuthContext);
	const [count, setCount] = useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			setCount((curCount) => --curCount);
		}, 1000);

		if (count === 0) {
			setAuth(null);
			removeFromLocalStorage('auth');
			removeFromLocalStorage('userData');
			removeFromLocalStorage('_grecaptcha');
			navigate('/login');
		}

		return () => clearInterval(interval);
	}, [count, navigate, setAuth]);

	return (
		<div className='h-75 d-flex justify-content-center align-items-center'>
			<SpinnerInfinity color={'#9b00ef'} secondaryColor={'transparent'} size={60} />
		</div>
	);
}
