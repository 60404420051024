import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/auth';
import { GlobalStateProvider } from './context/GlobalState';
import { ToastContainer } from 'react-toastify';
import Background from './components/background/Background';
import NavBar from './components/nav/NavBar';
import Footer from './components/footer/Footer';
import Home from './pages/public/home/Home';
import Contact from './pages/public/contact/Contact';
import Login from './pages/public/login/Login';
import ForgotPassword from './pages/public/login/ForgotPassword';
import Qsite from './pages/public/qsite/Qsite';
import PageNotFound from './pages/public/pagenotfound/PageNotFound';
import AdminRoutes from './components/routes/AdminRoutes';
import Admin from './pages/admin/admin/Admin';
import Samples from './pages/admin/templates/Samples';
import Account from './pages/admin/account/Account';
import Email from './pages/admin/account/Email';
import Password from './pages/admin/account/Password';
import Promos from './pages/admin/promos/Promos';
import AddPromo from './pages/admin/promos/AddPromo';
import EditPromo from './pages/admin/promos/EditPromo';
import ViewPromo from './pages/admin/promos/ViewPromo';
import ViewQr from './pages/admin/promos/ViewQr';
import Profiles from './pages/admin/profiles/Profiles';
import AddProfile from './pages/admin/profiles/AddProfile';
import EditProfile from './pages/admin/profiles/EditProfile';
import Sponsors from './pages/admin/sponsors/Sponsors';
import AddSponsor from './pages/admin/sponsors/AddSponsor';
import EditSponsor from './pages/admin/sponsors/EditSponsor';
import Venues from './pages/admin/venues/Venues';
import AddVenue from './pages/admin/venues/AddVenue';
import EditVenue from './pages/admin/venues/EditVenue';
import Levels from './pages/admin/venues/Levels';
import AddLevel from './pages/admin/venues/AddLevel';
import EditLevel from './pages/admin/venues/EditLevel';
import Users from './pages/admin/users/Users';
import AddUser from './pages/admin/users/AddUser';
import EditUser from './pages/admin/users/EditUser';
import SuperRoutes from './components/routes/SuperRoutes';
import QrqsAdmin from './pages/qrqs/admin/QrqsAdmin';
import Customers from './pages/qrqs/customers/Customers';
import AddCustomer from './pages/qrqs/customers/AddCustomer';
import EditCustomer from './pages/qrqs/customers/EditCustomer';
import Sports from './pages/qrqs/sports/Sports';
import AddSport from './pages/qrqs/sports/AddSport';
import EditSport from './pages/qrqs/sports/EditSport';
import Teams from './pages/qrqs/teams/Teams';
import AddTeam from './pages/qrqs/teams/AddTeam';
import EditTeam from './pages/qrqs/teams/EditTeam';
import Types from './pages/qrqs/types/Types';
import AddType from './pages/qrqs/types/AddType';
import EditType from './pages/qrqs/types/EditType';
import Templates from './pages/qrqs/templates/Templates';
import AddTemplate from './pages/qrqs/templates/AddTemplate';
import EditTemplate from './pages/qrqs/templates/EditTemplate';

export default function App() {
	const [curWidth, setCurWidth] = useState(window.innerWidth);
	const [hide, setHide] = useState(false);

	useEffect(() => {
		function handleResize() {
			setCurWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		hideStuff();
	}, []);

	const hideStuff = () => {
		const url = window.location.href;
		let qsite = url.includes('/_/');
		if (qsite) {
			setHide(true);
		}
	};

	return (
		<BrowserRouter>
			<AuthProvider>
				<GlobalStateProvider>
					<Background width={curWidth} hide={hide}>
						<NavBar hide={hide} />
						<ToastContainer />
						<Routes>
							<Route path='/' element={<Home />} />
							<Route path='/_/:sub/:tUrl' element={<Qsite />} />
							<Route path='/contact' element={<Contact />} />
							<Route path='/login' element={<Login />} />
							<Route path='/forgotpassword' element={<ForgotPassword />} />
							<Route path='/admin' element={<AdminRoutes />}>
								<Route path='' element={<Admin />} />
							</Route>
							<Route path='/samples' element={<AdminRoutes />}>
								<Route path='' element={<Samples />} />
							</Route>
							<Route path='/account' element={<AdminRoutes />}>
								<Route path='' element={<Account />} />
								<Route path='email' element={<Email />} />
								<Route path='password' element={<Password />} />
							</Route>
							<Route path='/promos' element={<AdminRoutes />}>
								<Route path='' element={<Promos />} />
								<Route path='add' element={<AddPromo />} />
								<Route path='add/:id' element={<AddPromo />} />
								<Route path='edit/:id' element={<EditPromo />} />
								<Route path='view/:id' element={<ViewPromo />} />
								<Route path='qr/:id' element={<ViewQr />} />
							</Route>
							<Route path='/profiles' element={<AdminRoutes />}>
								<Route path='' element={<Profiles />} />
								<Route path='add' element={<AddProfile />} />
								<Route path='edit/:id' element={<EditProfile />} />
							</Route>
							<Route path='/sponsors' element={<AdminRoutes />}>
								<Route path='' element={<Sponsors />} />
								<Route path='add' element={<AddSponsor />} />
								<Route path='edit/:id' element={<EditSponsor />} />
							</Route>
							<Route path='/venues' element={<AdminRoutes />}>
								<Route path='' element={<Venues />} />
								<Route path='add' element={<AddVenue />} />
								<Route path='edit/:id' element={<EditVenue />} />
								<Route path=':venId/levels' element={<Levels />} />
								<Route path=':venId/levels/add' element={<AddLevel />} />
								<Route path=':venId/levels/:lvlId/edit' element={<EditLevel />} />
							</Route>
							<Route path='/users' element={<AdminRoutes />}>
								<Route path='' element={<Users />} />
								<Route path='add' element={<AddUser />} />
								<Route path='edit/:id' element={<EditUser />} />
							</Route>
							<Route path='/qrqsadmin' element={<SuperRoutes />}>
								<Route path='' element={<QrqsAdmin />} />
							</Route>
							<Route path='/customers' element={<SuperRoutes />}>
								<Route path='' element={<Customers />} />
								<Route path='add' element={<AddCustomer />} />
								<Route path='edit/:id' element={<EditCustomer />} />
							</Route>
							<Route path='/sports' element={<SuperRoutes />}>
								<Route path='' element={<Sports />} />
								<Route path='add' element={<AddSport />} />
								<Route path='edit/:id' element={<EditSport />} />
							</Route>
							<Route path='/teams' element={<SuperRoutes />}>
								<Route path='' element={<Teams />} />
								<Route path='add' element={<AddTeam />} />
								<Route path='edit/:id' element={<EditTeam />} />
							</Route>
							<Route path='/types' element={<SuperRoutes />}>
								<Route path='' element={<Types />} />
								<Route path='add' element={<AddType />} />
								<Route path='edit/:id' element={<EditType />} />
							</Route>
							<Route path='/templates' element={<SuperRoutes />}>
								<Route path='' element={<Templates />} />
								<Route path='add' element={<AddTemplate />} />
								<Route path='edit/:id' element={<EditTemplate />} />
							</Route>
							<Route path='*' element={<PageNotFound />} />
						</Routes>
						<Footer hide={hide} />
					</Background>
				</GlobalStateProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}
