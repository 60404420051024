import React, { useState } from 'react';
import './Contact.css';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import validator from 'validator';
import emailjs from '@emailjs/browser';
import Input from '../../../components/forms/Input';
import Robot from '../../../components/forms/Robot';
import Button from '../../../components/forms/Button';

const formatPhoneNumber = (value) => {
	if (!value) return value;
	let phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength > 10) {
		phoneNumber = phoneNumber.substring(0, 10);
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export default function Contact() {
	const [isValidToken, setIsValidToken] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [org, setOrg] = useState('');
	const [phone, setPhone] = useState('');
	const [time, setTime] = useState('');
	const [msg, setMsg] = useState('');
	const [occupation, setOccupation] = useState('');
	const [loading, setLoading] = useState(false);

	function handleSubmit(e) {
		e.preventDefault();
		setLoading(true);
		if (name.trim() === '') {
			const input = document.getElementById('name');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Name is Required');
			return;
		}
		if (email.trim() === '' || !validator.isEmail(email)) {
			const input = document.getElementById('email');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Please enter a valid Email');
			return;
		}
		if (org.trim() === '') {
			const input = document.getElementById('org');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Organization Name is Required');
			return;
		}
		if (phone.trim() === '') {
			const input = document.getElementById('phone');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Phone is Required');
			return;
		}
		if (time.trim() === '') {
			const input = document.getElementById('time');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Best time to reach you is Required');
			return;
		}
		if (msg.trim() === '') {
			const input = document.getElementById('msg');
			if (input != null) {
				input.style.backgroundColor = '#fac7ca';
				input.style.border = '2px solid black';
			}
			toast.error('Message is Required');
			return;
		}

		if (occupation === '') {
			//Send email to maurine
			const toSend = {
				fromName: name,
				fromEmail: email,
				fromOrg: org,
				fromPhone: phone,
				bestTime: time,
				msg: msg,
				toEmail: 'todd@zaxxiss.com',
			};
			emailjs.send('service_heg30c2', 'template_8om3x2s', toSend, 'user_QwSUk6avGckJ2ufY4kqDl');
			setLoading(false);
			toast.success('Thank you. Your request has been received.');
		} else {
			setLoading(false);
			toast.success('Thank you. Your request has been received.');
		}
	}

	function handlePhone(e) {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setPhone(formattedPhoneNumber);
	}

	function handleTime(e) {
		const value = e.target.value;
		setTime(value);
	}

	function handleMessage(e) {
		const value = e.target.value;
		setMsg(value);
	}

	async function handleReCaptchaVerify(token) {
		if (!token) {
			return;
		}

		token && setIsValidToken(true);
	}

	return (
		<div className='mt-5 pt-5'>
			<div className='container py-3'>
				<h2 className='text-center'>Contact Us</h2>
				<div className='formContainer shadow-lg rounded p-3 mx-auto'>
					<form onSubmit={handleSubmit}>
						<GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
						{isValidToken && (
							<>
								<Input name='name' id='name' label='Full Name' type='text' value={name} setValue={setName} />
								<Input
									name='email'
									id='email'
									label='Email Address'
									type='email'
									value={email}
									setValue={setEmail}
								/>
								<Input name='org' id='org' label='Organization' type='text' value={org} setValue={setOrg} />
								<Input
									name='phone'
									id='phone'
									label='Phone #'
									type='tel'
									value={phone}
									funcCall={handlePhone}
								/>
								<Robot
									name='occupation'
									id='occupation'
									label='Occupation'
									type='text'
									value={occupation}
									setValue={setOccupation}
								/>
								<div className='mb-3'>
									<label className='frmLabel'>Best time to reach you</label>
									<select className='form-select' id='time' value={time} onChange={(e) => handleTime(e)}>
										<option defaultValue=''>Select One...</option>
										<option value='Morning'>Morning</option>
										<option value='Afternoon'>Afternoon</option>
										<option value='Evening'>Evening</option>
									</select>
								</div>
								<div className='mb-3'>
									<label className='frmLabel'>Message</label>
									<textarea
										className='form-control'
										id='msg'
										value={msg}
										onChange={(e) => handleMessage(e)}
									/>
								</div>
								<div className='mt-4 d-flex justify-content-center'>
									<Button
										loading={loading}
										type='submit'
										disabled={!name || !email || !org || !phone || !time || !msg}
									>
										Hit Us Up
									</Button>
								</div>
							</>
						)}
					</form>
				</div>
			</div>
		</div>
	);
}
