import React, { useContext, useEffect, useState } from 'react';
import './Promos.css';
import { AuthContext } from '../../../context/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { storage } from '../../../firebase';
import { deleteObject, ref } from 'firebase/storage';
import axios from 'axios';
import AddIcon from '../../../components/icons/AddIcon';
import DelIcon from '../../../components/icons/DelIcon';
import ListRow from '../../../components/listrow/ListRow';
import Pagination from '../../../components/pagination/Pagination';
import ContainerMd from '../../../components/containers/ContainerMd';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import icoActive from '../../../assets/images/icoChk.png';
import icoView from '../../../assets/images/icoView.png';
import icoQR from '../../../assets/images/icoQR.png';

const fixDate = (date) => {
	const oldDate = new Date(date);
	let year = oldDate.getFullYear();
	let month = oldDate.getMonth() + 1;
	let day = oldDate.getDate();
	let hour = oldDate.getHours();
	let minutes = oldDate.getMinutes();

	//format date
	const strDate = `${month}/${day}/${year}`;

	//format time
	const ampm = hour >= 12 ? 'pm' : 'am';
	hour = hour % 12;
	hour = hour ? hour : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hour + ':' + minutes + ' ' + ampm;

	return `${strDate} ${strTime}`;

	/*
	const [year, month, day] = date.split('-');
	const result = `${month}/${day}/${year}`;
	return result;
    */
};

export default function Promos() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [promos, setPromos] = useState([]);
	const [noPromos, setNoPromos] = useState(false);
	const [delItem, setDelItem] = useState(false);
	const [delDoc, setDelDoc] = useState({});
	const [tnyUrls, setTnyUrls] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(10);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = promos.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(promos.length / recordsPerPage);
	const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

	useEffect(() => {
		if (tnyUrls.length === 0 && !noPromos) {
			const initTinyUrls = async () => {
				try {
					const { data } = await axios.get(`/getTinyUrls/${auth.masterCust.id}`);
					if (data.length === 0) {
						setNoPromos(true);
					} else {
						setTnyUrls(data);
					}
				} catch (err) {
					toast.error(err);
				}
			};
			initTinyUrls();
		}
	}, [tnyUrls, noPromos, auth, setNoPromos, setTnyUrls, promos]);

	useEffect(() => {
		if (promos.length === 0 && !noPromos) {
			async function initPromos() {
				try {
					const { data } = await axios.get(`/getCustomerPromos/${auth.masterCust.id}`);
					if (data.length === 0) {
						setNoPromos(true);
					} else {
						setPromos(data);
					}
				} catch (err) {
					console.log(err);
				}
			}
			initPromos();
		}
	}, [promos, noPromos, auth, setNoPromos, setPromos]);

	const loadPromos = async () => {
		try {
			const { data } = await axios.get(`/getCustomerPromos/${auth.masterCust.id}`);
			setPromos(data);
		} catch (err) {
			toast.error(err);
		}
	};

	const handleConfirmationBox = (prm) => {
		if (!delItem) {
			document.querySelector('.confirm-bg').style.display = 'flex';
			document.querySelector('.confirmation-container').style.display = 'flex';
			setDelItem(true);
			setDelDoc(prm);
		} else {
			document.querySelector('.confirm-bg').style.display = 'none';
			document.querySelector('.confirmation-container').style.display = 'none';
			setDelItem(false);
		}
	};

	const handleEdit = (e, id) => {
		//e.preventDefault();
		navigate(`edit/${id}`);
	};

	const handleDelete = async (e) => {
		try {
			if (delDoc.header !== '' && delDoc.header !== undefined) {
				//delete header image from storage
				const imgRef = ref(storage, delDoc.header);
				deleteObject(imgRef);
			}
			if (delDoc.qpon !== '' && delDoc.qpon !== undefined) {
				//delete qpon image from storage
				const imgRef = ref(storage, delDoc.qpon);
				deleteObject(imgRef);
			}

			//DELETE ALL OTHER RECORDS ASSOCIATED WITH PROMO

			//delete promo from database
			await axios.delete(`/delPromo/${delDoc._id}`).then((res) => {
				const ok = res.data.ok;
				if (ok) {
					//update the list of promos
					setDelItem(true);
					handleConfirmationBox();
					loadPromos();
					toast.success('Promo deleted successfully');
				}
			});
		} catch (err) {
			toast.error(err);
		}
	};

	const nextPage = () => {
		if (currentPage !== nPages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<AdminPageTemplate title='Promos'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link to='add'>
					<AddIcon alt='Add Promo' />
				</Link>
			</div>
			<ContainerMd>
				{currentRecords.length === 0 && <div className='noRecsText d-flex justify-content-center'>No Promos Added Yet</div>}
				{tnyUrls.map((tny) => (
					<div key={tny}>
						<div className='col-12 mb-3 d-flex flex-row align-items-center'>
							<div className='col-10'>
								<div className='txtTinyUrl'>{tny}</div>
							</div>
							<div className='col-2 d-flex justify-content-end'>
								<Link to={`add/${tny}`}>
									<AddIcon className='smAdd' alt='Add Promo' />
								</Link>
							</div>
						</div>
						{currentRecords.map((prm) => (
							<div key={prm._id}>
								{prm.tinyurl === tny && (
									<ListRow>
										<div className='row ps-5'>
											<div className='col-4'>
												<div className='listLinkText' onClick={(e) => handleEdit(e, prm._id)}>
													{prm.name}
												</div>
											</div>
											<div className='col-1 d-flex justify-content-center'>
												{prm.active && <img className='icoAct' src={icoActive} alt='Active' title='Active Promo' />}
											</div>
											<div className='col-1 d-flex justify-content-center'>
												<div className='listInfoText'>{prm.sequence}</div>
											</div>
											<div className='col-3 d-flex justify-content-center'>
												<div className='listInfoText'>{fixDate(prm.expdate)}</div>
											</div>
											<div className='col-1 d-flex justify-content-end'>
												<Link to={`view/${prm._id}`}>
													<img className='icoView' src={icoView} alt='View' title='View Promo' />
												</Link>
											</div>
											<div className='col-1 d-flex justify-content-end'>
												<Link to={`qr/${prm._id}`}>
													<img className='icoQR' src={icoQR} alt='QR Code' title='View QR Code' />
												</Link>
											</div>
											<div className='col-1 d-flex justify-content-end'>
												<DelIcon alt='Delete' funcCall={() => handleConfirmationBox(prm)} />
											</div>
										</div>
									</ListRow>
								)}
							</div>
						))}
					</div>
				))}
			</ContainerMd>
			{nPages >= 2 && <Pagination prev={prevPage} pgNums={pageNumbers} curPage={currentPage} handler={setCurrentPage} next={nextPage} />}
			{/* DELETE CONFIRMATION BOX */}
			<div className='confirmation-container'>
				<div className='confirmation-text'>
					Are you sure?
					<br />
					This cannot be undone!
				</div>
				<div className='button-container'>
					<button className='cancel-button' onClick={() => handleConfirmationBox()}>
						Cancel
					</button>
					<button className='confirmation-button' onClick={() => handleDelete()}>
						Delete
					</button>
				</div>
			</div>
			<div className='confirm-bg' onClick={() => handleConfirmationBox()}></div>
			{/* END DELETE CONFIRMATION BOX */}
		</AdminPageTemplate>
	);
}
