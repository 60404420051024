import React from 'react';

export default function Product({ prmSpns, allSpns, prodPic1, prodPic2 }) {
	return (
		<div className='row mb-2'>
			<div className='col-6 h-100 d-flex flex-column justify-content-end'>
				{prmSpns.map((prmSpn) => (
					<div className='spnDiv shadow' key={prmSpn._id}>
						<a href={prmSpn.url} target='_blank' rel='noreferrer'>
							{allSpns.map((spn) => (
								<div key={spn._id}>{spn._id === prmSpn.sponsorId && <img className='spnImg' src={spn.logo} alt={spn.name} />}</div>
							))}
						</a>
					</div>
				))}
			</div>
			<div className='col-6 h-100'>
				{prodPic1 !== '' && (
					<div className='row'>
						<div className='col-12'>
							<img className='prfImg mb-2' src={prodPic1} alt='Product' />
						</div>
					</div>
				)}
				{prodPic2 !== '' && (
					<div className='row'>
						<div className='col-12'>
							<img className='prfImg' src={prodPic2} alt='Product' />
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
