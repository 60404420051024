import React from 'react';
import './Background.css';

export default function Background({ width, hide, children }) {
	return (
		<>
			{!hide && (
				<>
					{width >= 1200 && <div className='bgLarge'>{children}</div>}
					{width >= 768 && width <= 1199 && <div className='bgMedium'>{children}</div>}
					{width <= 767 && <div className='bgSmall'>{children}</div>}
				</>
			)}
			{hide && <div>{children}</div>}
		</>
	);
}
