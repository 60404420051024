import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyCkTGmpP5w-2hSiU8yJ-5iZxAXJbacbs5o',
	authDomain: 'qrqsite-e7c69.firebaseapp.com',
	projectId: 'qrqsite-e7c69',
	storageBucket: 'qrqsite-e7c69.appspot.com',
	messagingSenderId: '670531713585',
	appId: '1:670531713585:web:c250e233becf3b74aeb310',
	measurementId: 'G-P6CG3E00RW',
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
