import { useCallback, useContext, useEffect, useState } from 'react';
import './Promos.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import { storage } from '../../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxMd from '../../../components/shadowboxes/ShadowBoxMd';
import Checkbox from '../../../components/forms/Checkbox';
import HdrImgEdit from '../../../components/forms/HdrImgEdit';
import Textarea from '../../../components/forms/Textarea';
import SponsorElement from '../../../components/forms/SponsorElement';
import CustomBtnElement from '../../../components/forms/CustomBtnElement';
import CaptureForm from '../../../components/forms/CaptureForm';
import CaptureFormElement from '../../../components/forms/CaptureFormElement';
import CaptureFormCustom from '../../../components/forms/CaptureFormCustom';
import TriviaElement from '../../../components/forms/TriviaElement';
import PlayersForm from '../../../components/forms/PlayersForm';
import ImageEdit from '../../../components/forms/ImageEdit';
import defHdrImg from '../../../assets/images/defHdrImage.jpg';
import defQponImg from '../../../assets/images/defNoImage.png';

const randomStringMake = (count) => {
	const letter = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let randomString = '';
	for (let i = 0; i < count; i++) {
		const randomStringNumber = Math.floor(1 + Math.random() * (letter.length - 1));
		randomString += letter.substring(randomStringNumber, randomStringNumber + 1);
	}
	return randomString;
};

const onlyNums = (value) => {
	let newVal = value.replace(/[^\d]/g, '');
	return newVal;
};

const formatPhone = (value) => {
	if (!value) return value;
	let phoneNumber = value.toString();
	phoneNumber = phoneNumber.replace(/[^\d]/g, '');
	if (phoneNumber.charAt(0) === '1' || phoneNumber.charAt(0) === 1) {
		phoneNumber = '' + phoneNumber.slice(1);
	}
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength >= 10) {
		phoneNumber = phoneNumber.substring(0, 10);
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export default function AddPromo() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const { id } = useParams();
	//General Values
	const scheme = 'light';
	const [temps, setTemps] = useState([]);
	const [tempId, setTempId] = useState('');
	const [tempName, setTempName] = useState('');
	const [venues, setVenues] = useState([]);
	const [venId, setVenId] = useState('');
	const [levels, setLevels] = useState([]);
	const [lvlId, setLvlId] = useState('');
	//Settings
	const [name, setName] = useState('');
	const [seq, setSeq] = useState('');
	const [newQRImgUrl, setNewQRImgUrl] = useState('');
	const [stDate, setStDate] = useState('');
	const [exDate, setExDate] = useState('');
	const [recurring, setRecurring] = useState(false);
	const [interval, setInterval] = useState('');
	const [shwSoc, setShwSoc] = useState(false);
	const [svdSoc, setSvdSoc] = useState({});
	const [fb, setFb] = useState('');
	const [tw, setTw] = useState('');
	const [ig, setIg] = useState('');
	const [tt, setTt] = useState('');
	const [donation, setDonation] = useState('');
	const [security, setSecurity] = useState('');
	const [dispSecurity, setDispSecurity] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [phone, setPhone] = useState('');
	const [dispPhone, setDispPhone] = useState('');
	//Layout
	const [newImgUrl, setNewImgUrl] = useState('');
	const [headline, setHeadline] = useState('');
	const [bdyText, setBdyText] = useState('');
	const [video, setVideo] = useState('');
	//Sponsors
	const [sponsors, setSponsors] = useState([]);
	const [spn1Id, setSpn1Id] = useState('');
	const [spn1Url, setSpn1Url] = useState('');
	const [spn2Id, setSpn2Id] = useState('');
	const [spn2Url, setSpn2Url] = useState('');
	const [spn3Id, setSpn3Id] = useState('');
	const [spn3Url, setSpn3Url] = useState('');
	//Custom Buttons
	const [btnNum, setBtnNum] = useState('');
	const [btn1Txt, setBtn1Txt] = useState('');
	const [btn1Url, setBtn1Url] = useState('');
	const [btn2Txt, setBtn2Txt] = useState('');
	const [btn2Url, setBtn2Url] = useState('');
	const [btn3Txt, setBtn3Txt] = useState('');
	const [btn3Url, setBtn3Url] = useState('');
	const [btn4Txt, setBtn4Txt] = useState('');
	const [btn4Url, setBtn4Url] = useState('');
	const [btn5Txt, setBtn5Txt] = useState('');
	const [btn5Url, setBtn5Url] = useState('');
	//Capture Form
	const [fNameC, setFNameC] = useState(false);
	const [fNameR, setFNameR] = useState(false);
	const [lNameC, setLNameC] = useState(false);
	const [lNameR, setLNameR] = useState(false);
	const [emailC, setEmailC] = useState(false);
	const [emailR, setEmailR] = useState(false);
	const [add1C, setAdd1C] = useState(false);
	const [add1R, setAdd1R] = useState(false);
	const [add2C, setAdd2C] = useState(false);
	const [add2R, setAdd2R] = useState(false);
	const [cityC, setCityC] = useState(false);
	const [cityR, setCityR] = useState(false);
	const [stateC, setStateC] = useState(false);
	const [stateR, setStateR] = useState(false);
	const [zipC, setZipC] = useState(false);
	const [zipR, setZipR] = useState(false);
	const [countryC, setCountryC] = useState(false);
	const [countryR, setCountryR] = useState(false);
	const [hPhnC, setHphnC] = useState(false);
	const [hPhnR, setHphnR] = useState(false);
	const [cPhnC, setCphnC] = useState(false);
	const [cPhnR, setCphnR] = useState(false);
	const [wPhnC, setWphnC] = useState(false);
	const [wPhnR, setWphnR] = useState(false);
	const [wExtC, setWextC] = useState(false);
	const [wExtR, setWextR] = useState(false);
	const [commC, setCommC] = useState(false);
	const [commR, setCommR] = useState(false);
	const [cust1, setCust1] = useState('');
	const [cust1C, setCust1C] = useState(false);
	const [cust1R, setCust1R] = useState(false);
	const [cust2, setCust2] = useState('');
	const [cust2C, setCust2C] = useState(false);
	const [cust2R, setCust2R] = useState(false);
	const [cust3, setCust3] = useState('');
	const [cust3C, setCust3C] = useState(false);
	const [cust3R, setCust3R] = useState(false);
	const [cust4, setCust4] = useState('');
	const [cust4C, setCust4C] = useState(false);
	const [cust4R, setCust4R] = useState(false);
	const [cust5, setCust5] = useState('');
	const [cust5C, setCust5C] = useState(false);
	const [cust5R, setCust5R] = useState(false);
	const [cust6, setCust6] = useState('');
	const [cust6C, setCust6C] = useState(false);
	const [cust6R, setCust6R] = useState(false);
	//Trivia
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');
	const [trivia, setTrivia] = useState([]);
	const [trvCount, setTrvCount] = useState(0);
	//Product
	const [newProdImg1, setNewProdImg1] = useState('');
	const [newProdImg2, setNewProdImg2] = useState('');
	//Player Lineup
	const [profiles, setProfiles] = useState([]);
	const [arrPlayers, setArrPlayers] = useState([]);
	//Profile
	const [profileId, setProfileId] = useState('');
	//Qpon
	const [newQponUrl, setNewQponUrl] = useState('');

	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	/********************************************************************************************************************************************/
	/*** LOAD ARRAY FUNCTIONS ***/
	/********************************************************************************************************************************************/
	const loadTemps = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getTemplates`);
			setTemps(data);
		} catch (err) {
			toast.error(err);
		}
	}, []);
	const loadVenues = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomerVenues/${auth.masterCust.id}`);
			setVenues(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);
	const loadLevels = useCallback(async () => {
		if (venId !== '' && venId !== undefined) {
			try {
				const { data } = await axios.get(`/getVenueLevels/${venId}`);
				setLevels(data);
			} catch (err) {
				toast.error(err);
			}
		}
	}, [venId]);
	const loadSponsors = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomerSponsors/${auth.masterCust.id}`);
			setSponsors(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);
	const loadProfiles = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomerProfiles/${auth.masterCust.id}`);
			setProfiles(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);
	const loadSavedSocials = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSvdSocs/${auth.masterCust.id}`);
			setSvdSoc({ data });
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);
	/********************************************************************************************************************************************/
	/*** END LOAD ARRAY FUNCTIONS ***/
	/********************************************************************************************************************************************/

	/********************************************************************************************************************************************/
	/*** SET INITIAL VALUES ***/
	/********************************************************************************************************************************************/
	useEffect(() => {
		if (cancelRedirect) {
			navigate('/promos');
		}
	});

	useEffect(() => {
		if (temps.length === 0) {
			loadTemps();
		}
	}, [temps, loadTemps]);

	useEffect(() => {
		if (venues.length === 0) {
			loadVenues();
		}
	}, [venues, loadVenues]);

	useEffect(() => {
		if (venId !== '' && levels.length === 0) {
			loadLevels();
		}
	}, [venId, levels, loadLevels]);

	useEffect(() => {
		if (
			sponsors.length === 0 &&
			(tempName === 'Multi-Sponsor' ||
				tempName === 'Single Sponsor' ||
				tempName === 'Product Page' ||
				tempName === 'Player Lineup' ||
				tempName === 'Profile Page')
		) {
			loadSponsors();
		}
	}, [sponsors, tempName, loadSponsors]);

	useEffect(() => {
		if (profiles.length === 0 && (tempName === 'Player Lineup' || tempName === 'Profile Page')) {
			loadProfiles();
		}
	}, [profiles, tempName, loadProfiles]);

	useEffect(() => {
		loadSavedSocials();
	}, [loadSavedSocials]);

	useEffect(() => {
		if (
			svdSoc.data &&
			fb === '' &&
			tw === '' &&
			ig === '' &&
			tt === '' &&
			donation === '' &&
			security === '' &&
			email === '' &&
			subject === '' &&
			phone === ''
		) {
			setFb(svdSoc.data.facebook);
			setTw(svdSoc.data.twitter);
			setIg(svdSoc.data.instagram);
			setTt(svdSoc.data.tiktok);
			setDonation(svdSoc.data.donation);
			setDispSecurity(formatPhone(svdSoc.data.security));
			setEmail(svdSoc.data.email);
			setSubject(svdSoc.data.subject);
			setDispPhone(formatPhone(svdSoc.data.phone));
		}
	}, [svdSoc, fb, tw, ig, tt, donation, security, email, subject, phone]);

	/********************************************************************************************************************************************/
	/*** END SET INITIAL VALUES ***/
	/********************************************************************************************************************************************/

	/********************************************************************************************************************************************/
	/*** SUBMIT FUNCTIONS ***/
	/********************************************************************************************************************************************/
	const handleSubmit = async (e) => {
		e.preventDefault();
		//HANDLE DATES AND TIMES
		//Get GMT offset hours
		let offset = new Date().getTimezoneOffset();
		offset = offset / 60;
		//Set Unix Times
		const unixstart = parseInt((new Date(stDate).getTime() / 1000).toFixed(0));
		const unixend = parseInt((new Date(exDate).getTime() / 1000).toFixed(0));

		//Create Tiny URL
		let tinyUrl = '';
		let abbr = '';
		if (id !== '' && id !== undefined) {
			tinyUrl = id;
		} else {
			if (lvlId !== '') {
				//get abbreviation
				try {
					const { data } = await axios.get(`/getLevel/${lvlId}`);
					abbr = data.abbr;
				} catch (err) {
					toast.error(err);
				}
			}
			if (abbr !== '') {
				const len = abbr.length;
				if (len === 1) {
					const endTUrl = randomStringMake(11);
					tinyUrl = (abbr + '_' + endTUrl).toString();
				}
				if (len === 2) {
					const endTUrl = randomStringMake(10);
					tinyUrl = (abbr + '_' + endTUrl).toString();
				}
				if (len === 3) {
					const endTUrl = randomStringMake(9);
					tinyUrl = (abbr + '_' + endTUrl).toString();
				}
				if (len === 4) {
					const endTUrl = randomStringMake(8);
					tinyUrl = (abbr + '_' + endTUrl).toString();
				}
				if (len === 5) {
					const endTUrl = randomStringMake(7);
					tinyUrl = (abbr + '_' + endTUrl).toString();
				}
			} else {
				const tnyurl = randomStringMake(13);
				tinyUrl = tnyurl;
			}
		}

		//Create Qsite URL
		let subdomain = '';
		let qsite = '';
		//get customer subdomain
		const { data } = await axios.get(`/getCustForSub/${auth.masterCust.id}`);
		subdomain = data.subdomain;
		qsite = `https://qrqsite.com/${subdomain}/${tinyUrl}`;

		//Save current social media to saved social
		if (!svdSoc.data) {
			await axios.post('/addSavedSocial', {
				fb,
				tw,
				ig,
				tt,
				donation,
				security,
				email,
				subject,
				phone,
				customerId: auth.user.customerId,
			});
		} else {
			await axios.put(`/updSavedSocial/${svdSoc.data._id}`, {
				facebook: fb,
				twitter: tw,
				instagram: ig,
				tiktok: tt,
				donation,
				security,
				email,
				subject,
				phone,
			});
		}

		try {
			setLoading(true);
			if (name.trim() === '') {
				const input = document.getElementById('name');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Name is Required');
				return;
			}
			if (seq.trim() === '') {
				const input = document.getElementById('seq');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Sequence is Required');
				return;
			}
			if (tempId.trim() === '') {
				const input = document.getElementById('template');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Template is Required');
				return;
			}

			//Fix any null ObjectIds
			let prmProfileId = '';
			let prmVenueId = '';
			let prmLevelId = '';
			if (profileId === '') {
				prmProfileId = null; //'000000000000000000000000';
			} else {
				prmProfileId = profileId;
			}
			if (venId === '') {
				prmVenueId = null; //'000000000000000000000000';
			} else {
				prmVenueId = venId;
			}
			if (lvlId === '') {
				prmLevelId = null; //'000000000000000000000000';
			} else {
				prmLevelId = lvlId;
			}

			const newPromo = await axios.post('/addPromo', {
				name,
				tinyurl: tinyUrl,
				qsite: qsite,
				sequence: seq,
				qrimg: newQRImgUrl,
				recurring,
				recurstarttime: 0,
				recurendtime: 0,
				recurinterval: interval,
				header: newImgUrl,
				headline,
				bodytext: bdyText,
				video,
				qpon: newQponUrl,
				prodImg1: newProdImg1,
				prodImg2: newProdImg2,
				shwsocial: shwSoc,
				colorscheme: scheme,
				startdate: stDate,
				expdate: exDate,
				tzoffset: offset,
				unixstart,
				unixend,
				tempname: tempName,
				templateId: tempId,
				profileId: prmProfileId,
				venueId: prmVenueId,
				levelId: prmLevelId,
				customerId: auth.masterCust.id,
			});
			const newPromoId = newPromo.data._id;

			//Social Links
			if (shwSoc) {
				await axios.post('/addSocial', {
					fb,
					tw,
					ig,
					tt,
					donation,
					security,
					email,
					subject,
					phone,
					promoId: newPromoId,
				});
			}

			//Sponsors
			if (spn1Id !== '' && spn1Url !== '') {
				await axios.post('/addPrmSpn', {
					order: 1,
					url: spn1Url,
					sponsorId: spn1Id,
					promoId: newPromoId,
				});
			}
			if (spn2Id !== '' && spn2Url !== '') {
				await axios.post('/addPrmSpn', {
					order: 2,
					url: spn2Url,
					sponsorId: spn2Id,
					promoId: newPromoId,
				});
			}
			if (spn3Id !== '' && spn3Url !== '') {
				await axios.post('/addPrmSpn', {
					order: 3,
					url: spn3Url,
					sponsorId: spn3Id,
					promoId: newPromoId,
				});
			}

			//Custom Buttons
			if (btn1Txt !== '' && btn1Url !== '') {
				await axios.post('/addButton', {
					order: 1,
					text: btn1Txt,
					url: btn1Url,
					promoId: newPromoId,
				});
			}
			if (btn2Txt !== '' && btn2Url !== '') {
				await axios.post('/addButton', {
					order: 2,
					text: btn2Txt,
					url: btn2Url,
					promoId: newPromoId,
				});
			}
			if (btn3Txt !== '' && btn3Url !== '') {
				await axios.post('/addButton', {
					order: 3,
					text: btn3Txt,
					url: btn3Url,
					promoId: newPromoId,
				});
			}
			if (btn4Txt !== '' && btn4Url !== '') {
				await axios.post('/addButton', {
					order: 4,
					text: btn4Txt,
					url: btn4Url,
					promoId: newPromoId,
				});
			}
			if (btn5Txt !== '' && btn5Url !== '') {
				await axios.post('/addButton', {
					order: 5,
					text: btn5Txt,
					url: btn5Url,
					promoId: newPromoId,
				});
			}

			//Capture Form
			if (tempName === 'User Input Form') {
				let count = 1;
				if (fNameC) {
					await axios.post('/addCaptureForm', {
						label: 'First Name',
						field: 'fname',
						order: count,
						required: fNameR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (lNameC) {
					await axios.post('/addCaptureForm', {
						label: 'Last Name',
						field: 'lname',
						order: count,
						required: lNameR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (emailC) {
					await axios.post('/addCaptureForm', {
						label: 'Email',
						field: 'email',
						order: count,
						required: emailR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (add1C) {
					await axios.post('/addCaptureForm', {
						label: 'Address 1',
						field: 'add1',
						order: count,
						required: add1R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (add2C) {
					await axios.post('/addCaptureForm', {
						label: 'Address 2',
						field: 'add2',
						order: count,
						required: add2R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cityC) {
					await axios.post('/addCaptureForm', {
						label: 'City',
						field: 'city',
						order: count,
						required: cityR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (stateC) {
					await axios.post('/addCaptureForm', {
						label: 'State',
						field: 'state',
						order: count,
						required: stateR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (zipC) {
					await axios.post('/addCaptureForm', {
						label: 'Zip Code',
						field: 'zip',
						order: count,
						required: zipR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (countryC) {
					await axios.post('/addCaptureForm', {
						label: 'Country',
						field: 'country',
						order: count,
						required: countryR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (hPhnC) {
					await axios.post('/addCaptureForm', {
						label: 'Home Phone',
						field: 'hphn',
						order: count,
						required: hPhnR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cPhnC) {
					await axios.post('/addCaptureForm', {
						label: 'Cell Phone',
						field: 'cphn',
						order: count,
						required: cPhnR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (wPhnC) {
					await axios.post('/addCaptureForm', {
						label: 'Work Phone',
						field: 'wphn',
						order: count,
						required: wPhnR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (wExtC) {
					await axios.post('/addCaptureForm', {
						label: 'Work Phone Ext.',
						field: 'wext',
						order: count,
						required: wExtR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (commC) {
					await axios.post('/addCaptureForm', {
						label: 'Comments',
						field: 'comm',
						order: count,
						required: commR,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cust1 !== '' && cust1C) {
					const fld = cust1.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust1,
						field: fld,
						order: count,
						required: cust1R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cust2 !== '' && cust2C) {
					const fld = cust2.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust2,
						field: fld,
						order: count,
						required: cust2R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cust3 !== '' && cust3C) {
					const fld = cust3.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust3,
						field: fld,
						order: count,
						required: cust3R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cust4 !== '' && cust4C) {
					const fld = cust4.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust4,
						field: fld,
						order: count,
						required: cust4R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cust5 !== '' && cust5C) {
					const fld = cust5.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust5,
						field: fld,
						order: count,
						required: cust5R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
				if (cust6 !== '' && cust6C) {
					const fld = cust6.replace(/\s+/g, '').toLowerCase();
					await axios.post('/addCaptureForm', {
						label: cust6,
						field: fld,
						order: count,
						required: cust6R,
						promoId: newPromoId,
					});
					count = count + 1;
				}
			}

			//Trivia
			if (tempName === 'Trivia Page') {
				trivia.map(async (trv) => {
					await axios.post('/addTrivia', {
						question: trv.question,
						answer: trv.answer,
						promoId: newPromoId,
					});
				});
			}
			//Player Lineup
			if (tempName === 'Player Lineup') {
				arrPlayers.map(async (plyr) => {
					await axios.post('/addGamePlayers', {
						profileId: plyr,
						promoId: newPromoId,
					});
				});
			}

			toast.success('Promo successfully added');
			setLoading(false);
			navigate('/promos');
		} catch (err) {
			toast.error(err);
		}
	};
	/********************************************************************************************************************************************/
	/*** END SUBMIT FUNCTIONS ***/
	/********************************************************************************************************************************************/

	/********************************************************************************************************************************************/
	/*** HANDLE VALUE FUNCTIONS ***/
	/********************************************************************************************************************************************/
	/*** General Values ***/
	const handleQRChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewQRImgUrl(URL);
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};

	const handleTempChange = (e) => {
		const value = e.target.value;
		const index = e.nativeEvent.target.selectedIndex;
		setTempId(value);
		setTempName(e.nativeEvent.target[index].text);
	};
	const handleVenue = (e) => {
		const value = e.target.value;
		setVenId(value);
		loadLevels();
	};
	const handleLevel = (e) => {
		const value = e.target.value;
		setLvlId(value);
	};

	/*** Settings Values ***/
	const handleSeq = (e) => {
		const value = e.target.value;
		const newNum = onlyNums(value);
		setSeq(newNum);
	};
	const handleRecurring = (e) => {
		const value = e.target.checked;
		setRecurring(value);
	};
	const handleInterval = (e) => {
		const value = e.target.value;
		const newNum = onlyNums(value);
		setInterval(newNum);
	};
	const handleShwSoc = (e) => {
		const value = e.target.checked;
		setShwSoc(value);
	};
	const handleSecurity = (e) => {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhone(value);
		setDispSecurity(formattedPhoneNumber);
		setSecurity(formattedPhoneNumber.replace(/[^\d]/g, ''));
	};
	const handlePhone = (e) => {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhone(value);
		setDispPhone(formattedPhoneNumber);
		setPhone(formattedPhoneNumber.replace(/[^\d]/g, ''));
	};

	/*** Layout Values ***/
	/*** Header Image ***/
	const handleFileChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewImgUrl(URL);
				});
			})
			.catch((err) => {
				toast.error(err);
			});
		file = null;
	};

	/*** Body Text ***/
	const handleBdyText = (e) => {
		const value = e.target.value;
		setBdyText(value);
	};

	/*** Sponsors ***/
	const handleSpn1Change = (e) => {
		const value = e.target.value;
		setSpn1Id(value);
		setSpn2Id('');
		setSpn2Url('');
		setSpn3Id('');
		setSpn3Url('');
	};
	const handleSpn2Change = (e) => {
		const value = e.target.value;
		setSpn2Id(value);
		setSpn3Id('');
		setSpn3Url('');
	};
	const handleSpn3Change = (e) => {
		const value = e.target.value;
		setSpn3Id(value);
	};

	/*** Custom Buttons ***/
	const handleBtnNum = (e) => {
		const value = e.target.value;
		setBtnNum(value);
		if (value === '0') {
			setBtn1Txt('');
			setBtn1Url('');
			setBtn2Txt('');
			setBtn2Url('');
			setBtn3Txt('');
			setBtn3Url('');
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '1') {
			setBtn2Txt('');
			setBtn2Url('');
			setBtn3Txt('');
			setBtn3Url('');
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '2') {
			setBtn3Txt('');
			setBtn3Url('');
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '3') {
			setBtn4Txt('');
			setBtn4Url('');
			setBtn5Txt('');
			setBtn5Url('');
		} else if (value === '4') {
			setBtn5Txt('');
			setBtn5Url('');
		}
	};

	/*** Capture Form ***/
	const handleFNameC = (e) => {
		const value = e.target.checked;
		setFNameC(value);
	};
	const handleFNameR = (e) => {
		const value = e.target.checked;
		setFNameR(value);
	};
	const handleLNameC = (e) => {
		const value = e.target.checked;
		setLNameC(value);
	};
	const handleLNameR = (e) => {
		const value = e.target.checked;
		setLNameR(value);
	};
	const handleEmailC = (e) => {
		const value = e.target.checked;
		setEmailC(value);
	};
	const handleEmailR = (e) => {
		const value = e.target.checked;
		setEmailR(value);
	};
	const handleAdd1C = (e) => {
		const value = e.target.checked;
		setAdd1C(value);
	};
	const handleAdd1R = (e) => {
		const value = e.target.checked;
		setAdd1R(value);
	};
	const handleAdd2C = (e) => {
		const value = e.target.checked;
		setAdd2C(value);
	};
	const handleAdd2R = (e) => {
		const value = e.target.checked;
		setAdd2R(value);
	};
	const handleCityC = (e) => {
		const value = e.target.checked;
		setCityC(value);
	};
	const handleCityR = (e) => {
		const value = e.target.checked;
		setCityR(value);
	};
	const handleStateC = (e) => {
		const value = e.target.checked;
		setStateC(value);
	};
	const handleStateR = (e) => {
		const value = e.target.checked;
		setStateR(value);
	};
	const handleZipC = (e) => {
		const value = e.target.checked;
		setZipC(value);
	};
	const handleZipR = (e) => {
		const value = e.target.checked;
		setZipR(value);
	};
	const handleCountryC = (e) => {
		const value = e.target.checked;
		setCountryC(value);
	};
	const handleCountryR = (e) => {
		const value = e.target.checked;
		setCountryR(value);
	};
	const handleHPhnC = (e) => {
		const value = e.target.checked;
		setHphnC(value);
	};
	const handleHPhnR = (e) => {
		const value = e.target.checked;
		setHphnR(value);
	};
	const handleCPhnC = (e) => {
		const value = e.target.checked;
		setCphnC(value);
	};
	const handleCPhnR = (e) => {
		const value = e.target.checked;
		setCphnR(value);
	};
	const handleWPhnC = (e) => {
		const value = e.target.checked;
		setWphnC(value);
	};
	const handleWPhnR = (e) => {
		const value = e.target.checked;
		setWphnR(value);
	};
	const handleWExtC = (e) => {
		const value = e.target.checked;
		setWextC(value);
	};
	const handleWExtR = (e) => {
		const value = e.target.checked;
		setWextR(value);
	};
	const handleCommC = (e) => {
		const value = e.target.checked;
		setCommC(value);
	};
	const handleCommR = (e) => {
		const value = e.target.checked;
		setCommR(value);
	};
	const handleCust1C = (e) => {
		const value = e.target.checked;
		setCust1C(value);
	};
	const handleCust1R = (e) => {
		const value = e.target.checked;
		setCust1R(value);
	};
	const handleCust2C = (e) => {
		const value = e.target.checked;
		setCust2C(value);
	};
	const handleCust2R = (e) => {
		const value = e.target.checked;
		setCust2R(value);
	};
	const handleCust3C = (e) => {
		const value = e.target.checked;
		setCust3C(value);
	};
	const handleCust3R = (e) => {
		const value = e.target.checked;
		setCust3R(value);
	};
	const handleCust4C = (e) => {
		const value = e.target.checked;
		setCust4C(value);
	};
	const handleCust4R = (e) => {
		const value = e.target.checked;
		setCust4R(value);
	};
	const handleCust5C = (e) => {
		const value = e.target.checked;
		setCust5C(value);
	};
	const handleCust5R = (e) => {
		const value = e.target.checked;
		setCust5R(value);
	};
	const handleCust6C = (e) => {
		const value = e.target.checked;
		setCust6C(value);
	};
	const handleCust6R = (e) => {
		const value = e.target.checked;
		setCust6R(value);
	};

	/*** Trivia ***/
	const addTrivia = (e) => {
		const trvObj = { question, answer };
		setTrivia([...trivia, trvObj]);
		setTrvCount(trvCount + 1);
		setQuestion('');
		setAnswer('');
	};

	/*** Product ***/
	const handleProdImg1 = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewProdImg1(URL);
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};
	const handleProdImg2 = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewProdImg2(URL);
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};

	/*** Player Lineup ***/
	const handleAddPlayer = (e, prfId) => {
		const value = e.target.checked;
		if (value) {
			setArrPlayers([...arrPlayers, prfId]);
		} else {
			const idx = arrPlayers.indexOf(prfId);
			setArrPlayers([...arrPlayers.slice(0, idx), ...arrPlayers.slice(idx + 1, arrPlayers.length)]);
		}
	};

	/*** Qpon ***/
	const handleQponChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewQponUrl(URL);
				});
			})
			.catch((err) => {
				toast.error(err);
			});
		file = null;
	};
	/********************************************************************************************************************************************/
	/*** END HANDLE VALUE FUNCTIONS ***/
	/********************************************************************************************************************************************/

	return (
		<AdminPageTemplate title='Add Promo'>
			<ShadowBoxMd>
				<form onSubmit={handleSubmit}>
					{temps.length >= 1 && (
						<div className='row mb-3 d-flex justify-content-center align-items-center'>
							<div className='col-12 col-md-3 pe-1 text-start text-md-end'>
								<label className='frmLabel'>Template</label>
							</div>
							<div className='col-12 col-md-6 ps-2'>
								<select className='form-select' id='template' required value={tempId} onChange={(e) => handleTempChange(e)}>
									<option key='0' value=''></option>
									{temps.map((tmp) => (
										<option key={tmp._id} value={tmp._id}>
											{tmp.name}
										</option>
									))}
								</select>
							</div>
							<div className='col-12 col-md-3 text-center'>
								<Link className='samplesLink col-3 text-center' to='/samples'>
									View Template Samples
								</Link>
							</div>
						</div>
					)}
					<div className='row mb-3 d-flex justify-content-center align-items-center'>
						<div className='col-12 col-md-3 pe-1 text-start text-md-end'>
							<label className='frmLabel'>Use Price Level</label>
						</div>
						<div className='col-12 col-md-6 ps-2'>
							<select className='form-select' id='venue' value={venId} onChange={(e) => handleVenue(e)}>
								<option key='1' value=''></option>
								{venues.map((ven) => (
									<option key={ven._id} value={ven._id}>
										{ven.name}
									</option>
								))}
							</select>
						</div>
						<div className='col-md-3 d-none d-md-block'></div>
					</div>
					{venId !== '' && (
						<div className='row mb-3 d-flex justify-content-center align-items-center'>
							<div className='col-12 col-md-3 pe-1 text-start text-md-end'>
								<label className='frmLabel'>Price Level</label>
							</div>
							<div className='col-12 col-md-6 ps-2'>
								<select className='form-select' id='level' value={lvlId} onChange={(e) => handleLevel(e)}>
									<option key='2' value=''></option>
									{levels.map((lvl) => (
										<option key={lvl._id} value={lvl._id}>
											{lvl.description}
										</option>
									))}
								</select>
							</div>
							<div className='col-md-3 d-none d-md-block'></div>
						</div>
					)}
					<div className='row d-flex justify-content-evenly'>
						{
							//*********************************************************************************************************************//
							//*********************************************************************************************************************//
							//*** SETTINGS ***//
							//*********************************************************************************************************************//
							//*********************************************************************************************************************//
						}
						<div className='col-12 col-md-6 px-4'>
							<h2 className='colHdng my-3 text-center'>Settings</h2>
							<Input name='name' id='name' label='Name' type='text' required value={name} setValue={setName} />
							<Input name='seq' id='seq' label='Sequence' type='number' max='2' required value={seq} funcCall={handleSeq} />
							<label className='frmLabel mb-1'>Image for QR Code</label>
							<ImageEdit inputId='imgQR' type='file' handler={handleQRChange} newUrl={newQRImgUrl} image={newQRImgUrl} imgDefault={defQponImg} />
							<div className='admInfo mb-1'>recommended upload size: 200 x 200</div>
							<div className='admInfo mb-3'>max file size: 2mb</div>
							<Input name='stDate' id='stDate' label='Start Date' type='datetime-local' value={stDate} setValue={setStDate} />
							<Input name='exDate' id='exDate' label='Expire Date' type='datetime-local' value={exDate} setValue={setExDate} />
							<div className='row mb-3'>
								<div className='col-auto pe-2'>
									<Checkbox checked={recurring} funcCall={handleRecurring} />
								</div>
								<div className='col ps-2'>
									<div className='textSemi'>Recurring</div>
								</div>
							</div>
							{recurring && (
								<Input
									name='interval'
									id='interval'
									label='Recur Interval'
									type='text'
									placeholder='# of minutes'
									max='3'
									value={interval}
									funcCall={handleInterval}
								/>
							)}
							<div className='row mb-3'>
								<div className='col-auto pe-2'>
									<Checkbox checked={shwSoc} funcCall={handleShwSoc} />
								</div>
								<div className='col ps-2'>
									<div className='textSemi'>Show Social Links</div>
								</div>
							</div>
							{shwSoc && (
								<>
									<Input name='fb' id='fb' label='Facebook' type='text' placeholder='https://' value={fb} setValue={setFb} />
									<Input name='tw' id='tw' label='Twitter' type='text' placeholder='https://' value={tw} setValue={setTw} />
									<Input name='ig' id='ig' label='Instagram' type='text' placeholder='https://' value={ig} setValue={setIg} />
									<Input name='tt' id='tt' label='Tik-Tok' type='text' placeholder='https://' value={tt} setValue={setTt} />
									<Input
										name='donation'
										id='donation'
										label='Donation Link'
										type='text'
										placeholder='https://'
										value={donation}
										setValue={setDonation}
									/>
									<Input name='security' id='security' label='Security #' type='tel' value={dispSecurity} funcCall={handleSecurity} />
									<Input name='email' id='email' label='Email' type='email' value={email} setValue={setEmail} />
									<Input name='subject' id='subject' label='Email Subject' type='text' value={subject} setValue={setSubject} />
									<Input name='phone' id='phone' label='Phone #' type='tel' value={dispPhone} funcCall={handlePhone} />
								</>
							)}
						</div>
						{
							//*********************************************************************************************************************//
							//*********************************************************************************************************************//
							//*** LAYOUT ***//
							//*********************************************************************************************************************//
							//*********************************************************************************************************************//
						}
						<div className='col-12 col-md-6 px-4'>
							<h2 className='colHdng my-3 text-center'>Layout</h2>
							{
								//*********************************************************************************************************************//
								//*********************************************************************************************************************//
								//*** HEADER ***//
								//*********************************************************************************************************************//
								//*********************************************************************************************************************//
							}
							{tempName !== 'Qpon Page' && tempName !== '' && (
								<>
									<HdrImgEdit
										label='Header'
										inputId='imgHdr'
										type='file'
										handler={handleFileChange}
										newUrl={newImgUrl}
										image={defHdrImg}
										imgDefault={defHdrImg}
									/>
									<div className='admInfo mb-1'>recommended upload size: 800 x 200</div>
									<div className='admInfo mb-3'>max file size: 2mb</div>
								</>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** HEADLINE ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName !== 'Qpon Page' && tempName !== '' && (
								<Input name='headline' id='headline' label='Headline' type='text' max='60' value={headline} setValue={setHeadline} />
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** BODY TEXT ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName !== '2 - Buttons' && tempName !== 'Trivia Page' && tempName !== '' && (
								<Textarea inputId='bdyText' inputLabel='Body Text' max='1500' value={bdyText} handler={handleBdyText} />
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** VIDEO ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{(tempName === 'Multi-Sponsor' ||
								tempName === 'Announcement' ||
								tempName === 'Single Sponsor' ||
								tempName === 'Product Page' ||
								tempName === 'Player Lineup' ||
								tempName === 'Profile Page') && (
								<Input name='video' id='video' label='Video' placeholder='e.g. KKJprZqU_oU' max='20' value={video} setValue={setVideo} />
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** SPONSORS ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{(tempName === 'Multi-Sponsor' ||
								tempName === 'Single Sponsor' ||
								tempName === 'Product Page' ||
								tempName === 'Player Lineup' ||
								tempName === 'Profile Page') && (
								<SponsorElement
									urlId='spn1Url'
									urlLabel='Sponsor 1'
									placeholder='https://'
									urlValue={spn1Url}
									setValue={setSpn1Url}
									selId='spn1Id'
									value={spn1Id}
									handler={handleSpn1Change}
									arr={sponsors}
								/>
							)}
							{spn1Id && tempName !== 'Single Sponsor' && (
								<SponsorElement
									urlId='spn2Url'
									urlLabel='Sponsor 2'
									placeholder='https://'
									urlValue={spn2Url}
									setValue={setSpn2Url}
									selId='spn2Id'
									value={spn2Id}
									handler={handleSpn2Change}
									arr={sponsors}
								/>
							)}
							{spn1Id && spn2Id && tempName !== 'Single Sponsor' && (
								<SponsorElement
									urlId='spn3Url'
									urlLabel='Sponsor 3'
									placeholder='https://'
									urlValue={spn3Url}
									setValue={setSpn3Url}
									selId='spn3Id'
									value={spn3Id}
									handler={handleSpn3Change}
									arr={sponsors}
								/>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** CUSTOM BUTTONS NUMBER SELECT ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName === 'Announcement' && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2, 3, 4, 5].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{(tempName === 'Multi-Sponsor' || tempName === 'Single Sponsor' || tempName === 'User Input Form') && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2, 3, 4].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{(tempName === 'Trivia Page' || tempName === 'Survey Page' || tempName === 'Profile Page') && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2, 3].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{(tempName === 'Player Lineup' || tempName === '2 - Buttons') && (
								<div className='mb-3'>
									<label className='frmLabel'># of Buttons</label>
									<select className='form-select' id='btnNum' value={btnNum} onChange={(e) => handleBtnNum(e)}>
										<option value='0'>0</option>
										{[1, 2].map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
								</div>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** CUSTOM BUTTONS ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{btnNum >= 1 && 1 <= btnNum && (
								<CustomBtnElement
									label='Button 1'
									txtId='btn1Txt'
									max='25'
									txtValue={btn1Txt}
									setTxtValue={setBtn1Txt}
									urlId='btn1Url'
									urlValue={btn1Url}
									setUrlValue={setBtn1Url}
								/>
							)}
							{btnNum >= 1 && 2 <= btnNum && (
								<CustomBtnElement
									label='Button 2'
									txtId='btn2Txt'
									max='25'
									txtValue={btn2Txt}
									setTxtValue={setBtn2Txt}
									urlId='btn2Url'
									urlValue={btn2Url}
									setUrlValue={setBtn2Url}
								/>
							)}
							{btnNum >= 1 && 3 <= btnNum && (
								<CustomBtnElement
									label='Button 3'
									txtId='btn3Txt'
									max='25'
									txtValue={btn3Txt}
									setTxtValue={setBtn3Txt}
									urlId='btn3Url'
									urlValue={btn3Url}
									setUrlValue={setBtn3Url}
								/>
							)}
							{btnNum >= 1 && 4 <= btnNum && (
								<CustomBtnElement
									label='Button 4'
									txtId='btn4Txt'
									max='25'
									txtValue={btn4Txt}
									setTxtValue={setBtn4Txt}
									urlId='btn4Url'
									urlValue={btn4Url}
									setUrlValue={setBtn4Url}
								/>
							)}
							{btnNum >= 1 && 5 <= btnNum && (
								<CustomBtnElement
									label='Button 5'
									txtId='btn5Txt'
									max='25'
									txtValue={btn5Txt}
									setTxtValue={setBtn5Txt}
									urlId='btn5Url'
									urlValue={btn5Url}
									setUrlValue={setBtn5Url}
								/>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** CAPTURE FORM (TEMPLATE 4) ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName === 'User Input Form' && (
								<CaptureForm>
									<CaptureFormElement
										inputIdC='fnamec'
										inputIdR='fnamer'
										inputLabel='First Name'
										handlerC={handleFNameC}
										handlerR={handleFNameR}
									/>
									<CaptureFormElement
										inputIdC='lnamec'
										inputIdR='lnamer'
										inputLabel='Last Name'
										handlerC={handleLNameC}
										handlerR={handleLNameR}
									/>
									<CaptureFormElement
										inputIdC='emailc'
										inputIdR='emailr'
										inputLabel='Email'
										handlerC={handleEmailC}
										handlerR={handleEmailR}
									/>
									<CaptureFormElement
										inputIdC='add1c'
										inputIdR='add1r'
										inputLabel='Address 1'
										handlerC={handleAdd1C}
										handlerR={handleAdd1R}
									/>
									<CaptureFormElement
										inputIdC='add2c'
										inputIdR='add2r'
										inputLabel='Address 2'
										handlerC={handleAdd2C}
										handlerR={handleAdd2R}
									/>
									<CaptureFormElement inputIdC='cityc' inputIdR='cityr' inputLabel='City' handlerC={handleCityC} handlerR={handleCityR} />
									<CaptureFormElement
										inputIdC='statec'
										inputIdR='stater'
										inputLabel='State'
										handlerC={handleStateC}
										handlerR={handleStateR}
									/>
									<CaptureFormElement inputIdC='zipc' inputIdR='zipr' inputLabel='Zip Code' handlerC={handleZipC} handlerR={handleZipR} />
									<CaptureFormElement
										inputIdC='countryc'
										inputIdR='countryr'
										inputLabel='Country'
										handlerC={handleCountryC}
										handlerR={handleCountryR}
									/>
									<CaptureFormElement
										inputIdC='hphnc'
										inputIdR='hphnr'
										inputLabel='Home Phone'
										handlerC={handleHPhnC}
										handlerR={handleHPhnR}
									/>
									<CaptureFormElement
										inputIdC='cphnc'
										inputIdR='cphnr'
										inputLabel='Cell Phone'
										handlerC={handleCPhnC}
										handlerR={handleCPhnR}
									/>
									<CaptureFormElement
										inputIdC='wphnc'
										inputIdR='wphnr'
										inputLabel='Work Phone'
										handlerC={handleWPhnC}
										handlerR={handleWPhnR}
									/>
									<CaptureFormElement
										inputIdC='wextc'
										inputIdR='wextr'
										inputLabel='Work Phone Ext.'
										handlerC={handleWExtC}
										handlerR={handleWExtR}
									/>
									<CaptureFormElement inputIdC='commc' inputIdR='commr' inputLabel='Comments' handlerC={handleCommC} handlerR={handleCommR} />
									<CaptureFormCustom
										inputIdTxt='cust1'
										txtValue={cust1}
										max='20'
										handlerT={setCust1}
										inputIdC='cust1c'
										inputIdR='cust1r'
										handlerC={handleCust1C}
										handlerR={handleCust1R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust2'
										txtValue={cust2}
										max='20'
										handlerT={setCust2}
										inputIdC='cust2c'
										inputIdR='cust2r'
										handlerC={handleCust2C}
										handlerR={handleCust2R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust3'
										txtValue={cust3}
										max='20'
										handlerT={setCust3}
										inputIdC='cust3c'
										inputIdR='cust3r'
										handlerC={handleCust3C}
										handlerR={handleCust3R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust4'
										txtValue={cust4}
										max='20'
										handlerT={setCust4}
										inputIdC='cust4c'
										inputIdR='cust4r'
										handlerC={handleCust4C}
										handlerR={handleCust4R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust5'
										txtValue={cust5}
										max='20'
										handlerT={setCust5}
										inputIdC='cust5c'
										inputIdR='cust5r'
										handlerC={handleCust5C}
										handlerR={handleCust5R}
									/>
									<CaptureFormCustom
										inputIdTxt='cust6'
										txtValue={cust6}
										max='20'
										handlerT={setCust6}
										inputIdC='cust6c'
										inputIdR='cust6r'
										handlerC={handleCust6C}
										handlerR={handleCust6R}
									/>
								</CaptureForm>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** TRIVIA PAGE (TEMPLATE 5) ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName === 'Trivia Page' && (
								<>
									<TriviaElement question={question} setQstValue={setQuestion} answer={answer} setAnsValue={setAnswer} />
									<div className='row mb-1 d-flex justify-content-center align-items-center'>
										<div className='col-12 text-center'>
											<div className='samplesLink' onClick={addTrivia}>
												Save Question
											</div>
										</div>
									</div>
									{trvCount === 1 && (
										<div className='row mb-3 d-flex justify-content-center align-items-center'>
											<div className='col-12 text-center'>
												<div className='admInfo'>You have added 1 question</div>
											</div>
										</div>
									)}
									{trvCount >= 2 && (
										<div className='row mb-3 d-flex justify-content-center align-items-center'>
											<div className='col-12 text-center'>
												<div className='admInfo'>You have added {trvCount} questions</div>
											</div>
										</div>
									)}
								</>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** PRODUCT PAGE (TEMPLATE 6) ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName === 'Product Page' && (
								<>
									<label className='frmLabel'>Product Image #1</label>
									<ImageEdit
										inputId='prodImg1'
										type='file'
										handler={handleProdImg1}
										newUrl={newProdImg1}
										image={newProdImg1}
										imgDefault={defQponImg}
									/>
									<label className='frmLabel'>Product Image #2</label>
									<ImageEdit
										inputId='prodImg2'
										type='file'
										handler={handleProdImg2}
										newUrl={newProdImg2}
										image={newProdImg2}
										imgDefault={defQponImg}
									/>
								</>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** Player Lineup (TEMPLATE 7) ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName === 'Player Lineup' && (
								<PlayersForm>
									{profiles.map((prf) => (
										<div className='row mb-3 d-flex align-items-center' key={prf._id}>
											<div className='col-0 col-sm-1'></div>
											<div className='col-1 text-center'>
												<input className='chkBox' type='checkbox' onChange={(e) => handleAddPlayer(e, prf._id)} />
											</div>
											<div className='col-6'>
												{prf.lname}, {prf.fname}
											</div>
											<div className='col-2 text-center'>{prf.posabbr}</div>
											<div className='col-2 text-center'>{prf.number}</div>
										</div>
									))}
								</PlayersForm>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** Profile Page (TEMPLATE 8) ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName === 'Profile Page' && (
								<div className='mb-3'>
									<label className='frmLabel'>Profile</label>
									<select className='form-select' id='profile' value={profileId} onChange={(e) => setProfileId(e.target.value)}>
										<option value=''>Select One...</option>
										{profiles.map((prf) => (
											<option key={prf._id} value={prf._id}>
												{prf.lname}, {prf.fname}
											</option>
										))}
									</select>
								</div>
							)}
							{
								//********************************************************************************************************//
								//********************************************************************************************************//
								//*** Qpon Page (TEMPLATE 9) ***//
								//********************************************************************************************************//
								//********************************************************************************************************//
							}
							{tempName === 'Qpon Page' && (
								<>
									<HdrImgEdit
										label='Qpon Image'
										inputId='imgQpon'
										type='file'
										handler={handleQponChange}
										newUrl={newQponUrl}
										image={defQponImg}
										imgDefault={defQponImg}
									/>
									<div className='admInfo mb-1'>recommended upload size: 800 x 1300</div>
									<div className='admInfo mb-3'>max file size: 2mb</div>
								</>
							)}
						</div>
					</div>
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!tempId}>
							Save Promo
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxMd>
		</AdminPageTemplate>
	);
}
