import React, { useEffect, useState } from 'react';
import './Qsite.css';

export default function TwoButton({ btns }) {
	const [btn1Text, setBtn1Text] = useState('');
	const [btn1Url, setBtn1Url] = useState('');
	const [btn2Text, setBtn2Text] = useState('');
	const [btn2Url, setBtn2Url] = useState('');

	useEffect(() => {
		if (btn1Text === '') {
			let count = 1;
			btns.forEach((btn) => {
				if (count === 1) {
					setBtn1Text(btn.text);
					setBtn1Url(btn.url);
					count = count + 1;
				} else {
					setBtn2Text(btn.text);
					setBtn2Url(btn.url);
				}
			});
		}
	}, [btn1Text, btns]);

	return (
		<div className='row mb-3'>
			<div className='col-6 h-100'>
				<a className='fw-bold fs-5 text-decoration-none' href={btn1Url} target='_blank' rel='noreferrer'>
					<div className='twobtnDiv shadow d-flex justify-content-center'>{btn1Text}</div>
				</a>
			</div>
			<div className='col-6 h-100'>
				<a className='fw-bold fs-5 text-decoration-none' href={btn2Url} target='_blank' rel='noreferrer'>
					<div className='twobtnDiv shadow d-flex justify-content-center'>{btn2Text}</div>
				</a>
			</div>
		</div>
	);
}
