import React, { useCallback, useContext, useEffect, useState } from 'react';
import './Customers.css';
import { AuthContext } from '../../../context/auth';
import { Link, useNavigate } from 'react-router-dom';
import { storage } from '../../../firebase';
import { deleteObject, ref } from 'firebase/storage';
import { saveInLocalStorage } from '../../../helpers/auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import AddIcon from '../../../components/icons/AddIcon';
import DelIcon from '../../../components/icons/DelIcon';
import ContainerSm from '../../../components/containers/ContainerSm';
import ListRow from '../../../components/listrow/ListRow';
import Pagination from '../../../components/pagination/Pagination';
import CheckBoxActive from '../../../components/forms/CheckBoxActive';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import defNoImg from '../../../assets/images/defNoImage.png';

export default function Customers() {
	const navigate = useNavigate();
	const { auth, setAuth } = useContext(AuthContext);
	const [customers, setCustomers] = useState([]);
	const [delItem, setDelItem] = useState(false);
	const [delDoc, setDelDoc] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(10);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = customers.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(customers.length / recordsPerPage);
	const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

	const loadCustomers = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomers/${auth.user.superuser}`);
			setCustomers(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	useEffect(() => {
		if (customers.length === 0) {
			loadCustomers();
		}
	}, [customers, loadCustomers]);

	const handleConfirmationBox = (customer) => {
		if (!delItem) {
			document.querySelector('.confirm-bg').style.display = 'flex';
			document.querySelector('.confirmation-container').style.display = 'flex';
			setDelItem(true);
			setDelDoc(customer);
		} else {
			document.querySelector('.confirm-bg').style.display = 'none';
			document.querySelector('.confirmation-container').style.display = 'none';
			setDelItem(false);
		}
	};

	const handleEdit = (e, id) => {
		e.preventDefault();
		navigate(`edit/${id}`);
	};

	const handleDelete = async (e) => {
		try {
			//delete users
			delUsers(delDoc._id);
			//delete venues and price levels
			delVenues(delDoc._id);
			//delete sponsors
			delSponsors(delDoc._id);
			//delete profiles
			delProfiles(delDoc._id);

			//delete promos from database

			//delete customer from database
			await axios.delete(`/delCustomer/${delDoc._id}/${auth.user.superuser}`).then((res) => {
				const ok = res.data.ok;
				if (ok) {
					//update the list of customers
					loadCustomers();
					setDelItem(true);
					handleConfirmationBox();
					toast.success('Customer deleted successfully');
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleActive = async (e, value, id) => {
		e.preventDefault();
		try {
			//update Customer and Users
			await axios.put(`/updCustomer/${id}/${auth.user.superuser}`, { active: value }).then(async (res) => {
				const { data } = await axios.get(`/getCustomerUsers/${id}`);
				data.map(async (user) => {
					await axios.put(`/updUser/${user._id}`, { active: value });
				});
				loadCustomers();
				if (value) {
					toast.success('Customer and users are now active');
				} else {
					toast.success('Customer and users are now inactive');
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	/************************************************************************************************************************************/
	/*** ALL DELETE FUNCTIONS ***/
	/************************************************************************************************************************************/
	// users
	const delUsers = async (id) => {
		try {
			let { data } = await axios.get(`/getCustomerUsers/${id}`);
			data.map(async (user) => {
				await axios.delete(`/delUser/${user._id}`);
			});
		} catch (err) {
			toast.error('Users did not delete correctly');
		}
	};
	// venues
	const delVenues = async (id) => {
		try {
			//get all venues and map through and delete after deleting price levels
			const { data } = await axios.get(`/getCustomerVenues/${id}`);
			data.map(async (ven) => {
				//delete all price levels
				delPriceLevels(ven._id);
				//delete venue
				await axios.delete(`/delVenue/${ven._id}`);
			});
		} catch (err) {
			console.log(err);
		}
	};
	const delPriceLevels = async (venId) => {
		try {
			let { data } = await axios.get(`/getVenueLevels/${venId}`);
			data.map(async (lvl) => {
				await axios.delete(`/delLevel/${lvl._id}`);
			});
		} catch (err) {
			console.log(err);
		}
	};
	// sponsors
	const delSponsors = async (id) => {
		try {
			const { data } = await axios.get(`/getCustomerSponsors/${id}`);
			data.map(async (spn) => {
				if (spn.logo !== defNoImg) {
					//delete image from storage
					const imgRef = ref(storage, spn.logo);
					deleteObject(imgRef);
				}
				//delete sponsor from database
				await axios.delete(`/delSponsor/${spn._id}`);
			});
		} catch (err) {
			console.log(err);
		}
	};
	// profiles
	const delProfiles = async (id) => {
		try {
			const { data } = await axios.get(`/getCustomerProfiles/${id}`);
			data.map(async (prf) => {
				if (prf.photo !== defNoImg) {
					//delete photo from storage
					const imgRef = ref(storage, prf.photo);
					deleteObject(imgRef);
				}
				//delete profile from database
				await axios.delete(`/delProfile/${prf._id}`);
			});
		} catch (err) {
			console.log(err);
		}
	};
	/************************************************************************************************************************************/
	/*** END ALL DELETE FUNCTIONS ***/
	/************************************************************************************************************************************/

	const handleCustChange = async (e) => {
		const value = e.target.value;
		const index = e.nativeEvent.target.selectedIndex;
		const custName = e.nativeEvent.target[index].text;
		setAuth({ token: auth.token, user: auth.user, masterCust: { id: value, name: custName } });
		saveInLocalStorage('auth', { token: auth.token, user: auth.user, masterCust: { id: value, name: custName } });
	};

	const nextPage = () => {
		if (currentPage !== nPages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<AdminPageTemplate title='Customers'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link to='add'>
					<AddIcon alt='Add Customer' />
				</Link>
			</div>
			<ContainerSm>
				{customers.length >= 1 && (
					<form className='mb-4'>
						<select className='form-select' value={auth.masterCust.id} onChange={(e) => handleCustChange(e)}>
							{customers.map((cust) => {
								return (
									<option key={cust._id} value={cust._id}>
										{cust.name}
									</option>
								);
							})}
						</select>
					</form>
				)}
				{currentRecords.map((cust) => (
					<ListRow key={cust._id}>
						<div className='listLinkText col-12 col-md-6' onClick={(e) => handleEdit(e, cust._id)}>
							{cust.name}
						</div>
						<div className='listInfoText col-6 col-md-3'>{cust.subdomain}</div>
						<div className='col-6 col-md-3 d-flex flex-row justify-content-end'>
							<div className='col-6 d-flex justify-content-end'>
								<CheckBoxActive active={cust.active} funcCall={handleActive} value={!cust.active} id={cust._id} />
							</div>
							<div className='col-6 d-flex justify-content-end'>
								<DelIcon alt='Delete' funcCall={() => handleConfirmationBox(cust)} />
							</div>
						</div>
					</ListRow>
				))}
			</ContainerSm>
			{nPages >= 2 && <Pagination prev={prevPage} pgNums={pageNumbers} curPage={currentPage} handler={setCurrentPage} next={nextPage} />}
			{/* DELETE CONFIRMATION BOX */}
			<div className='confirmation-container'>
				<div className='confirmation-text'>
					Are you sure?
					<br />
					This cannot be undone!
				</div>
				<div className='button-container'>
					<button className='cancel-button' onClick={() => handleConfirmationBox()}>
						Cancel
					</button>
					<button className='confirmation-button' onClick={() => handleDelete()}>
						Delete
					</button>
				</div>
			</div>
			<div className='confirm-bg' onClick={() => handleConfirmationBox()}></div>
			{/* END DELETE CONFIRMATION BOX */}
		</AdminPageTemplate>
	);
}
