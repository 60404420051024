import React from 'react';
import './AdminPageTemplate.css';

export default function AdminPageTemplate({ title, children }) {
	return (
		<div className='pgTop'>
			<h2 className='textSemi pgHdng text-center'>{title}</h2>
			{children}
		</div>
	);
}
