import React from 'react';
import './Qsite.css';

export default function MultiSponsor({ prmSpns, allSpns, btns }) {
	return (
		<div className='row mb-2'>
			<div className='col-6 h-100 d-flex flex-column justify-content-end'>
				{prmSpns.map((prmSpn) => (
					<div className='spnDiv shadow' key={prmSpn._id}>
						<a href={prmSpn.url} target='_blank' rel='noreferrer'>
							{allSpns.map((spn) => (
								<div key={spn._id}>{spn._id === prmSpn.sponsorId && <img className='spnImg' src={spn.logo} alt={spn.name} />}</div>
							))}
						</a>
					</div>
				))}
			</div>
			<div className='col-6 h-100'>
				{btns.map((btn) => (
					<a className='fw-bold fs-5 text-decoration-none' key={btn._id} href={btn.url} target='_blank' rel='noreferrer'>
						<div className='btnDiv shadow'>{btn.text}</div>
					</a>
				))}
			</div>
		</div>
	);
}
