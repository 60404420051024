import React from 'react';
import './Forms.css';

export default function CaptureFormChkBox(props) {
	return (
		<>
			{!props.cChkd && !props.rChkd && (
				<input className='chkBox' type='checkbox' id={props.inputId} value='1' onChange={(e) => props.handler(e)} />
			)}
			{props.cChkd && (
				<input className='chkBox' type='checkbox' id={props.inputId} checked={true} value='1' onChange={(e) => props.handler(e)} />
			)}
			{props.rChkd && (
				<input
					className='chkBox'
					type='checkbox'
					id={props.inputId}
					checked={props.rChkd}
					value='1'
					onChange={(e) => props.handler(e)}
				/>
			)}
		</>
	);
}
