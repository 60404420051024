import React from 'react';
import './Footer.css';

const curYear = new Date().getFullYear();

export default function Footer({ hide }) {
	return (
		<>
			{!hide && (
				<footer className='footer mt-auto py-3 bg-transparent fixed-bottom' style={{ height: '40px' }}>
					{curYear === 2023 && <span className='ftrText text-muted'>&copy;{curYear} Mobile QR Media, LLC</span>}
					{curYear >= 2024 && <span className='ftrText text-muted'>&copy;2023-{curYear} Mobile QR Media, LLC</span>}
				</footer>
			)}
		</>
	);
}
