import React, { createContext, useEffect, useState } from 'react';
import { getFromLocalStorage } from '../helpers/auth';

const GlobalStateContext = createContext();

const GlobalStateProvider = ({ children }) => {
	const [state, setState] = useState(null);

	useEffect(() => {
		setState(getFromLocalStorage('userData'));
	}, []);

	return <GlobalStateContext.Provider value={{ state, setState }}>{children}</GlobalStateContext.Provider>;
};

export { GlobalStateContext, GlobalStateProvider };
