import React from 'react';
import './Account.css';
import { Link } from 'react-router-dom';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import icoEmail from '../../../assets/images/accEmail.png';
import icoPword from '../../../assets/images/accPwrd.png';

export default function Account() {
	return (
		<AdminPageTemplate title='My Account'>
			<div className='row d-flex justify-content-center'>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='email'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoEmail} alt='Change Email' />
							<br />
							Change Email
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='password'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoPword} alt='Change Password' />
							<br />
							Change Password
						</div>
					</Link>
				</div>
			</div>
		</AdminPageTemplate>
	);
}
