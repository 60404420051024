import React from 'react';

export default function PageNotFound() {
	return (
		<div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '100vw', height: '75vh' }}>
			<div>
				<h1 className='display-1' style={{ color: '#9b00ef', fontWeight: 'bold' }}>
					404
				</h1>
			</div>
			<div>
				<h3>Page Not Found</h3>
			</div>
		</div>
	);
}
