import React from 'react';
import './Forms.css';

export default function Input({ name, label, type, placeholder, id, value, required, readonly, max, setValue, funcCall }) {
	return (
		<>
			{setValue && label && (
				<div className='mb-3'>
					<label className='frmLabel'>{label}</label>
					<input
						className='form-control'
						type={type}
						placeholder={placeholder}
						name={name}
						id={id}
						required={required}
						readOnly={readonly}
						maxLength={max}
						value={value}
						onChange={(e) => setValue(e.target.value)}
					/>
				</div>
			)}
			{funcCall && label && (
				<div className='mb-3'>
					<label className='frmLabel'>{label}</label>
					<input
						className='form-control'
						type={type}
						placeholder={placeholder}
						name={name}
						id={id}
						required={required}
						readOnly={readonly}
						maxLength={max}
						value={value}
						onChange={(e) => funcCall(e)}
					/>
				</div>
			)}
			{!setValue && !funcCall && (
				<div className='mb-3'>
					<label className='frmLabel'>{label}</label>
					<input className='form-control' type={type} placeholder={placeholder} name={name} id={id} readOnly={readonly} maxLength={max} value={value} />
				</div>
			)}
			{!label && funcCall && (
				<input className='form-control' type={type} placeholder={placeholder} name={name} id={id} readOnly={readonly} maxLength={max} value={value} onChange={(e) => funcCall(e)} />
			)}
			{!label && setValue && (
				<input
					className='form-control'
					type={type}
					placeholder={placeholder}
					name={name}
					id={id}
					readOnly={readonly}
					maxLength={max}
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
			)}
		</>
	);
}
