import React, { useEffect, useState } from 'react';
import './Promos.css';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Header from '../../../components/qsite/Header';
import Headline from '../../../components/qsite/Headline';
import MultiSponsor from '../../../components/qsite/MultiSponsor';
import BodyText from '../../../components/qsite/BodyText';
import Video from '../../../components/qsite/Video';
import Social from '../../../components/qsite/Social';
import Buttons from '../../../components/qsite/Buttons';
import SingleSpon from '../../../components/qsite/SingleSpon';
import Capture from '../../../components/qsite/Capture';
import Trivia from '../../../components/qsite/Trivia';
import Product from '../../../components/qsite/Product';
import PlayerLineup from '../../../components/qsite/PlayerLineup';
import Profile from '../../../components/qsite/Profile';
import TwoButton from '../../../components/qsite/TwoButton';

export default function ViewPromo() {
	const { id } = useParams();
	const [promo, setPromo] = useState({});
	const [prmId, setPrmId] = useState('');
	const [tempName, setTempName] = useState('');
	const [custId, setCustId] = useState('');
	const [prmSpns, setPrmSpns] = useState([]);
	const [allSpns, setAllSpns] = useState([]);
	const [btns, setBtns] = useState([]);
	const [social, setSocial] = useState({});
	const [capture, setCapture] = useState([]);
	const [trivia, setTrivia] = useState([]);
	const [players, setPlayers] = useState([]);

	useEffect(() => {
		if (Object.keys(promo).length === 0 || Object.keys(promo).length === undefined) {
			const initPromo = async () => {
				const { data } = await axios.get(`/getPromo/${id}`);
				setPromo(data);
				setPrmId(data._id);
				setTempName(data.tempname);
				setCustId(data.customerId);
			};
			initPromo();
		}
	}, [promo, id, setPromo]);
	// Get Promo Sponsors
	useEffect(() => {
		if (
			prmSpns.length === 0 &&
			prmId !== '' &&
			(tempName === 'Multi-Sponsor' || tempName === 'Single Sponsor' || tempName === 'Product Page' || tempName === 'Player Lineup' || tempName === 'Profile Page')
		) {
			const initPromoSponsors = async () => {
				const { data } = await axios.get(`/getPrmSpns4Qsite/${prmId}`);
				setPrmSpns(data);
			};
			initPromoSponsors();
		}
	}, [prmSpns, prmId, tempName]);
	// Get All Sponsors
	useEffect(() => {
		if (
			allSpns.length === 0 &&
			custId !== '' &&
			(tempName === 'Multi-Sponsor' || tempName === 'Single Sponsor' || tempName === 'Product Page' || tempName === 'Player Lineup' || tempName === 'Profile Page')
		) {
			const initAllSponsors = async () => {
				const { data } = await axios.get(`/getAllSpns4Qsite/${custId}`);
				setAllSpns(data);
			};
			initAllSponsors();
		}
	}, [allSpns, custId, tempName]);
	// Get Custom Buttons
	useEffect(() => {
		if (btns.length === 0 && prmId !== '' && tempName !== 'Qpon Page') {
			const initBtns = async () => {
				const { data } = await axios.get(`/getBtns4Qsite/${prmId}`);
				setBtns(data);
			};
			initBtns();
		}
	}, [btns, prmId, tempName]);
	// Get Social Links
	useEffect(() => {
		if (promo.shwsocial && prmId !== '') {
			const initSoc = async () => {
				const { data } = await axios.get(`/getSocial4Qsite/${prmId}`);
				setSocial(data);
			};
			initSoc();
		}
	}, [promo, prmId]);
	// Get Capture Form
	useEffect(() => {
		if (tempName === 'User Input Form' && prmId !== '') {
			const initUIF = async () => {
				const { data } = await axios.get(`/getCapture4Qsite/${prmId}`);
				setCapture(data);
			};
			initUIF();
		}
	}, [tempName, prmId]);
	// Get Trivia
	useEffect(() => {
		if (tempName === 'Trivia Page' && prmId !== '') {
			const initTrivia = async () => {
				const { data } = await axios.get(`/getTrivia4Qsite/${prmId}`);
				setTrivia(data);
			};
			initTrivia();
		}
	}, [tempName, prmId]);
	// Get Game Players
	useEffect(() => {
		if (tempName === 'Player Lineup' && prmId !== '') {
			const initPlayers = async () => {
				const { data } = await axios.get(`/getPlayers4Qsite/${prmId}`);
				setPlayers(data);
			};
			initPlayers();
		}
	}, [tempName, prmId]);

	return (
		<AdminPageTemplate title='Preview'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link className='backText' to='/promos'>
					&laquo; Back to Promos
				</Link>
			</div>
			<div className='vwContainer shadow-lg'>
				<>
					{tempName === 'Multi-Sponsor' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							<MultiSponsor prmSpns={prmSpns} allSpns={allSpns} btns={btns} />
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							{promo.video !== '' && <Video video={promo.video} />}
						</>
					)}
					{tempName === 'Announcement' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							{promo.video !== '' && <Video video={promo.video} />}
							<Buttons btns={btns} />
						</>
					)}
					{tempName === 'Single Sponsor' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							<SingleSpon prmSpns={prmSpns} allSpns={allSpns} />
							{promo.headline !== '' && <Headline text={promo.headline} />}
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							{promo.video !== '' && <Video video={promo.video} />}
							<Buttons btns={btns} />
						</>
					)}
					{tempName === 'User Input Form' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							<Capture fields={capture} prmId={prmId} />
							<Buttons btns={btns} />
						</>
					)}
					{tempName === 'Trivia Page' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							<Trivia arrTrivia={trivia} />
							<Buttons btns={btns} />
						</>
					)}
					{tempName === 'Product Page' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							<Product prmSpns={prmSpns} allSpns={allSpns} prodPic1={promo.photo1} prodPic2={promo.photo2} />
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							{promo.video !== '' && <Video video={promo.video} />}
						</>
					)}
					{tempName === 'Player Lineup' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							<PlayerLineup prmSpns={prmSpns} allSpns={allSpns} players={players} />
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							{promo.video !== '' && <Video video={promo.video} />}
							<Buttons btns={btns} />
						</>
					)}
					{tempName === 'Profile Page' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							<Profile prmSpns={prmSpns} allSpns={allSpns} btns={btns} prfId={promo.profileId} />
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
							{promo.video !== '' && <Video video={promo.video} />}
						</>
					)}
					{tempName === 'Qpon Page' && (
						<>
							<img className='qpnImg mb-4' src={promo.qpon} alt={promo.name} />
							{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
						</>
					)}
					{tempName === '2 - Buttons' && (
						<>
							{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
							{promo.headline !== '' && <Headline text={promo.headline} />}
							<TwoButton btns={btns} />
						</>
					)}
					{promo.shwsocial && <Social soc={social} />}
					<div style={{ height: '50px' }}></div>
				</>
			</div>
		</AdminPageTemplate>
	);
}
