import { useCallback, useContext, useEffect, useState } from 'react';
import './Customers.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';

export default function EditCustomer() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { auth } = useContext(AuthContext);
	const [customer, setCustomer] = useState({});
	const [types, setTypes] = useState([]);
	const [sports, setSports] = useState([]);
	const [teams, setTeams] = useState([]);
	const [name, setName] = useState('');
	const [subdomain, setSubdomain] = useState('');
	const [analytics, setAnalytics] = useState('');
	const [typename, setTypename] = useState('');
	const [typeId, setTypeId] = useState('');
	const [sportId, setSportId] = useState('');
	const [teamId, setTeamId] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadCustomer = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getCustomer/${id}/${auth.user.superuser}`);
			setCustomer(data);
		} catch (err) {
			console.log(err);
		}
	}, [id, auth]);

	const loadTypes = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getTypes/${auth.user.superuser}`);
			setTypes(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	const loadSports = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSports/${auth.user.superuser}`);
			setSports(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	const loadTeams = useCallback(
		async (value) => {
			if (value !== '' && value !== undefined) {
				try {
					const { data } = await axios.get(`/getTeamsBySport/${value}/${auth.user.superuser}`);
					setTeams(data);
				} catch (err) {
					console.log(err);
				}
			} else {
				try {
					const { data } = await axios.get(`/getTeamsBySport/${sportId}/${auth.user.superuser}`);
					setTeams(data);
				} catch (err) {
					console.log(err);
				}
			}
		},
		[sportId, auth]
	);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/customers');
		}
	});

	useEffect(() => {
		if (customer.length === 0 || customer.length === undefined) {
			loadCustomer();
		}
	}, [customer, loadCustomer]);

	useEffect(() => {
		if (customer.name !== undefined && name === '') {
			setName(customer.name);
			setSubdomain(customer.subdomain);
			setAnalytics(customer.analytics);
			setTypename(customer.typename);
			setTypeId(customer.typeId);
			setSportId(customer.sportId);
			setTeamId(customer.teamId);
		}
	}, [customer, name]);

	useEffect(() => {
		if (types.length === 0) {
			loadTypes();
		}
	}, [types, loadTypes]);

	useEffect(() => {
		if (sports.length === 0) {
			loadSports();
		}
	}, [sports, loadSports]);

	useEffect(() => {
		if (sportId !== '' && teams.length === 0) {
			loadTeams();
		}
	}, [sportId, teams, loadTeams]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			if (name.trim() === '') {
				const input = document.getElementById('name');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Name is Required');
				return;
			}
			if (typeId.trim() === '') {
				const input = document.getElementById('type');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Type is Required');
				return;
			}

			let dbSportId = '';
			let dbTeamId = '';
			if (sportId === '') {
				dbSportId = '000000000000000000000000';
			} else {
				dbSportId = sportId;
			}
			if (teamId === '') {
				dbTeamId = '000000000000000000000000';
			} else {
				dbTeamId = teamId;
			}

			const { data } = await axios.put(`/updCustomer/${id}/${auth.user.superuser}`, {
				name,
				subdomain,
				analytics,
				typename,
				typeId,
				sportId: dbSportId,
				teamId: dbTeamId,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Customer successfully updated');
				setLoading(false);
				navigate('/customers');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleName = (e) => {
		const value = e.target.value;
		setName(value);
		let sub = value.replace(/\s+/g, '').toLowerCase();
		setSubdomain(sub);
	};

	const handleType = (e) => {
		const value = e.target.value;
		setTypeId(value);
		const index = e.nativeEvent.target.selectedIndex;
		setTypename(e.nativeEvent.target[index].text);
	};

	const handleSport = (e) => {
		const value = e.target.value;
		setSportId(value);
		loadTeams(value);
	};

	const handleTeam = (e) => {
		const value = e.target.value;
		setTeamId(value);
	};

	return (
		<AdminPageTemplate title='Edit Customer'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Customer Name' type='text' value={name} funcCall={handleName} />
					<Input name='subdomain' id='subdomain' label='Subdomain' type='text' readonly={true} value={subdomain} />
					<Input name='analytics' id='analytics' label='Analytics' type='text' value={analytics} setValue={setAnalytics} />
					<div className='mb-3'>
						<label className='frmLabel'>Type</label>
						<select className='form-select' id='type' value={typeId} onChange={(e) => handleType(e)}>
							<option value=''>Select One...</option>
							{types.map((type) => (
								<option key={type._id} value={type._id}>
									{type.name}
								</option>
							))}
						</select>
					</div>
					{typename === 'Sports' && (
						<div className='mb-3'>
							<label className='frmLabel'>Sport</label>
							<select className='form-select' id='type' value={sportId} onChange={(e) => handleSport(e)}>
								<option value=''>Select One...</option>
								{sports.map((sport) => (
									<option key={sport._id} value={sport._id}>
										{sport.sport}
									</option>
								))}
							</select>
						</div>
					)}
					{sportId !== '' && (
						<div className='mb-3'>
							<label className='frmLabel'>Team</label>
							<select className='form-select' id='type' value={teamId} onChange={(e) => handleTeam(e)}>
								<option value=''>Select One...</option>
								{teams.map((team) => (
									<option key={team._id} value={team._id}>
										{team.city} {team.mascot}
									</option>
								))}
							</select>
						</div>
					)}
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name || !typeId}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
