import React, { useEffect, useState } from 'react';
import './Promos.css';
import { Link, useParams } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';

export default function ViewQr() {
	const { id } = useParams();
	const [prmInfo, setPrmInfo] = useState({});
	const [noPromo, setnoPromo] = useState(false);

	useEffect(() => {
		if (Object.keys(prmInfo).length === 0 && id !== '' && !noPromo) {
			const initPromo = async () => {
				try {
					const { data } = await axios.get(`/getPromo/${id}`);
					if (data.length === 0) {
						setnoPromo(true);
					} else {
						setPrmInfo(data);
						setnoPromo(false);
					}
				} catch (err) {
					toast.error(err);
				}
			};
			initPromo();
		}
	}, [prmInfo, id, noPromo, setnoPromo, setPrmInfo]);

	return (
		<AdminPageTemplate title={`QR Code for ${prmInfo.name}`}>
			<div className='mb-3 d-flex justify-content-center'>
				<Link className='backText' to='/promos'>
					&laquo; Back to Promos
				</Link>
			</div>
			<div className='d-flex justify-content-center'>
				<QRCode value={prmInfo.qsite} size='700' ecLevel='H' bgColor='transparent' logoImage={prmInfo.qrimg} logoWidth='150' logoheight='150' removeQrCodeBehindLogo='true' />
			</div>
		</AdminPageTemplate>
	);
}

//Put this back under removeQrCodeBehindLogo in QRCode if wanted
/*
					qrStyle='dots'
					eyeRadius={[
						{
							// top/left eye
							outer: [100, 100, 0, 100],
							inner: [100, 100, 0, 100],
						},
						{
							// top/right eye
							outer: [100, 100, 100, 0],
							inner: [100, 100, 100, 0],
						},
						{
							// bottom/left
							outer: [100, 0, 100, 100],
							inner: [100, 0, 100, 100],
						},
					]}
*/
