import React, { useCallback, useContext, useEffect, useState } from 'react';
import './Teams.css';
import { AuthContext } from '../../../context/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { storage } from '../../../firebase';
import { deleteObject, ref } from 'firebase/storage';
import axios from 'axios';
import AddIcon from '../../../components/icons/AddIcon';
import DelIcon from '../../../components/icons/DelIcon';
import ContainerSm from '../../../components/containers/ContainerSm';
import ListRow from '../../../components/listrow/ListRow';
import Pagination from '../../../components/pagination/Pagination';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';

export default function Teams() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [teams, setTeams] = useState([]);
	const [sports, setSports] = useState([]);
	const [sportId, setSportId] = useState('');
	const [delItem, setDelItem] = useState(false);
	const [delDoc, setDelDoc] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(10);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = teams.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(teams.length / recordsPerPage);
	const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

	const loadTeams = useCallback(async () => {
		if (sportId === '' || sportId === undefined) {
			try {
				const { data } = await axios.get(`/getTeams/${auth.user.superuser}`);
				setTeams(data);
			} catch (err) {
				console.log(err);
			}
		}
		if (sportId !== '' && sportId !== undefined) {
			try {
				const { data } = await axios.get(`/getTeamsBySport/${sportId}/${auth.user.superuser}`);
				setTeams(data);
			} catch (err) {
				console.log(err);
			}
		}
	}, [sportId, auth]);

	const loadSports = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSports/${auth.user.superuser}`);
			setSports(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	useEffect(() => {
		if (teams.length === 0) {
			loadTeams();
		}
	}, [teams, loadTeams]);

	useEffect(() => {
		if (sports.length === 0) {
			loadSports();
		}
	}, [sports, loadSports]);

	const handleConfirmationBox = (team) => {
		if (!delItem) {
			document.querySelector('.confirm-bg').style.display = 'flex';
			document.querySelector('.confirmation-container').style.display = 'flex';
			setDelItem(true);
			setDelDoc(team);
		} else {
			document.querySelector('.confirm-bg').style.display = 'none';
			document.querySelector('.confirmation-container').style.display = 'none';
			setDelItem(false);
		}
	};

	const handleEdit = (e, id) => {
		e.preventDefault();
		navigate(`edit/${id}`);
	};

	const handleDelete = async (e) => {
		try {
			//delete logo from storage
			if (delDoc.logo !== '' && delDoc !== undefined) {
				const imgRef = ref(storage, delDoc.logo);
				deleteObject(imgRef);
			}
			//delete team from database
			await axios.delete(`/delTeam/${delDoc._id}/${auth.user.superuser}`).then((res) => {
				const ok = res.data.ok;
				if (ok) {
					//update the list of teams
					loadTeams();
					setDelItem(true);
					handleConfirmationBox();
					toast.success('Team deleted successfully');
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleSport = (e) => {
		const value = e.target.value;
		setSportId(value);
		setCurrentPage(1);
		setTeams([]);
	};

	const nextPage = () => {
		if (currentPage !== nPages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const prevPage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<AdminPageTemplate title='Teams'>
			<div className='mb-4 d-flex justify-content-center'>
				<Link to='add'>
					<AddIcon alt='Add Team' />
				</Link>
			</div>
			<ContainerSm>
				<div className='mb-4'>
					<label className='frmLabel'>Show by Sport</label>
					<select className='form-select' id='type' value={sportId} onChange={(e) => handleSport(e)}>
						<option value=''></option>
						{sports.map((sport) => (
							<option key={sport._id} value={sport._id}>
								{sport.sport}
							</option>
						))}
					</select>
				</div>
				{currentRecords.map((team) => (
					<ListRow key={team._id}>
						<div className='listLinkText col-12 col-md-6' onClick={(e) => handleEdit(e, team._id)}>
							{team.city} {team.mascot}
						</div>
						<div className='listInfoText col-6 col-md-3'>{team.league}</div>
						<div className='col-6 col-md-3 d-flex justify-content-end'>
							<DelIcon alt='Delete' funcCall={() => handleConfirmationBox(team)} />
						</div>
					</ListRow>
				))}
			</ContainerSm>
			{nPages >= 2 && <Pagination prev={prevPage} pgNums={pageNumbers} curPage={currentPage} handler={setCurrentPage} next={nextPage} />}
			{/* DELETE CONFIRMATION BOX */}
			<div className='confirmation-container'>
				<div className='confirmation-text'>
					Are you sure?
					<br />
					This cannot be undone!
				</div>
				<div className='button-container'>
					<button className='cancel-button' onClick={() => handleConfirmationBox()}>
						Cancel
					</button>
					<button className='confirmation-button' onClick={() => handleDelete()}>
						Delete
					</button>
				</div>
			</div>
			<div className='confirm-bg' onClick={() => handleConfirmationBox()}></div>
			{/* END DELETE CONFIRMATION BOX */}
		</AdminPageTemplate>
	);
}
