import { useCallback, useEffect, useState } from 'react';
import './Users.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';

const formatPhoneNumber = (value) => {
	if (!value) return value;
	let phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength > 10) {
		phoneNumber = phoneNumber.substring(0, 10);
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export default function EditUser() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [user, setUser] = useState({});
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [cPhn, setCphn] = useState('');
	const [wPhn, setWphn] = useState('');
	const [wExt, setWext] = useState('');
	const [title, setTitle] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadUser = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getUser/${id}`);
			setUser(data);
		} catch (err) {
			console.log(err);
		}
	}, [id]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/users');
		}
	});

	useEffect(() => {
		if (user.length === 0 || user.length === undefined) {
			loadUser();
		}
	}, [user, loadUser]);

	useEffect(() => {
		if (user.fname !== undefined && fname === '') {
			setFname(user.fname);
			setLname(user.lname);
			setEmail(user.email);
			setCphn(user.cphn);
			setWphn(user.wphn);
			setWext(user.wext);
			setTitle(user.title);
		}
	}, [user, fname]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);

			if (fname.trim() === '') {
				const input = document.getElementById('fname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('First Name is Required');
				return;
			}
			if (lname.trim() === '') {
				const input = document.getElementById('lname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Last Name is Required');
				return;
			}
			if (email.trim() === '') {
				const input = document.getElementById('email');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Email is Required');
				return;
			}

			const { data } = await axios.put(`/updUser/${id}`, {
				fname,
				lname,
				email,
				cphn: cPhn,
				wphn: wPhn,
				wext: wExt,
				title,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('User successfully updated');
				setLoading(false);
				navigate('/users');
			}
		} catch (err) {
			console.log(err);
		}
	};

	function handleCPhone(e) {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setCphn(formattedPhoneNumber);
	}

	function handleWPhone(e) {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setWphn(formattedPhoneNumber);
	}

	return (
		<AdminPageTemplate title='Edit User'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='fname' id='fname' label='First Name' type='text' value={fname} setValue={setFname} />
					<Input name='lname' id='lname' label='Last Name' type='text' value={lname} setValue={setLname} />
					<Input name='email' id='email' label='Email' type='email' value={email} setValue={setEmail} />
					<Input name='title' id='title' label='Title' type='text' value={title} setValue={setTitle} />
					<Input name='cPhn' id='cPhn' label='Cell Phone' type='tel' value={cPhn} funcCall={handleCPhone} />
					<Input name='wPhn' id='wPhn' label='Work Phone' type='tel' value={wPhn} funcCall={handleWPhone} />
					<Input name='wExt' id='wExt' label='Work Extension' type='tel' value={wExt} setValue={setWext} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!fname || !lname || !email}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
