import React, { useContext, useEffect } from 'react';
import './Nav.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import { getFromLocalStorage, removeFromLocalStorage } from '../../helpers/auth';
import hdrLogo from '../../assets/images/hdrLogo.png';
import mnuAdmin from '../../assets/images/mnuAdmin.png';
import mnuPromos from '../../assets/images/mnuPromos.png';
import mnuProfiles from '../../assets/images/mnuProfiles.png';
import mnuSponsors from '../../assets/images/mnuSponsors.png';
import mnuVenues from '../../assets/images/mnuVenue.png';
import mnuUsers from '../../assets/images/mnuUsers.png';
import mnuCustomers from '../../assets/images/mnuCustomers.png';
import mnuSports from '../../assets/images/mnuSports.png';
import mnuTeams from '../../assets/images/mnuTeams.png';
import mnuTypes from '../../assets/images/mnuTypes.png';
import mnuTemplates from '../../assets/images/mnuTemplate.png';

export default function NavBar({ hide }) {
	const navigate = useNavigate();
	const { auth, setAuth } = useContext(AuthContext);

	useEffect(() => {
		if (!auth || auth === null || auth === undefined) {
			setAuth(getFromLocalStorage('auth'));
		}
	}, [auth, setAuth]);

	const handleLogout = () => {
		setAuth(null);
		removeFromLocalStorage('auth');
		removeFromLocalStorage('userData');
		removeFromLocalStorage('_grecaptcha');
		navigate('/login');
	};

	return (
		<>
			{!hide && (
				<>
					<nav className='navbar navbar-expand-lg navbar-dark bg-dark fixed-top'>
						<div className='container-fluid'>
							{(!auth || auth === null) && (
								<NavLink className='navbar-brand' to='/'>
									<img className='hdrLogo me-4' src={hdrLogo} alt='QRQS' width='123' height='50' />
								</NavLink>
							)}
							{auth && <img className='hdrLogo me-4' src={hdrLogo} alt='QRQS' width='123' height='50' />}
							<button
								className='navbar-toggler'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#navbarSupportedContent'
								aria-controls='navbarSupportedContent'
								aria-expanded='false'
								aria-label='Toggle navigation'
							>
								<span className='navbar-toggler-icon'></span>
							</button>
							<div className='collapse navbar-collapse' id='navbarSupportedContent'>
								<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
									{(!auth || auth === null) && (
										<>
											{/*}
											<li className='nav-item'>
												<NavLink className='nav-link' to='/about'>
													About Us
												</NavLink>
											</li>
                                    {*/}
											<li className='nav-item'>
												<NavLink className='nav-link' to='/contact'>
													Contact Us
												</NavLink>
											</li>
											<li className='nav-item'>
												<NavLink className='nav-link' to='/login'>
													Login
												</NavLink>
											</li>
										</>
									)}
									{auth && (
										<>
											<ul className='navbar-nav'>
												<li className='nav-item dropdown'>
													<button
														className='nav-link dropdown-toggle'
														id='navbarDarkDropdownMenuLink'
														data-bs-toggle='dropdown'
														aria-expanded='false'
														style={{ backgroundColor: 'transparent', border: 'none' }}
													>
														Admin
													</button>
													<ul className='dropdown-menu dropdown-menu-dark' aria-labelledby='navbarDarkDropdownMenuLink'>
														<li>
															<NavLink className='dropdown-item mb-1' to='/admin'>
																<img className='mnuImg' src={mnuAdmin} alt='' />
																&nbsp;&nbsp;&nbsp;&nbsp; Admin
															</NavLink>
														</li>
														<li>
															<NavLink className='dropdown-item mb-1' to='/promos'>
																<img className='mnuImg' src={mnuPromos} alt='' />
																&nbsp;&nbsp;&nbsp;&nbsp; Promos
															</NavLink>
														</li>
														<li>
															<NavLink className='dropdown-item mb-1' to='/profiles'>
																<img className='mnuImg' src={mnuProfiles} alt='' />
																&nbsp;&nbsp;&nbsp;&nbsp; Profiles
															</NavLink>
														</li>
														<li>
															<NavLink className='dropdown-item mb-1' to='/sponsors'>
																<img className='mnuImg' src={mnuSponsors} alt='' />
																&nbsp;&nbsp;&nbsp;&nbsp; Sponsors
															</NavLink>
														</li>
														<li>
															<NavLink className='dropdown-item mb-1' to='/venues'>
																<img className='mnuImg' src={mnuVenues} alt='' />
																&nbsp;&nbsp;&nbsp;&nbsp; Venues
															</NavLink>
														</li>
														<li>
															<NavLink className='dropdown-item mb-1' to='/users'>
																<img className='mnuImg' src={mnuUsers} alt='' />
																&nbsp;&nbsp;&nbsp;&nbsp; Users
															</NavLink>
														</li>
													</ul>
												</li>
											</ul>
											{auth.user.superuser && (
												<ul className='navbar-nav'>
													<li className='nav-item dropdown'>
														<button
															className='nav-link dropdown-toggle'
															id='navbarDarkDropdownMenuLink'
															data-bs-toggle='dropdown'
															aria-expanded='false'
															style={{
																backgroundColor: 'transparent',
																border: 'none',
															}}
														>
															QRGP Admin
														</button>
														<ul className='dropdown-menu dropdown-menu-dark' aria-labelledby='navbarDarkDropdownMenuLink'>
															<li>
																<NavLink className='dropdown-item mb-1' to='/qrqsadmin'>
																	<img className='mnuImg' src={mnuAdmin} alt='' />
																	&nbsp;&nbsp;&nbsp;&nbsp; QRQS Admin
																</NavLink>
															</li>
															<li>
																<NavLink className='dropdown-item mb-1' to='/customers'>
																	<img className='mnuImg' src={mnuCustomers} alt='' />
																	&nbsp;&nbsp;&nbsp;&nbsp; Customers
																</NavLink>
															</li>
															<li>
																<NavLink className='dropdown-item mb-1' to='/sports'>
																	<img className='mnuImg' src={mnuSports} alt='' />
																	&nbsp;&nbsp;&nbsp;&nbsp; Sports
																</NavLink>
															</li>
															<li>
																<NavLink className='dropdown-item mb-1' to='/teams'>
																	<img className='mnuImg' src={mnuTeams} alt='' />
																	&nbsp;&nbsp;&nbsp;&nbsp; Teams
																</NavLink>
															</li>
															<li>
																<NavLink className='dropdown-item mb-1' to='/types'>
																	<img className='mnuImg' src={mnuTypes} alt='' />
																	&nbsp;&nbsp;&nbsp;&nbsp; Types
																</NavLink>
															</li>
															<li>
																<NavLink className='dropdown-item mb-1' to='/templates'>
																	<img className='mnuImg' src={mnuTemplates} alt='' />
																	&nbsp;&nbsp;&nbsp;&nbsp; Templates
																</NavLink>
															</li>
														</ul>
													</li>
												</ul>
											)}
											<li className='nav-item'>
												<NavLink className='nav-link' to='/account'>
													My Account
												</NavLink>
											</li>
											<li className='nav-item'>
												<div className='notNav nav-link' onClick={handleLogout}>
													Logout
												</div>
											</li>
										</>
									)}
								</ul>
							</div>
						</div>
					</nav>
					{auth && (
						<div className='mt-5 pt-4'>
							<div className='row px-2 d-flex justify-content-between'>
								{auth.user.superuser && (
									<div className='wlcmText col-6'>
										Customer: <span style={{ color: '#9b00ef' }}>{auth.masterCust.name}</span>
									</div>
								)}
								{!auth.user.superuser && <div className='col-6'></div>}
								<div className='wlcmText col-6 text-end'>Welcome {auth.user.fname}</div>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
}
