import React, { useRef } from 'react';
import './Forms.css';

export default function ImageEdit(props) {
	const fileInputRef = useRef(null);

	return (
		<div className='mb-3'>
			<input hidden type={props.type} accept='image/*' ref={fileInputRef} onChange={props.handler} />
			{props.newUrl !== '' && <img className='logoImg' src={props.newUrl} alt='' onClick={() => fileInputRef.current?.click()} />}
			{props.newUrl === '' && (
				<>
					{props.image && <img className='logoImg' src={props.image} alt='' onClick={() => fileInputRef.current?.click()} />}
					{!props.image && (
						<img className='logoImg' src={props.imgDefault} alt='' onClick={() => fileInputRef.current?.click()} />
					)}
				</>
			)}
		</div>
	);
}
