import { useContext, useEffect, useState } from 'react';
import './Profiles.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import { storage } from '../../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ImageEdit from '../../../components/forms/ImageEdit';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import defNoImg from '../../../assets/images/defNoImage.png';

export default function AddProfile() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [display, setDisplay] = useState('');
	const [stats, setStats] = useState('');
	const [number, setNumber] = useState('');
	const [title, setTitle] = useState('');
	const [abbr, setAbbr] = useState('');
	const [newImgUrl, setNewImgUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/profiles');
		}
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			let uplImgUrl = '';
			if (newImgUrl !== '') {
				uplImgUrl = newImgUrl;
			} else {
				uplImgUrl = defNoImg;
			}

			if (fname.trim() === '') {
				const input = document.getElementById('fname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('First Name is Required');
				return;
			}
			if (lname.trim() === '') {
				const input = document.getElementById('lname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Last Name is Required');
				return;
			}

			const { data } = await axios.post('/addProfile', {
				fname,
				lname,
				displayname: display,
				stats,
				number,
				postitle: title,
				posabbr: abbr,
				photo: uplImgUrl,
				customerId: auth.masterCust.id,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Profile successfully added');
				setLoading(false);
				navigate('/profiles');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleFileChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/customers/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewImgUrl(URL);
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};

	return (
		<AdminPageTemplate title='Add Profile'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='fname' id='fname' label='First Name' type='text' value={fname} setValue={setFname} />
					<Input name='lname' id='lname' label='Last Name' type='text' value={lname} setValue={setLname} />
					<Input name='display' id='display' label='Display Name' type='text' value={display} setValue={setDisplay} />
					<Input name='stats' id='stats' label='Stats URL' type='text' value={stats} setValue={setStats} />
					<Input name='number' id='number' label='Number' type='number' value={number} setValue={setNumber} />
					<Input name='title' id='title' label='Position Title' type='text' value={title} setValue={setTitle} />
					<Input name='abbr' id='abbr' label='Position Abbreviation' type='text' value={abbr} setValue={setAbbr} />
					<ImageEdit inputId='imgPhoto' type='file' handler={handleFileChange} newUrl={newImgUrl} image={defNoImg} imgDefault={defNoImg} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!fname || !lname}>
							Save Profile
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
