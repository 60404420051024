import React from 'react';
import './Home.css';
import hmpgLogo from '../../../assets/images/hmpgLogo.png';
import hmpgTech from '../../../assets/images/hmpgTech.jpg';
import hmpgFans from '../../../assets/images/hmpgFans.jpg';
import hmpgOverview from '../../../assets/images/hmpgOverview.jpg';
import hmpgTemps from '../../../assets/images/hmpgTemps.jpg';

export default function Home() {
	return (
		<div className='mt-5'>
			<div className='container-fluid pt-3'>
				<img className='hmpgImg pt-4' src={hmpgLogo} alt='QR Gameplan' />
			</div>
			<div className='row justify-content-evenly mt-5'>
				<div className='col-12 col-md-6 col-xl-3 my-2 d-flex align-items-stretch justify-content-center'>
					<div className='card' style={{ width: '80%' }}>
						<img className='card-img-top' src={hmpgTech} alt='Technology' />
						<div className='card-body'>
							<h4 className='cardTitle card-title text-center mb-3'>A Mobile Marketing Game Changer</h4>
							<p className='cardText'>Create a more effective and engaging mobile marketing experience for everyone.</p>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-6 col-xl-3 my-2 d-flex align-items-stretch justify-content-center'>
					<div className='card' style={{ width: '80%' }}>
						<img className='card-img-top' src={hmpgFans} alt='Fans' />
						<div className='card-body'>
							<h4 className='cardTitle card-title text-center mb-3'>Plain &amp; Simple</h4>
							<p className='cardText'>Increase Revenue</p>
							<p className='cardText'>Keep Fans Engaged Longer</p>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-6 col-xl-3 my-2 d-flex align-items-stretch justify-content-center'>
					<div className='card' style={{ width: '80%' }}>
						<img className='card-img-top' src={hmpgOverview} alt='Overview' />
						<div className='card-body'>
							<h4 className='cardTitle card-title text-center mb-3'>Increase Revenue</h4>
							<p className='cardText'>1 QR Code - Multiple Vendors</p>
							<p className='cardText'>Multiple Vendors - Multiple Revenue Streams</p>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-6 col-xl-3 my-2 d-flex align-items-stretch justify-content-center'>
					<div className='card' style={{ width: '80%' }}>
						<img className='card-img-top' src={hmpgTemps} alt='Templates' />
						<div className='card-body'>
							<h4 className='cardTitle card-title text-center mb-3'>Keep Fans Engaged</h4>
							<p className='cardText'>Timed Changing Ads</p>
							<p className='cardText'>Not Just Ads - Many Activities &amp; Choices</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
