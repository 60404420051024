import { useContext, useEffect, useState } from 'react';
import './Sports.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';

export default function AddSport() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [sport, setSport] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/sports');
		}
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);

			if (sport.trim() === '') {
				const input = document.getElementById('sport');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Sport is Required');
				return;
			}

			const { data } = await axios.post(`/addSport/${auth.user.superuser}`, {
				sport,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Successfully added sport');
				setLoading(false);
				navigate('/sports');
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Add Sport'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='sport' id='sport' label='Sport' type='text' value={sport} setValue={setSport} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!sport}>
							Save Sport
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
