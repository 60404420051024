import React from 'react';
import './Forms.css';

export default function PlayersForm({ children }) {
	return (
		<>
			<div className='mb-3'>
				<div className='col-md-2 col-xl-4'></div>
				<div className='prmHdng col-12 col-md-10 col-xl-8'>Choose Starting Players:</div>
			</div>
			{children}
		</>
	);
}
