import { useCallback, useEffect, useState } from 'react';
import './Venues.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';

export default function EditVenue() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [venue, setVenue] = useState({});
	const [levels, setLevels] = useState([]);
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadVenue = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getVenue/${id}`);
			setVenue(data);
		} catch (err) {
			console.log(err);
		}
	}, [id]);

	const loadLevels = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getVenueLevels/${id}`);
			setLevels(data);
		} catch (err) {
			console.log(err);
		}
	}, [id]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/venues');
		}
	});

	useEffect(() => {
		if (venue.length === 0 || venue.length === undefined) {
			loadVenue();
		}
	}, [venue, loadVenue]);

	useEffect(() => {
		if (levels.length === 0) {
			loadLevels();
		}
	}, [levels, loadLevels]);

	useEffect(() => {
		if (venue.name !== undefined && name === '') {
			setName(venue.name);
		}
	}, [venue, name]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			if (name.trim() === '') {
				const input = document.getElementById('name');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Name is Required');
				return;
			}

			const { data } = await axios.put(`/updVenue/${id}`, {
				name,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Venue successfully updated');
				setLoading(false);
				navigate('/venues');
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Edit Venue'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Name' type='text' value={name} setValue={setName} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
