import React from 'react';
import './Qsite.css';
import socFB from '../../assets/images/socFB.png';
import socTW from '../../assets/images/socTW.png';
import socIG from '../../assets/images/socIG.png';
import socTT from '../../assets/images/socTT.png';
import socEM from '../../assets/images/socEM.png';
import socPH from '../../assets/images/socPH.png';
import socDonation from '../../assets/images/socDonation.png';
import socSecurity from '../../assets/images/socSecurity.png';

export default function Social({ soc }) {
	return (
		<>
			{soc.security && (
				<div className='row mb-4'>
					<div className='col-12 d-flex justify-content-center'>
						<div className='socHdng mb-2'>EMERGENCY</div>
					</div>
					<div className='col-12 d-flex justify-content-center'>
						{soc.security !== '' && (
							<a href={`tel:${soc.security}`}>
								<div className='socDiv'>
									<img src={socSecurity} alt='Security' />
								</div>
							</a>
						)}
					</div>
				</div>
			)}
			{(soc.email || soc.phone || soc.donation) && (
				<div className='row mb-4'>
					<div className='col-12 d-flex justify-content-center'>
						<div className='socHdng mb-2'>CONNECT WITH US</div>
					</div>
					<div className='col-12 d-flex justify-content-center'>
						{soc.email !== '' && (
							<a href={`mailto:${soc.email}?subject=${soc.subject}`}>
								<div className='socDiv'>
									<img src={socEM} alt='EM' />
								</div>
							</a>
						)}
						{soc.phone !== '' && (
							<a href={`tel:${soc.phone}`}>
								<div className='socDiv'>
									<img src={socPH} alt='PH' />
								</div>
							</a>
						)}
						{soc.donation !== '' && (
							<a href={soc.donation}>
								<div className='socDiv'>
									<img src={socDonation} alt='Donate' />
								</div>
							</a>
						)}
					</div>
				</div>
			)}
			{(soc.facebook !== '' || soc.twitter !== '' || soc.instagram !== '' || soc.tiktok !== '' || soc.pinterest !== '') && (
				<div className='row'>
					<div className='col-12 d-flex justify-content-center'>
						<div className='socHdng mb-2'>FOLLOW US</div>
					</div>
					<div className='col-12 d-flex justify-content-center'>
						{soc.facebook !== '' && (
							<a href={soc.facebook} target='_blank' rel='noreferrer'>
								<div className='socDiv'>
									<img src={socFB} alt='FB' />
								</div>
							</a>
						)}
						{soc.twitter !== '' && (
							<a href={soc.twitter} target='_blank' rel='noreferrer'>
								<div className='socDiv'>
									<img src={socTW} alt='TW' />
								</div>
							</a>
						)}
						{soc.instagram !== '' && (
							<a href={soc.instagram} target='_blank' rel='noreferrer'>
								<div className='socDiv'>
									<img src={socIG} alt='IG' />
								</div>
							</a>
						)}
						{soc.tiktok !== '' && (
							<a href={soc.tiktok} target='_blank' rel='noreferrer'>
								<div className='socDiv'>
									<img src={socTT} alt='TT' />
								</div>
							</a>
						)}
					</div>
				</div>
			)}
		</>
	);
}
