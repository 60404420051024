import React from 'react';
import './Qsite.css';

export default function SingleSpon({ prmSpns, allSpns }) {
	return (
		<>
			{prmSpns.map((prmSpn) => (
				<a href={prmSpn.url} key={prmSpn._id} target='_blank' rel='noreferrer'>
					{allSpns.map((spn) => (
						<div key={spn._id}>{spn._id === prmSpn.sponsorId && <img className='hdrImg' src={spn.logo} alt={spn.name} />}</div>
					))}
				</a>
			))}
		</>
	);
}
