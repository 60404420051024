import { useCallback, useContext, useEffect, useState } from 'react';
import './Teams.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import { storage } from '../../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxMd from '../../../components/shadowboxes/ShadowBoxMd';
import ImageEdit from '../../../components/forms/ImageEdit';
import defNoImg from '../../../assets/images/defNoImage.png';

export default function AddTeam() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [sports, setSports] = useState([]);
	const [city, setCity] = useState('');
	const [mascot, setMascot] = useState('');
	const [league, setLeague] = useState('');
	const [conference, setConference] = useState('');
	const [division, setDivision] = useState('');
	const [colorPrimary, setColorPrimary] = useState('#000000');
	const [colorSecondary, setColorSecondary] = useState('#000000');
	const [sportId, setSportId] = useState('');
	const [newImgUrl, setNewImgUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadSports = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSports/${auth.user.superuser}`);
			setSports(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/teams');
		}
	});

	useEffect(() => {
		if (sports.length === 0) {
			loadSports();
		}
	}, [sports, loadSports]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			let uplImgUrl = '';
			if (newImgUrl !== '') {
				uplImgUrl = newImgUrl;
			} else {
				uplImgUrl = defNoImg;
			}

			if (city.trim() === '') {
				const input = document.getElementById('city');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('City is Required');
				return;
			}
			if (mascot.trim() === '') {
				const input = document.getElementById('mascot');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Mascot is Required');
				return;
			}
			if (league.trim() === '') {
				const input = document.getElementById('league');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('League is Required');
				return;
			}
			if (sportId.trim() === '') {
				const input = document.getElementById('sport');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Sport is Required');
				return;
			}

			const { data } = await axios.post(`/addTeam/${auth.user.superuser}`, {
				city,
				mascot,
				league,
				conference,
				division,
				logo: uplImgUrl,
				colorprimary: colorPrimary,
				colorsecondary: colorSecondary,
				sportId,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Successfully added Team');
				setLoading(false);
				navigate('/teams');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleFileChange = async (e) => {
		let file = e.target.files.item(0);
		const storageRef = ref(storage, `/teams/${Date.now()}`);
		const uploadTask = uploadBytes(storageRef, file);
		uploadTask
			.then(() => {
				getDownloadURL(storageRef).then((URL) => {
					setNewImgUrl(URL);
				});
			})
			.catch((err) => {
				console.log(err);
			});
		file = null;
	};

	const handleSport = (e) => {
		const value = e.target.value;
		setSportId(value);
	};

	return (
		<AdminPageTemplate title='Add Team'>
			<ShadowBoxMd>
				<form onSubmit={handleSubmit}>
					<div className='row d-flex justify-content-around'>
						<div className='col-12 col-lg-5'>
							<Input name='city' id='city' label='City' type='text' value={city} setValue={setCity} />
							<Input name='mascot' id='mascot' label='Mascot' type='text' value={mascot} setValue={setMascot} />
							<Input name='league' id='league' label='League' type='text' value={league} setValue={setLeague} />
							<Input name='conference' id='conference' label='Conference' type='text' value={conference} setValue={setConference} />
							<Input name='division' id='division' label='Division' type='text' value={division} setValue={setDivision} />
						</div>
						<div className='col-12 col-lg-5'>
							<div className='frmLabel mb-3'>Logo</div>
							<ImageEdit inputId='imgLogo' type='file' handler={handleFileChange} newUrl={newImgUrl} image={defNoImg} imgDefault={defNoImg} />
							<Input name='colorprimary' id='colorprimary' label='Primary Color' type='color' value={colorPrimary} setValue={setColorPrimary} />
							<Input name='colorsecondary' id='colorsecondary' label='Secondary Color' type='color' value={colorSecondary} setValue={setColorSecondary} />
							<div>
								<label className='frmLabel'>Sport</label>
								<select className='form-select' id='sport' value={sportId} onChange={(e) => handleSport(e)}>
									<option value=''></option>
									{sports.map((sport) => (
										<option key={sport._id} value={sport._id}>
											{sport.sport}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!city || !mascot || !league || !sportId}>
							Save Team
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxMd>
		</AdminPageTemplate>
	);
}
