import React from 'react';
import './Forms.css';
import Input from './Input';
import CaptureFormChkBox from './CaptureFormChkBox';

export default function CaptureFormCustom(props) {
	return (
		<div className='row mb-3 d-flex align-items-center'>
			<div className='col-md-1'></div>
			<div className='col-6 col-md-5'>
				<Input
					type='text'
					id={props.inputIdTxt}
					placeholder='Custom Field'
					value={props.txtValue}
					max={props.max}
					setValue={props.handlerT}
				/>
			</div>
			<div className='col-3 text-center'>
				<CaptureFormChkBox inputId={props.inputIdC} cChkd={props.cChkd} handler={props.handlerC} />
			</div>
			<div className='col-3 text-center'>
				<CaptureFormChkBox inputId={props.inputIdR} rChkd={props.rChkd} handler={props.handlerR} />
			</div>
		</div>
	);
}
