import React from 'react';
import './Links.css';
import { Link } from 'react-router-dom';

export default function Password() {
	return (
		<Link className='fpLink' to='/forgotpassword'>
			Forgot Password
		</Link>
	);
}
