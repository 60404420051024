import React, { useEffect, useState } from 'react';
import './Qsite.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../../components/qsite/Header';
import Headline from '../../../components/qsite/Headline';
import MultiSponsor from '../../../components/qsite/MultiSponsor';
import BodyText from '../../../components/qsite/BodyText';
import Video from '../../../components/qsite/Video';
import Social from '../../../components/qsite/Social';
import Buttons from '../../../components/qsite/Buttons';
import SingleSpon from '../../../components/qsite/SingleSpon';
import Capture from '../../../components/qsite/Capture';
import Trivia from '../../../components/qsite/Trivia';
import PlayerLineup from '../../../components/qsite/PlayerLineup';
import Profile from '../../../components/qsite/Profile';
import TwoButton from '../../../components/qsite/TwoButton';

export default function Qsite_Full() {
	const { tUrl } = useParams();
	const [promo, setPromo] = useState({});
	const [noPrm, setNoPrm] = useState(false);
	const [prmId, setPrmId] = useState('');
	const [tempName, setTempName] = useState('');
	const [custId, setCustId] = useState('');
	const [prmSpns, setPrmSpns] = useState([]);
	const [noPrmSpns, setNoPrmSpns] = useState(false);
	const [allSpns, setAllSpns] = useState([]);
	const [noSpns, setNoSpns] = useState(false);
	const [btns, setBtns] = useState([]);
	const [noBtns, setNoBtns] = useState(false);
	const [social, setSocial] = useState({});
	const [noSoc, setNoSoc] = useState(false);
	const [capture, setCapture] = useState([]);
	const [noCap, setNoCap] = useState(false);
	const [trivia, setTrivia] = useState([]);
	const [noTrv, setNoTrv] = useState(false);
	const [players, setPlayers] = useState([]);
	const [noPly, setNoPly] = useState(false);

	// Get Promo Data
	useEffect(() => {
		if (Object.keys(promo).length === 0 && tUrl !== '' && !noPrm) {
			const initPromo = async () => {
				const { data } = await axios.get(`/getPromo4Qsite/${tUrl}`);
				if (data.length === 0) {
					setNoPrm(true);
				} else {
					setPromo(data);
					setPrmId(data._id);
					setTempName(data.tempname);
					setCustId(data.customerId);
				}
			};
			initPromo();
		}
	}, [promo, tUrl, noPrm, setNoPrm, setPromo, setPrmId, setTempName, setCustId]);
	// Get Promo Sponsors
	useEffect(() => {
		if (
			prmSpns.length === 0 &&
			prmId !== '' &&
			(tempName === 'Multi-Sponsor' || tempName === 'Single Sponsor' || tempName === 'Player Lineup' || tempName === 'Profile Page') &&
			!noPrmSpns
		) {
			const initPromoSponsors = async () => {
				const { data } = await axios.get(`/getPrmSpns4Qsite/${prmId}`);
				if (data.length === 0) {
					setNoPrmSpns(true);
				} else {
					setPrmSpns(data);
				}
			};
			initPromoSponsors();
		}
	}, [prmSpns, prmId, tempName, noPrmSpns, setNoPrmSpns, setPrmSpns]);
	// Get All Sponsors
	useEffect(() => {
		if (
			allSpns.length === 0 &&
			custId !== '' &&
			(tempName === 'Multi-Sponsor' || tempName === 'Single Sponsor' || tempName === 'Player Lineup' || tempName === 'Profile Page') &&
			!noSpns
		) {
			const initAllSponsors = async () => {
				const { data } = await axios.get(`/getAllSpns4Qsite/${custId}`);
				if (data.length === 0) {
					setNoSpns(true);
				} else {
					setAllSpns(data);
				}
			};
			initAllSponsors();
		}
	}, [allSpns, custId, tempName, noSpns, setNoSpns, setAllSpns]);
	// Get Custom Buttons
	useEffect(() => {
		if (btns.length === 0 && prmId !== '' && tempName !== 'Qpon Page' && !noBtns) {
			const initBtns = async () => {
				const { data } = await axios.get(`/getBtns4Qsite/${prmId}`);
				if (data.length === 0) {
					setNoBtns(true);
				} else {
					setBtns(data);
				}
			};
			initBtns();
		}
	}, [btns, prmId, tempName, noBtns, setNoBtns, setBtns]);
	// Get Social Links
	useEffect(() => {
		if (Object.keys(social).length === 0 && promo.shwsocial && prmId !== '' && !noSoc) {
			const initSoc = async () => {
				const { data } = await axios.get(`/getSocial4Qsite/${prmId}`);
				if (data.length === 0) {
					setNoSoc(true);
				} else {
					setSocial(data);
				}
			};
			initSoc();
		}
	}, [social, promo, prmId, noSoc, setNoSoc, setSocial]);
	// Get Capture Form
	useEffect(() => {
		if (capture.length === 0 && tempName === 'User Input Form' && prmId !== '' && !noCap) {
			const initUIF = async () => {
				const { data } = await axios.get(`/getCapture4Qsite/${prmId}`);
				if (data.length === 0) {
					setNoCap(true);
				} else {
					setCapture(data);
				}
			};
			initUIF();
		}
	}, [capture, tempName, prmId, noCap, setNoCap, setCapture]);
	// Get Trivia
	useEffect(() => {
		if (trivia.length === 0 && tempName === 'Trivia Page' && prmId !== '' && !noTrv) {
			const initTrivia = async () => {
				const { data } = await axios.get(`/getTrivia4Qsite/${prmId}`);
				if (data.length === 0) {
					setNoTrv(true);
				} else {
					setTrivia(data);
				}
			};
			initTrivia();
		}
	}, [trivia, tempName, prmId, noTrv, setNoTrv, setTrivia]);
	// Get Game Players
	useEffect(() => {
		if (players.length === 0 && tempName === 'Player Lineup' && prmId !== '' && !noPly) {
			const initPlayers = async () => {
				const { data } = await axios.get(`/getPlayers4Qsite/${prmId}`);
				if (data.length === 0) {
					setNoPly(true);
				} else {
					setPlayers(data);
				}
			};
			initPlayers();
		}
	}, [players, tempName, prmId, noPly, setNoPly, setPlayers]);

	return (
		<div className='vwContainer'>
			{tempName === 'Multi-Sponsor' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					<MultiSponsor prmSpns={prmSpns} allSpns={allSpns} btns={btns} />
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
					{promo.video !== '' && <Video video={promo.video} />}
				</>
			)}
			{tempName === 'Announcement' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
					{promo.video !== '' && <Video video={promo.video} />}
					<Buttons btns={btns} />
				</>
			)}
			{tempName === 'Single Sponsor' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
					{promo.video !== '' && <Video video={promo.video} />}
					<SingleSpon prmSpns={prmSpns} allSpns={allSpns} />
					<Buttons btns={btns} />
				</>
			)}
			{tempName === 'User Input Form' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
					<Capture fields={capture} prmId={prmId} />
					<Buttons btns={btns} />
				</>
			)}
			{tempName === 'Trivia Page' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
					<Trivia arrTrivia={trivia} />
					<Buttons btns={btns} />
				</>
			)}
			{tempName === 'Player Lineup' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					<PlayerLineup prmSpns={prmSpns} allSpns={allSpns} players={players} />
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
					{promo.video !== '' && <Video video={promo.video} />}
					<Buttons btns={btns} />
				</>
			)}
			{tempName === 'Profile Page' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					<Profile prmSpns={prmSpns} allSpns={allSpns} btns={btns} prfId={promo.profileId} />
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
					{promo.video !== '' && <Video video={promo.video} />}
				</>
			)}
			{tempName === 'Qpon Page' && (
				<>
					<img className='qpnImg mb-4' src={promo.qpon} alt={promo.name} />
					{promo.bodytext !== '' && <BodyText body={promo.bodytext} />}
				</>
			)}
			{tempName === '2 - Buttons' && (
				<>
					{promo.header !== '' && <Header url={promo.header} name={promo.name} />}
					{promo.headline !== '' && <Headline text={promo.headline} />}
					<TwoButton btns={btns} />
				</>
			)}
			{promo.shwsocial && <Social soc={social} />}
			<div style={{ height: '50px' }}></div>
		</div>
	);
}
