import { useContext, useEffect, useState } from 'react';
import './Types.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';

export default function AddType() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/types');
		}
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);

			if (name.trim() === '') {
				const input = document.getElementById('name');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Name is Required');
				return;
			}

			const { data } = await axios.post(`/addType/${auth.user.superuser}`, {
				name,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Successfully added type');
				setLoading(false);
				navigate('/types');
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Add Type'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Name' type='text' value={name} setValue={setName} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name}>
							Save Type
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
