import { useCallback, useContext, useEffect, useState } from 'react';
import './Types.css';
import { AuthContext } from '../../../context/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';

export default function EditType() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { auth } = useContext(AuthContext);
	const [type, setType] = useState({});
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadType = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getType/${id}/${auth.user.superuser}`);
			setType(data);
		} catch (err) {
			console.log(err);
		}
	}, [id, auth]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/types');
		}
	});

	useEffect(() => {
		if (type.length === 0 || type.length === undefined) {
			loadType();
		}
	}, [type, loadType]);

	useEffect(() => {
		if (type.name !== undefined && name === '') {
			setName(type.name);
		}
	}, [type, name]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			const { data } = await axios.put(`/updType/${id}/${auth.user.superuser}`, {
				name,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Type updated successfully');
				setLoading(false);
				navigate('/types');
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<AdminPageTemplate title='Edit Type'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='name' id='name' label='Name' type='text' value={name} setValue={setName} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name}>
							Save Changes
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
