import React from 'react';
import './Forms.css';

export default function CheckBoxActive({ active, funcCall, value, id }) {
	return (
		<>
			{active && <input className='chkBox' type='checkbox' checked onChange={(e) => funcCall(e, value, id)} />}
			{!active && <input className='chkBox' type='checkbox' onChange={(e) => funcCall(e, value, id)} />}
		</>
	);
}
