import { useContext, useEffect, useState } from 'react';
import './Users.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxSm from '../../../components/shadowboxes/ShadowBoxSm';
import emailjs from '@emailjs/browser';
import { AuthContext } from '../../../context/auth';

const randomStringMake = (count) => {
	const letter = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let randomString = '';
	for (let i = 0; i < count; i++) {
		const randomStringNumber = Math.floor(1 + Math.random() * (letter.length - 1));
		randomString += letter.substring(randomStringNumber, randomStringNumber + 1);
	}
	return randomString;
};

const formatPhoneNumber = (value) => {
	if (!value) return value;
	let phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength > 10) {
		phoneNumber = phoneNumber.substring(0, 10);
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export default function AddUser() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [title, setTitle] = useState('');
	const [cPhn, setCPhone] = useState('');
	const [wPhn, setWPhone] = useState('');
	const [wExt, setWExt] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/users');
		}
	});

	//Set temporary password to send in email
	useEffect(() => {
		if (password === '') {
			const tmpPwrd = randomStringMake(10);
			setPassword(tmpPwrd);
		}
	}, [password]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			if (fname.trim() === '') {
				const input = document.getElementById('fname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('First Name is Required');
				return;
			}
			if (lname.trim() === '') {
				const input = document.getElementById('lname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Last Name is Required');
				return;
			}
			if (email.trim() === '') {
				const input = document.getElementById('email');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Email is Required');
				return;
			}

			const { data } = await axios.post('/addUser', {
				fname,
				lname,
				email,
				title,
				cphn: cPhn,
				wphn: wPhn,
				wext: wExt,
				password,
				customername: auth.masterCust.name,
				customerId: auth.masterCust.id,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				const toSend = {
					toName: fname,
					userEmail: email,
					tmpPword: password,
				};
				emailjs.send('service_heg30c2', 'template_171ffex', toSend, 'user_QwSUk6avGckJ2ufY4kqDl');
				toast.success('User successfully added');
				setLoading(false);
				navigate('/users');
			}
		} catch (err) {
			console.log(err);
		}
	};

	function handleCPhone(e) {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setCPhone(formattedPhoneNumber);
	}

	function handleWPhone(e) {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setWPhone(formattedPhoneNumber);
	}

	return (
		<AdminPageTemplate title='Add User'>
			<ShadowBoxSm>
				<form onSubmit={handleSubmit}>
					<Input name='fname' id='fname' label='First Name' type='text' value={fname} setValue={setFname} />
					<Input name='lname' id='lname' label='Last Name' type='text' value={lname} setValue={setLname} />
					<Input name='email' id='email' label='Email' type='email' value={email} setValue={setEmail} />
					<Input name='title' id='title' label='Title' type='text' value={title} setValue={setTitle} />
					<Input name='cPhn' id='cPhn' label='Cell Phone' type='tel' value={cPhn} funcCall={handleCPhone} />
					<Input name='wPhn' id='wPhn' label='Work Phone' type='tel' value={wPhn} funcCall={handleWPhone} />
					<Input name='wExt' id='wExt' label='Work Extension' type='tel' value={wExt} setValue={setWExt} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!fname || !lname || !email}>
							Add User
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxSm>
		</AdminPageTemplate>
	);
}
