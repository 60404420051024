import { useCallback, useContext, useEffect, useState } from 'react';
import './Customers.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/auth';
import axios from 'axios';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Cancel from '../../../components/links/Cancel';
import ShadowBoxMd from '../../../components/shadowboxes/ShadowBoxMd';
import emailjs from '@emailjs/browser';

const randomStringMake = (count) => {
	const letter = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let randomString = '';
	for (let i = 0; i < count; i++) {
		const randomStringNumber = Math.floor(1 + Math.random() * (letter.length - 1));
		randomString += letter.substring(randomStringNumber, randomStringNumber + 1);
	}
	return randomString;
};

const formatPhoneNumber = (value) => {
	if (!value) return value;
	let phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength > 10) {
		phoneNumber = phoneNumber.substring(0, 10);
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export default function AddCustomer() {
	const navigate = useNavigate();
	const { auth } = useContext(AuthContext);
	const [types, setTypes] = useState([]);
	const [sports, setSports] = useState([]);
	const [teams, setTeams] = useState([]);
	const [name, setName] = useState('');
	const [subdomain, setSubdomain] = useState('');
	const [analytics, setAnalytics] = useState('');
	const [typename, setTypename] = useState('');
	const [typeId, setTypeId] = useState('');
	const [sportId, setSportId] = useState('');
	const [teamId, setTeamId] = useState('');
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [cphn, setCphn] = useState('');
	const [wphn, setWphn] = useState('');
	const [wext, setWext] = useState('');
	const [title, setTitle] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);

	const loadTypes = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getTypes/${auth.user.superuser}`);
			setTypes(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	const loadSports = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getSports/${auth.user.superuser}`);
			setSports(data);
		} catch (err) {
			console.log(err);
		}
	}, [auth]);

	const loadTeams = useCallback(async () => {
		if (sportId !== '' && sportId !== undefined) {
			try {
				const { data } = await axios.get(`/getTeamsBySport/${sportId}/${auth.user.superuser}`);
				setTeams(data);
			} catch (err) {
				console.log(err);
			}
		}
	}, [sportId, auth]);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/customers');
		}
	});

	//Set temporary password to send in email
	useEffect(() => {
		if (password === '') {
			const tmpPwrd = randomStringMake(10);
			setPassword(tmpPwrd);
		}
	}, [password]);

	useEffect(() => {
		if (types.length === 0) {
			loadTypes();
		}
	}, [types, loadTypes]);

	useEffect(() => {
		if (sports.length === 0) {
			loadSports();
		}
	}, [sports, loadSports]);

	useEffect(() => {
		if (sportId !== '' && teams.length === 0) {
			loadTeams();
		}
	}, [sportId, teams, loadTeams]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);

			if (name.trim() === '') {
				const input = document.getElementById('name');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Customer Name is Required');
				return;
			}
			if (typename.trim() === '') {
				const input = document.getElementById('type');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Type is Required');
				return;
			}
			if (typeId.trim() === '') {
				const input = document.getElementById('type');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Type is Required');
				return;
			}
			if (fname.trim() === '') {
				const input = document.getElementById('fname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('First Name is Required');
				return;
			}
			if (lname.trim() === '') {
				const input = document.getElementById('lname');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Last Name is Required');
				return;
			}
			if (email.trim() === '') {
				const input = document.getElementById('email');
				if (input != null) {
					input.style.backgroundColor = '#fac7ca';
					input.style.border = '2px solid black';
				}
				setLoading(false);
				toast.error('Email is Required');
				return;
			}

			let dbSportId = '';
			let dbTeamId = '';
			if (sportId === '') {
				dbSportId = '000000000000000000000000';
			} else {
				dbSportId = sportId;
			}
			if (teamId === '') {
				dbTeamId = '000000000000000000000000';
			} else {
				dbTeamId = teamId;
			}

			const newCust = await axios.post(`/addCustomer/${auth.user.superuser}`, {
				name,
				subdomain,
				analytics,
				typename,
				typeId,
				sportId: dbSportId,
				teamId: dbTeamId,
			});
			const newCustId = newCust.data._id;

			const { data } = await axios.post('/addUser', {
				fname,
				lname,
				email,
				cphn,
				wphn,
				wext,
				title,
				password,
				customername: name,
				customerId: newCustId,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				//Send email to new admin
				const toSend = {
					custName: name,
					toEmail: email,
					tmpPword: password,
				};
				emailjs.send('service_heg30c2', 'template_cdbudku', toSend, 'user_QwSUk6avGckJ2ufY4kqDl');

				toast.success('Successfully added Customer');
				setLoading(false);
				navigate('/customers');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleName = (e) => {
		const value = e.target.value;
		setName(value);
		let sub = value.replace(/\s+/g, '').toLowerCase();
		setSubdomain(sub);
	};

	const handleType = (e) => {
		const value = e.target.value;
		setTypeId(value);
		const index = e.nativeEvent.target.selectedIndex;
		setTypename(e.nativeEvent.target[index].text);
	};

	const handleSport = (e) => {
		const value = e.target.value;
		setSportId(value);
		loadTeams();
	};

	const handleTeam = (e) => {
		const value = e.target.value;
		setTeamId(value);
	};

	const handleCPhn = (e) => {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setCphn(formattedPhoneNumber);
	};

	const handleWPhn = (e) => {
		const value = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(value);
		setWphn(formattedPhoneNumber);
	};

	return (
		<AdminPageTemplate title='Add Customer'>
			<ShadowBoxMd>
				<form onSubmit={handleSubmit}>
					<div className='row d-flex justify-content-around'>
						<div className='col-12 col-lg-5'>
							<Input name='name' id='name' label='Customer Name' type='text' value={name} funcCall={handleName} />
							<Input name='subdomain' id='subdomain' label='Subdomain' type='text' readonly={true} value={subdomain} />
							<Input name='analytics' id='analytics' label='Analytics' type='text' value={analytics} setValue={setAnalytics} />
							<div className='mb-3'>
								<label className='frmLabel'>Type</label>
								<select className='form-select' id='type' value={typeId} onChange={(e) => handleType(e)}>
									<option value=''>Select One...</option>
									{types.map((type) => (
										<option key={type._id} value={type._id}>
											{type.name}
										</option>
									))}
								</select>
							</div>
							{typename === 'Sports' && (
								<div className='mb-3'>
									<label className='frmLabel'>Sport</label>
									<select className='form-select' id='type' value={sportId} onChange={(e) => handleSport(e)}>
										<option value=''>Select One...</option>
										{sports.map((sport) => (
											<option key={sport._id} value={sport._id}>
												{sport.sport}
											</option>
										))}
									</select>
								</div>
							)}
							{sportId !== '' && (
								<div className='mb-3'>
									<label className='frmLabel'>Team</label>
									<select className='form-select' id='type' value={teamId} onChange={(e) => handleTeam(e)}>
										<option value=''>Select One...</option>
										{teams.map((team) => (
											<option key={team._id} value={team._id}>
												{team.city} {team.mascot}
											</option>
										))}
									</select>
								</div>
							)}
						</div>
						<div className='col-12 col-lg-5'>
							<Input name='fname' id='fname' label='First Name' type='text' value={fname} setValue={setFname} />
							<Input name='lname' id='lname' label='Last Name' type='text' value={lname} setValue={setLname} />
							<Input name='email' id='email' label='Email' type='email' value={email} setValue={setEmail} />
							<Input name='cphn' id='cphn' label='Cell Phone' type='tel' value={cphn} funcCall={handleCPhn} />
							<Input name='wphn' id='wphn' label='Work Phone' type='tel' value={wphn} funcCall={handleWPhn} />
							<Input name='wext' id='wext' label='Work Ext.' type='text' value={wext} setValue={setWext} />
							<Input name='title' id='title' label='Title' type='text' value={title} setValue={setTitle} />
						</div>
					</div>
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' loading={loading} disabled={!name || !typeId || !fname || !lname || !email}>
							Save Customer
						</Button>
					</div>
				</form>
				<div className='my-3 d-flex justify-content-center'>
					<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
				</div>
			</ShadowBoxMd>
		</AdminPageTemplate>
	);
}
