import React from 'react';
import './Forms.css';

export default function CustomBtnElement({ label, txtId, max, txtValue, setTxtValue, urlId, urlValue, setUrlValue }) {
	return (
		<>
			<div className='mb-1'>
				<label className='frmLabel'>{label}</label>
				<input className='form-control' type='text' placeholder='Button Text' id={txtId} maxLength={max} value={txtValue} onChange={(e) => setTxtValue(e.target.value)} />
			</div>
			<div className='mb-3'>
				<input className='form-control' type='text' placeholder='https://' id={urlId} value={urlValue} onChange={(e) => setUrlValue(e.target.value)} />
			</div>
		</>
	);
}
