import React from 'react';
import './Admin.css';
import { Link } from 'react-router-dom';
import AdminPageTemplate from '../../../components/adminpagetemplate/AdminPageTemplate';
import icoPromos from '../../../assets/images/pgPromos.png';
import icoProfs from '../../../assets/images/pgProfiles.png';
import icoSpons from '../../../assets/images/pgSponsors.png';
import icoVens from '../../../assets/images/pgVenue.png';
import icoUsers from '../../../assets/images/pgUsers.png';

export default function Admin() {
	return (
		<AdminPageTemplate title='Admin'>
			<div className='row d-flex justify-content-center'>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/promos'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoPromos} alt='Promos' />
							<br />
							Promos
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/profiles'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoProfs} alt='Profiles' />
							<br />
							Profiles
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/sponsors'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoSpons} alt='Sponsors' />
							<br />
							Sponsors
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/venues'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoVens} alt='Venues' />
							<br />
							Venues
						</div>
					</Link>
				</div>
				<div className='col-6 col-md-4 col-lg-2 my-3 d-flex-column text-center'>
					<Link className='lnkText' to='/users'>
						<div className='admLink p-3 shadow-lg rounded'>
							<img className='cardImg' src={icoUsers} alt='Users' />
							<br />
							Users
						</div>
					</Link>
				</div>
			</div>
		</AdminPageTemplate>
	);
}
