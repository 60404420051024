import { useContext, useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { saveInLocalStorage } from '../../../helpers/auth';
import axios from 'axios';
import Input from '../../../components/forms/Input';
import Button from '../../../components/forms/Button';
import Password from '../../../components/links/Password';

export default function Login() {
	const navigate = useNavigate();
	const { setAuth } = useContext(AuthContext);
	const [isValidToken, setIsValidToken] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const { data } = await axios.post('/signin', {
				email,
				password,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				setAuth(data);
				saveInLocalStorage('auth', data);
				navigate('/admin');
			}
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	};

	async function handleReCaptchaVerify(token) {
		if (!token) {
			return;
		}

		token && setIsValidToken(true);
	}

	return (
		<div className='mt-5 pt-5'>
			<div className='container py-3'>
				<h2 className='text-center'>Login</h2>
				<div className='formContainer shadow-lg rounded p-3 mx-auto'>
					<form onSubmit={handleSubmit}>
						<GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
						{isValidToken && (
							<>
								<Input
									name='email'
									id='email'
									label='Email Address'
									type='email'
									value={email}
									setValue={setEmail}
								/>
								<Input
									name='pword'
									id='pword'
									label='Password'
									type='password'
									value={password}
									setValue={setPassword}
								/>
								<div className='mt-4 d-flex justify-content-center'>
									<Button loading={loading} type='submit' disabled={!email || !password}>
										Login
									</Button>
								</div>
								<div className='my-3 d-flex justify-content-center'>
									<Password />
								</div>
							</>
						)}
					</form>
				</div>
			</div>
		</div>
	);
}
